import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, DecimalPipe, NgClass, NgStyle } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CountListViewType } from '@compiere-ws/models/widget-center-json';
import { WidgetDataRequest } from '@compiere-ws/models/widget-data-request';
import { WidgetCenterService } from '@compiere-ws/services/widget-center/widget-center.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import { TranslateModule } from '@ngx-translate/core';
import { debounce } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, Subscription, of } from 'rxjs';
import { AbstractWidgetComponent } from '../../abstract-widget.component';

@Component({
  selector: 'iu-count-list-widget-ui',
  templateUrl: './count-list-widget-ui.component.html',
  styleUrls: ['./count-list-widget-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    AsyncPipe,
    TranslateModule,
    ButtonModule,
    DecimalPipe,
    TooltipModule,
  ],
})
export default class CountListWidgetUiComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  items$: Observable<{ id: string; displayedName: string }[]>;
  private items: { id: string; displayedName: string }[] = [];
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  // @ViewChild('widgetCircularProgressBg', { read: ElementRef, static: false })
  // widgetCircularProgressBgEl: ElementRef<HTMLDivElement>;
  // @ViewChild('widgetCircularProgressCover', { read: ElementRef, static: false })
  // widgetCircularProgressCoverEl: ElementRef<HTMLDivElement>;
  @ViewChild('widgetCircularProgress', { read: ElementRef, static: false })
  widgetCircularProgressEl: ElementRef<HTMLDivElement>;
  numberFormat = '1.0-2';
  offset = 0;
  limit = 0;
  NB_FETCH_ROWS = 50;
  isFetching = false;
  ids = ['Value', 'Name', 'DocumentNo', 'Description'];
  result = 0;
  currency = '';
  scrollSub: Subscription;
  percent = 0;
  bgColor = 'inherited';
  @Input()
  activeView: string = CountListViewType.COUNT_VIEW;
  progressInterval;
  lang;

  constructor(
    private renderer: Renderer2,
    private widgetService: WidgetCenterService,
    private store: DataStoreService,
    uiCreator: UICreatorService,
    private connectorService: SecurityManagerService
  ) {
    super(uiCreator);
    this.getDatas = debounce(this.getDatas, 200);
  }

  ngOnInit() {
    if (this.widget) {
      if (this.widget.defaultView != null) {
        this.activeView = this.widget.defaultView;
      }
    }
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
  }

  updateWidget(): void {
    if ((this.widget?.tabId || this.widget?.tableId) && this.widget?.aggregateColumnId) {
      this.switchTo(this.widget.defaultView);
      this.updateAllProps();
    } else {
      this.resetListData();
      this.resetCountData();
    }
  }

  updateAllProps() {
    this.updateCurrency();
    this.updateNumberFormat();
    this.updateColors();
  }

  fetchMoreData(nbInitRow: number = -1) {
    if (this.isFetching) {
      return;
    }
    if (this.offset === -1) {
      this.isFetching = false;
      return;
    }
    this.isFetching = true;
    this.getDatas(nbInitRow);
  }

  getCount() {
    if (!this.widget?.tabId && !this.widget?.tableId) {
      return;
    }
    let request: WidgetDataRequest = {
      widget: { ...this.widget, defaultView: this.activeView },
      startRow: -1,
      endRow: -1,
    };
    const sub = this.widgetService.getWidgetDatas(request).subscribe((datas) => {
      if (datas?.simpleResult) {
        let result = datas.simpleResult;
        if (result) {
          if (this.widget.multiplier) {
            result = result * this.widget.multiplier;
          }
          if (this.widget.isRounded) {
            const digits = this.widget.precision || 0;
            result = Number(result.toFixed(digits));
          } else if (this.widget.precision > 0) {
            if (isNaN(result)) result = 0;
            const multiplier = Math.pow(10, this.widget.precision);
            result = Math.floor(result * multiplier) / multiplier;
          }
        }
        this.result = result;
      } else this.result = 0;
      this.updateAllProps();
      sub.unsubscribe();
    });
  }

  getDatas(nbInitRow: number = -1) {
    if (!this.widget?.tabId && !this.widget?.tableId) {
      this.isFetching = false;
      return;
    }
    this.limit = nbInitRow > -1 ? nbInitRow : this.offset + this.NB_FETCH_ROWS;
    const sub = this.widgetService
      .getWidgetDatas({
        widget: { ...this.widget, defaultView: this.activeView },
        startRow: this.offset,
        endRow: this.limit,
      })
      .subscribe((res) => {
        this.tableName = res.tableName;
        if (res?.gridData?.data) {
          this.store.replaceComplexType(res.gridData);
          if (res?.gridData?.data?.length === this.limit - this.offset) {
            this.offset = this.limit;
          } else {
            this.offset = -1;
          }

          const newItems = res?.gridData?.data.map((d) => {
            return { id: d['Data_UUID'], displayedName: this.getDisplayedName(d) };
          });
          this.items = [...this.items, ...newItems];
        }
        this.isFetching = false;
        this.items$ = of(this.items);
        this.setScrollEvent();
        sub.unsubscribe();
      });
  }

  getDisplayedName(data: any) {
    let displayedName = '';
    for (let k of this.ids) {
      if (data.hasOwnProperty(k) && data[k] != undefined && data[k] != null) {
        if (displayedName != '') {
          displayedName += ' ';
        }
        displayedName += data[k];
      }
    }
    if (!displayedName && Object.keys(data).length > 0) {
      displayedName = data[Object.keys(data)[0]];
    }
    return displayedName;
  }

  trackByFn(index, item) {
    return item.id;
  }

  updateColors() {
    this.bgColor = 'inherited';
    this.percent = 0;
    if (
      (this.widget?.alertLevel > this.result && this.widget?.normalLevel < this.result) ||
      (this.widget?.alertLevel < this.result && this.widget?.normalLevel > this.result)
    ) {
      if (this.widget.warningColor) {
        this.bgColor = this.widget.warningColor;
      }
    } else if (this.widget?.alertLevel > this.widget?.normalLevel) {
      if (this.widget.alertColor && this.result >= this.widget?.alertLevel) {
        this.bgColor = this.widget.alertColor;
      } else if (this.widget.normalColor && this.result <= this.widget?.normalLevel) {
        this.bgColor = this.widget.normalColor;
      }
    } else {
      if (this.widget?.normalLevel == this.widget?.alertLevel) {
        this.bgColor = this.widget.warningColor ?? 'inherited';
      } else if (this.widget.alertColor && this.result <= this.widget?.alertLevel) {
        this.bgColor = this.widget.alertColor;
      } else if (this.widget.normalColor && this.result >= this.widget?.normalLevel) {
        this.bgColor = this.widget.normalColor;
      }
    }
    if (this.widget?.normalLevel == this.widget?.alertLevel) {
      this.percent = 100;
    } else if (this.widget.alertLevel > this.widget.normalLevel) {
      this.percent = (this.result / this.widget.alertLevel) * 100;
    } else if (this.widget.alertLevel < this.widget.normalLevel) {
      this.percent = (this.result / this.widget.normalLevel) * 100;
    } else {
      this.percent = 0;
    }
    this.percent = Number(this.percent.toFixed(2));
    if (this.widgetCircularProgressEl?.nativeElement) {
      let progressStartValue = 0,
        progressEndValue = this.percent * 3.6,
        speed = 5;
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
      this.progressInterval = setInterval(() => {
        progressStartValue += 2;

        if (this.progressInterval && progressStartValue >= progressEndValue) {
          progressStartValue = progressEndValue;
          clearInterval(this.progressInterval);
          this.progressInterval = null;
        }
        if (this.widgetCircularProgressEl?.nativeElement) {
          this.renderer.setStyle(
            this.widgetCircularProgressEl.nativeElement,
            'background',
            `conic-gradient(${this.bgColor} ${progressStartValue}deg, var(--global-bg-color-1) ${progressStartValue + 0.4}deg, var(--global-bg-color-1) 359deg, ${this.bgColor} 360deg)`
          );
        }
      }, speed);
    }
  }

  updateCurrency() {
    this.currency = this.widget?.measureUnit;
  }

  updateNumberFormat() {
    if (this.widget.precision != null) {
      this.numberFormat = '1.0-' + this.widget.precision;
    }
  }

  switchTo(activeView: string) {
    if (activeView == CountListViewType.LIST_VIEW) {
      this.resetCountData();
      this.activeView = CountListViewType.LIST_VIEW;
      this.fetchMoreData(10);
    } else if (activeView == CountListViewType.COUNT_VIEW) {
      this.resetListData();
      this.activeView = CountListViewType.COUNT_VIEW;
      this.getCount();
    }
  }

  resetListData() {
    this.items = [];
    this.offset = 0;
    this.limit = 0;
    this.items$ = null;
    this.unSetScrollEvent();
  }

  resetCountData() {
    if (this.widgetCircularProgressEl?.nativeElement) {
      this.renderer.setStyle(
        this.widgetCircularProgressEl.nativeElement,
        'background',
        `conic-gradient(${this.bgColor} ${0}deg, var(--global-bg-color-1) ${0.4}deg, var(--global-bg-color-1) 359deg, ${this.bgColor} 360deg)`
      );
    }
    this.result = 0;
    this.currency = '';
  }

  handleScrollProgress() {
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total && !this.isFetching) {
      this.fetchMoreData();
    }
  }

  setScrollEvent() {
    if (this.viewport && (!this.scrollSub || this.scrollSub.closed))
      this.scrollSub = this.viewport.elementScrolled().subscribe(() => {
        this.handleScrollProgress();
      });
  }

  unSetScrollEvent() {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unSetScrollEvent();
    SubscriptionsUtils.unsubscribe(...this.subscriptions);
  }
}
