import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-create-from-invoice-window-ui',
  templateUrl: './create-from-invoice-window-ui.component.html',
  styleUrls: ['./create-from-invoice-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class CreateFromInvoiceWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  orderGrid: any;
  inoutGrid: any;
  consignedTransferGrid: any;
  orderSelections: any;
  inoutSelections: any;
  consignedTransferSelections: any;
  isModal: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    // this.dataStore.data['C_Order_ID'] = null;
    // this.dataStore.data['M_InOut_ID'] = null;
    // this.dataStore.data['M_ConsignedTransfer_ID'] = null;
    if (this.dataStore.data['IsSOTrx'] === 'N') {
      this.dataContainers[1].label = this.translateService.instant('specificWindow.createFromInvoice.purchaseOrder');
      this.dataContainers[1].data.label = this.translateService.instant(
        'specificWindow.createFromInvoice.purchaseOrder'
      );
    }
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'C_Order_ID') {
      this.dataStore.data['M_InOut_ID'] = null;
      this.dataStore.data['M_ConsignedTransfer_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'M_InOut_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['M_ConsignedTransfer_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'M_ConsignedTransfer_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['M_InOut_ID'] = null;
      this.clearSelections();
    }
    super.notifyFromDataChange(item);
  }

  clearSelections() {
    for (const grid of this.dataStore.data['selections']) grid['selection'] = [];
    for (const grid of this.gridViews) grid.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
  }
}
