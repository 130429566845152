<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="p-col-12">
      <div class="fields apiz-p-grid">
        @if (loaded && fields?.length === 0) {
          <span class="noAttribute">
            {{ 'productAttribut.noAttribute' | translate }}
          </span>
        }
        <ng-template #grid />
        <ng-template iuDynamicContainer />
      </div>
    </div>

    <div
      class="iu-specific-btn-container apiz-p-grid p-col-12"
      [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
      <ng-template #vcrButtons />

      @if (fields?.length > 0) {
        <iu-button-ui
          class="p-col-12 p-md-3 p-lg-3"
          (click)="$event.preventDefault(); $event.stopPropagation(); validAttributes()"
          label="{{ 'infodialog.ok' | translate }}" />
      }

      @if (isModal) {
        <iu-button-ui
          class="p-col-12 p-md-3 p-lg-3"
          btnType="alert"
          (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
          label="{{ 'process.cancel' | translate }}"
          icon="fa fa-ban" />
      }
    </div>
  </p-scrollPanel>
</div>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
