@if (data()?.Description || isNewTransfer()) {
  <iu-storekeeper-input
    [type]="'textarea'"
    [label]="'Description' | SKWTranslate"
    [placeholder]="'NoDescription' | SKWTranslate"
    [value]="data()?.Description ?? ''"
    [readOnly]="!isNewTransfer()"
    (valueChange)="changeValue('Description', $event)" />
}

@if (data()?.Comment || isNewTransfer()) {
  <iu-storekeeper-input
    [type]="'textarea'"
    [label]="'comment' | SKWTranslate"
    [value]="data()?.Comment ?? ''"
    [readOnly]="!isNewTransfer()"
    (valueChange)="changeValue('Comment', $event)" />
}

@if (isConditioning()) {
  <div class="column">
    <span>{{ 'Destination' | SKWTranslate }}</span>
    <div class="row">
      <iu-storekeeper-input
        class="flex"
        [type]="'text'"
        [label]="'Location' | SKWTranslate"
        [value]="data()?.locator_destination_barcode"
        [readOnly]="true"
        (valueChange)="changeValue('emplacement', $event)" />
      <iu-storekeeper-input
        [type]="'text'"
        [label]="'Pallet' | SKWTranslate"
        [value]="data()?.palette_destination_barcode"
        [readOnly]="true"
        (valueChange)="changeValue('palette', $event)" />
    </div>
  </div>
} @else if (canConvertToConditioning()) {
  <iu-storekeeper-button [label]="'ConvertToConditioning' | SKWTranslate" (click)="togglePalletPanel($event)" />
}
