import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { isNil } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';
import CheckboxUiComponent from '../checkbox-ui/checkbox-ui.component';

@Component({
  selector: 'iu-input-switch-ui',
  templateUrl: './input-switch-ui.component.html',
  styleUrls: ['./input-switch-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    CheckboxUiComponent,
    NgClass,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
  ],
})
export default class InputSwitchUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input() columnName: string;
  @ViewChild('spanRef', { static: true }) spanRef: ElementRef;

  dataContainers: AbstractDataContainerCallout;

  set checked(value: boolean) {
    this.fieldValue = this.isTriState && isNil(value) ? value : value ? 'Y' : 'N';
    this.dataChange(this.fieldValue);
  }

  get checked() {
    return this.isTriState && isNil(this.fieldValue) ? null : this.fieldValue === 'Y';
  }

  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;

  isSwitchField = true;

  ngOnInit() {
    super.ngOnInit();
    if (!this.isStandalone) {
      if (this.data && this.data.label) {
        this.label = this.data.label;
      }
      if (this.cssClass !== undefined) {
        this.cssGrid = this.cssClass;
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.isStandalone) {
      let parentComp = this.DOMParentComponent;
      while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
        parentComp = parentComp.DOMParentComponent;
      }
      if (parentComp instanceof EditTabUiComponent) {
        this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
      }
      // permet de setter les valeurs venant de l'url d'un process
      if (this.parentProcess) {
        const value = this.parentProcess.paramsMap.get(this.data.columnName);
        if (value !== undefined) {
          (<string>value).toUpperCase() === 'Y' ? (this.checked = true) : (this.checked = false);
        }
      }
    }
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
  labelClick(event) {
    if (!this.isReadOnly) {
      if (event) {
        event.stopPropagation();
      }
      this.checked = !this.checked;
    }
  }
}
