import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPercentInPxCss',
  standalone: true,
})
export class GetPercentInPxCssPipe implements PipeTransform {
  transform(el: HTMLDivElement, args: { of: string; many: number }): string {
    if (el && args) {
      return el[args.of || 'clientWidth'] * (args.many < 1 ? args.many : args.many / 100) + 'px';
    }
    return '100%';
  }
}
