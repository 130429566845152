import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { chartModel } from '@iupics-components/models/chart-model';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

@Component({
  selector: 'iu-mix-chart-ui',
  standalone: true,
  imports: [],
  templateUrl: './mix-chart-ui.component.html',
  styleUrl: './mix-chart-ui.component.scss',
})
export class MixChartUiComponent implements OnInit {
  translateService = inject(TranslateService);

  @Input() data: any[] = [];
  @Input() chartType: any[];
  @Input() resourceName: string;
  @Output() barClick = new EventEmitter<string>();
  dataLabel: any[] = [];
  dataSets: any[] = [];
  private chart: Chart;

  constructor() {}

  ngOnInit(): void {
    this.initChartComponent();
  }

  handleBarClick(event, elements): void {
    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;
      const dataIndex = element.index;
      const dataset = this.chart.data.datasets[datasetIndex];
      if (dataset.type === 'bar') {
        const data = dataset.data[dataIndex];
        this.barClick.emit(this.data[0]['data'][dataIndex]);
      }
    }
  }

  initChartComponent() {
    for (let i = 0; i < this.data.length; i++) {
      switch (this.data[i]['type']) {
        case 'values':
          let bar: chartModel = new chartModel();
          bar.type = this.chartType[0];
          bar.label = this.translateService.instant('manufacturingChart.legend.bar');
          bar.data = this.data[i]['data'];
          bar.backgroundColor = 'rgba(255, 99, 132, 0.2)';
          bar.borderColor = 'rgba(255, 99, 132, 1)';
          bar.borderWidth = 1;
          this.dataSets.push(bar);
          break;

        case 'limit':
          let line: chartModel = new chartModel();
          line.label = this.translateService.instant('manufacturingChart.legend.line');
          line.type = this.chartType[1];
          line.data = this.data[i]['data'];
          line.borderColor = 'rgba(54, 162, 235, 1)';
          line.borderWidth = 2;
          line.fill = false;
          this.dataSets.push(line);
          break;

        case 'dates':
          this.dataLabel = this.data[i]['data'];
          break;

        default:
          break;
      }
    }
    this.createCombinedChart();
  }

  private createCombinedChart(): void {
    const ctx = document.getElementById('combinedChart') as HTMLCanvasElement;
    const labels: string[] = this.dataLabel;
    this.chart = new Chart(ctx, {
      type: this.chartType[0],
      data: {
        labels: labels,
        datasets: this.dataSets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 1000, // Set animation duration in milliseconds
          easing: 'easeInOutQuart', // Set animation easing function
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.translateService.instant('manufacturingChart.label'),
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
          x: {
            title: {
              display: false,
              text: 'Dates',
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
            align: 'end',
          },
          title: {
            display: false,
            text: this.resourceName,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
              speed: 10,
              threshold: 10,
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
              speed: 0.1,
              limits: {
                max: 10,
                min: 0.5,
              },
            },
          },
        },
        onClick: (event, elements) => {
          this.handleBarClick(event, elements);
        },
      },
    });
  }
}
