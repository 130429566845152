import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractWidgetComponent } from '@iupics/modules/iupics-widgets/abstract-widget.component';

@Component({
  selector: 'iu-metabase-widget',
  standalone: true,
  imports: [],
  templateUrl: './metabase-widget.component.html',
  styleUrl: './metabase-widget.component.scss',
})
export class MetabaseWidgetComponent extends AbstractWidgetComponent implements OnInit {
  url: SafeUrl;

  constructor(
    private sanitize: DomSanitizer,
    protected uiCreator: UICreatorService
  ) {
    super(uiCreator);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.widget.iframe) this.url = this.sanitize.bypassSecurityTrustResourceUrl(this.widget.iframe);
  }

  updateWidget() {
    super.updateWidget();
    if (this.widget.iframe) this.url = this.sanitize.bypassSecurityTrustResourceUrl(this.widget.iframe);
  }
}
