<div
  [ngClass]="{
    'vendor-profile-container': true,
    'with-profile-picture': true,
  }">
  <div class="picture" iuLoading [dataToWatch]="data()?.profilePicture" (onTimeout)="changeLoadingState($event)">
    @if (isLoading() || (hasImg() && !imgError())) {
      <img
        imgFallback
        [src]="data().profilePicture | docServerUrl | async"
        (errorHandler)="changeImgError()"
        alt="profile" />
    } @else {
      <i class="icon-user-circle" aria-hidden="true"></i>
    }
  </div>
  <div class="name" iuLoading [dataToWatch]="data()?.fullName">
    @if (data()?.fullName) {
      <h1>{{ data().fullName }}</h1>
      <i class="edit-button-icon icon-edit-pencil" aria-hidden="true" role="button" (click)="editBtnClick($event)"></i>
    }
  </div>
  <div class="type" iuLoading [dataToWatch]="data()?.type">
    @if (data()?.type) {
      <h2>{{ data()?.type }}</h2>
    }

    @if (data()?.isForbidden) {
      <i class="icon-blocked" aria-hidden="true"></i>
    }
  </div>
  <div class="rating" iuLoading [dataToWatch]="data()?.rating">
    <iu-input-stars-ui [data]="data()?.rating" />
  </div>
  <div class="top" iuLoading [dataToWatch]="data()?.top">
    @if (data()?.top > 0) {
      <span class="top-label">{{ 'vendorProfile.topLabel' | translate }}</span>
      <span class="top-value">{{ data()?.top }}</span>
    }
  </div>
</div>
