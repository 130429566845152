<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast']"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div id="page-wrap">
      <h1>{{ 'specificWindow.quickOrder.title' | translate }} ( {{ docStatus }} )</h1>
      <div id="customer">
        <div class="row">
          <table class="metaleft p-md-4 p-sm-12" id="meta" aria-label="metaleft table">
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.customer' | translate }}</th>
              <td class="p-grid"><ng-template #vcrBPartner /></td>
            </tr>
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.customerLocation' | translate }}</th>
              <td class="p-grid"><ng-template #vcrBPartnerLocation /></td>
            </tr>
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.deliveryViaRule' | translate }}</th>
              <td class="p-grid"><ng-template #vcrDeliveryViaRule /></td>
            </tr>
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.deliveryRule' | translate }}</th>
              <td class="p-grid"><ng-template #vcrDeliveryRule /></td>
            </tr>
          </table>

          <table class="metaright p-md-4 p-sm-12" id="meta" aria-label="metaright table">
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.order' | translate }}</th>
              <td class="p-grid"><ng-template #vcrOrder /></td>
            </tr>
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.poReference' | translate }}</th>
              <td class="p-grid"><ng-template #vcrPOReference /></td>
            </tr>
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.dateOrdered' | translate }}</th>
              <td class="p-grid"><ng-template #vcrDateOrdered /></td>
            </tr>
            <tr>
              <th class="meta-head" scope="row">{{ 'specificWindow.quickOrder.shipper' | translate }}</th>
              <td class="p-grid"><ng-template #vcrMShipper /></td>
            </tr>
          </table>
        </div>
        <a class="settings" (click)="isSidebarOpen = !isSidebarOpen">
          <i class="fas fa-cog" aria-hidden="true"></i>
        </a>
      </div>
      <table id="items" aria-label="items table">
        <thead>
          <tr>
            <th scope="col">{{ 'specificWindow.quickOrder.product' | translate }}</th>
            <th scope="col">{{ 'specificWindow.quickOrder.description' | translate }}</th>
            <!-- <th class="alignRight">{{ 'specificWindow.quickOrder.priceList' | translate }}</th> -->
            <th class="alignRight" scope="col">{{ 'specificWindow.quickOrder.unitPrice' | translate }}</th>
            <th scope="col">{{ 'specificWindow.quickOrder.quantity' | translate }}</th>
            <th scope="col">{{ 'specificWindow.quickOrder.discount' | translate }}</th>
            <th class="alignRight" scope="col">{{ 'specificWindow.quickOrder.totalLine' | translate }}</th>
            <th class="alignRight" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          @for (item of dataLines.length + 1 | fakeArray; track i; let i = $index) {
            <tr [ngClass]="['item-row', i === dataLines.length ? 'hidden' : '']">
              <td class="item-name">
                <ng-template #vcrProduct />
              </td>
              <td class="description"><ng-template #vcrDescription /></td>
              <!-- <td class="fastorder-price">
                <ng-template #vcrPriceList/>
              </td> -->
              <td class="fastorder-price">
                <div [hidden]="true">
                  <ng-template #vcrPriceList />
                  <ng-template #vcrPriceActual />
                </div>

                <ng-template #vcrPriceEntered />
              </td>
              <td class="fastorder-qty"><ng-template #vcrQty /></td>
              <td class="fastorder-discount"><ng-template #vcrDiscount /></td>
              <td class="fastorder-linetotal"><ng-template #vcrLineTotalAmt /></td>
              <td class="deleteButton">
                {{ item }}
                @if (i < dataLines.length) {
                  <button
                    tooltipPosition="top"
                    showDelay="250"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{ 'generic.delete' | translate }}"
                    pButton
                    icon="icon-delete"
                    (click)="removeLine(dataLines[i], i)"
                    [disabled]="
                      dataStore?.data?.['DocStatus'] === 'CO' || dataStore?.data?.['DocStatus']?.id === 'CO'
                    "></button>
                }
              </td>
            </tr>
          }

          <tr id="hiderow">
            <td colspan="7">
              <button
                pButton
                id="addrow"
                class="p-button p-button-secondary"
                title="Add a row"
                (click)="newLine()"
                [label]="'specificWindow.quickOrder.addrow' | translate"
                icon="icon-add"
                [disabled]="
                  !dataStore?.data?.['C_Order_ID'] ||
                  dataStore?.data?.['DocStatus'] === 'CO' ||
                  dataStore?.data?.['DocStatus']?.id === 'CO'
                "></button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="blank"></td>
            <td class="total-line balance">
              <label>{{ 'specificWindow.quickOrder.orderTotal' | translate }}</label>
            </td>
            <td colspan="2" class="total-value balance fastorder-grandtotal">
              <ng-template #vcrGrandTotal />
            </td>
          </tr>
          <tr>
            <td class="buttonExecute" colspan="7">
              <div>
                @if (
                  dataStore?.data?.['DocStatus'] !== 'DR' ||
                  (dataStore?.data?.['DocStatus']?.id && dataStore?.data?.['DocStatus']?.id !== 'DR')
                ) {
                  <button
                    pButton
                    class="p-button p-button-warning"
                    (click)="$event.preventDefault(); $event.stopPropagation(); resetOrder()"
                    [label]="'specificWindow.quickOrder.newOrder' | translate"
                    icon="fa fa-plus"></button>
                }

                <button
                  pButton
                  [disabled]="dataStore?.data?.['DocStatus'] === 'CO' || dataStore?.data?.['DocStatus']?.id === 'CO'"
                  (click)="$event.preventDefault(); $event.stopPropagation(); processOrder()"
                  [label]="'specificWindow.processing' | translate"
                  [icon]="
                    dataStore?.data?.['DocStatus'] === 'CO' || dataStore?.data?.['DocStatus']?.id === 'CO'
                      ? 'fa fa-ban'
                      : 'fa fa-check'
                  "
                  class="p-button p-button-success"></button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div style="height: 70px"></div>
  </p-scrollPanel>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="0" position="right" styleClass="p-sidebar-md fast-create-order">
    <div class="apiz-p-grid">
      <h1 class="iu-notification-center-title">
        {{ 'specificWindow.quickOrder.settings' | translate }}
      </h1>
      <ng-template #vcrSettings />
    </div>
  </p-sidebar>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
