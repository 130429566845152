<p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
  <div class="p-col-12">
    <div class="form-style">
      <div class="fields" style="padding: 5px 20px">
        <ng-template iuDynamicContainer />
      </div>
      @if (showCharts) {
        <iu-mix-chart-ui
          (barClick)="openNewTab($event)"
          [data]="chartData"
          [chartType]="chartType"
          [resourceName]="selectedResourceName" />
      }
    </div>
  </div>
  <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
    @if (gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
      <iu-button-ui
        class="p-col-12 p-md-3 p-lg-2"
        (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
        label="{{ 'contextmenu.zoom' | translate }}"
        icon="fa fa-search-plus"
        data-cy="form-button-zoom" />
    }
    <ng-template #vcrButtons />
    @if (isModal && gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
      <iu-button-ui
        class="p-col-12 p-md-3 p-lg-2"
        (click)="
          $event.preventDefault(); $event.stopPropagation(); setSelectedRowOnAutocomplete(); closeModalEmitter.emit()
        "
        label="{{ 'infodialog.ok' | translate }}"
        data-cy="form-button-validate" />
    }
    @if (isModal) {
      <iu-button-ui
        class="p-col-12 p-md-3 p-lg-2"
        btnType="alert"
        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban" />
    }
  </div>
</p-scrollPanel>
