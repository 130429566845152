import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DestroyRef, Directive, inject, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounce } from 'lodash';
import { Observable, tap, throttleTime } from 'rxjs';

@Directive({
  standalone: true,
})
export class SKWVirtualScrollDirective {
  #destroyRef = inject(DestroyRef);
  viewport = viewChild(CdkVirtualScrollViewport);

  constructor() {
    this.handleScrollProgress = debounce(this.handleScrollProgress);
  }

  listen(obs: Observable<Event>) {
    obs
      ?.pipe(
        throttleTime(1000),
        tap(() => this.handleScrollProgress()),
        takeUntilDestroyed(this.#destroyRef)
      )
      ?.subscribe();
  }

  handleScrollProgress() {
    const end = this.viewport().getRenderedRange().end;
    const total = this.viewport().getDataLength();
    if (end >= total * 0.75) {
      this.fetchMoreData();
    }
  }

  fetchMoreData(force = false) {
    // implement this...
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
