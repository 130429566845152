import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLimit',
  standalone: true,
})
export class TextLimitPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value && args && typeof args === 'number' && (<string>value).length > args) {
      return (<string>value).slice(0, args) + '...';
    } else {
      return value;
    }
  }
}
