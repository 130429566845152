import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config.service';
import {
  injectSmartButtonManagerService,
  SmartButtonData,
} from '@iupics-manager/managers/smart-button-manager/smart-button-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';

@Component({
  selector: 'iu-smart-button-ui',
  templateUrl: './smart-button-ui.component.html',
  styleUrls: ['./smart-button-ui.component.scss'],
  standalone: true,
  imports: [NgClass, TextLimitPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class SmartButtonUiComponent {
  readonly #config = inject(AppConfig);
  readonly #smManagerService = injectSmartButtonManagerService();

  // buttonWidth = 160;
  buttonWidth: number;

  data = input<SmartButtonData>();

  label = computed(() => this.data().name);
  key = computed(() => this.data().key);
  id = computed(() => this.data().id);
  iconClass = computed(() => this.data().icon);

  onClick = output<{ key: number; id: number }>();

  constructor() {
    this.buttonWidth = this.#config.getConstant(
      `SmartButtonUiComponent#buttonWidth_${Global.isMobileWidth() ? 'mobile' : 'not-mobile'}`
    );
  }

  onSmartButtonClick(event?: MouseEvent) {
    event?.preventDefault();
    this.#smManagerService.toggleSmartButton(this.key());
  }
}
