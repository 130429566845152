<div class="notes-container" #notesContainer>
  <div
    class="notes-wrapper"
    [ngClass]="{
      'has-scrollbar': notesWrapper?.scrollHeight > notesWrapper?.clientHeight,
    }"
    #notesWrapper>
    @if (!isNotesLoaded) {
      <div class="loading-container centered-content">
        <p class="loading">{{ 'detailsNote.loading' | translate }}<span>.</span><span>.</span><span>.</span></p>
      </div>
    } @else {
      @if (notes.length === 0) {
        <div class="notes-empty-container centered-content" data-cy="notes-panel-empty-container">
          <i class="icon-empty-note icon-notes" aria-hidden="true"></i>
          <span>{{ 'detailsNote.emptyNote' | translate }}</span>
        </div>
      } @else {
        @for (note of notes; track note; let i = $index) {
          <iu-note-ui [note]="note" [parent]="this"></iu-note-ui>
        }
      }
    }
  </div>
  <form class="notes-form" data-cy="notes-panel-form">
    <iu-input-mention-ui
      [readonly]="!isTagsLoaded || isNoteSending"
      [tags]="tags$"
      [sendIcon]="true"
      (enterEmitter)="sendNote()"
      #inputMentionRef>
    </iu-input-mention-ui>
    <div class="notes-buttons">
      @if (entryAttachment === null) {
        <i
          class="note-button icon-attach3x"
          aria-hidden="true"
          role="button"
          (click)="displayJoinFilesPanel = true"
          data-cy="notes-panel-file-button"></i>
      }
      @if (entryAttachment !== null) {
        <p>
          {{ entryAttachment.name | textLimit: 20 }}
          <i
            class="note-button note-button-attachement icon-close"
            role="button"
            (click)="deleteEntryAttachment()"
            aria-hidden="true"></i>
        </p>
      }
      <i
        [ngClass]="{
          'note-button': true,
          'icon-bell': !isFollow,
          'icon-bell-slash': isFollow,
        }"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'detailsNote.' + (isFollow ? 'un' : '') + 'follow' | translate }}"
        (click)="follow(!isFollow)"
        aria-hidden="true"
        role="button"
        data-cy="notes-panel-follow-button">
      </i>
    </div>
  </form>

  @if (displayJoinFilesPanel) {
    <iu-modal-ui
      [title]="'joinFiles.title'"
      [hasCloseBtn]="true"
      [contentType]="ModalContentType.JOINFILE"
      (closeModalEmitter)="displayJoinFilesPanel = false">
      <iu-join-file-ui
        [dsKey]="currentDataStoreKey"
        [adTable_ID]="table_id"
        [fromNotes]="true"
        (uploadEnd)="fileUpload($event); displayJoinFilesPanel = false"></iu-join-file-ui>
    </iu-modal-ui>
  }
</div>
