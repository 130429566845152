import { Component, OnInit } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  CompiereDataGridType,
  DataStore,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { PickListModule } from 'primeng/picklist';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, zip } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'iu-select-order',
  templateUrl: './select-order.component.html',
  styleUrls: ['./select-order.component.scss'],
  standalone: true,
  imports: [ButtonModule, TooltipModule, PickListModule, SharedModule, TranslateModule],
})
export default class SelectOrderComponent extends AbstractDynamicComponent implements OnInit {
  public exclusionDataList: any[] = [];
  public inclusionDataList: any[] = [];
  public exclusionDataListOld: any[] = [];
  public inclusionDataListOld: any[] = [];
  ColumnSortYesNo: number;
  ColumnSortOrder: number;
  tabId: number;
  sortYesNo: string;
  sortOrder: string;
  datasList: any;
  adTabID: number;
  adWindowID: number;
  offset: number;
  limit: number;
  test = false;
  testWS = false;
  currentRecordId: string;
  datasWS: any;
  indexField: number;
  indexName: number;
  indexSortYesNo: number;
  indexSortOrder: number;
  indexSeqNo: number;

  constructor(
    private store: DataStoreService,
    private socketService: SocketService
  ) {
    super();
  }

  ngOnInit() {
    this.init();
  }
  init() {
    this.ColumnSortYesNo = this.data.AD_ColumnSortYesNo_ID;
    this.ColumnSortOrder = this.data.AD_ColumnSortOrder_ID;
    this.tabId = this.data.AD_Table_ID;
    this.adTabID = this.data.AD_Tab_ID;
    this.adWindowID = this.data.AD_Window_ID;
    const ColumnTabID = 101;
    const request: DataStoreRequest = {
      windowId: this.adWindowID,
      record_id: undefined,
      parent_constraint: undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: ColumnTabID,
        startRow: 0,
        endRow: 2,
        filterModel: {
          AD_Column_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [[this.ColumnSortOrder, this.ColumnSortYesNo]],
          },
        },
      },
    };
    this.store.getDataGrid(request).subscribe((response) => {
      this.sortYesNo = response.data.find((col) => col['AD_Column_ID'] === this.ColumnSortYesNo)['ColumnName'];
      this.sortOrder = response.data.find((col) => col['AD_Column_ID'] === this.ColumnSortOrder)['ColumnName'];
      if (this.data.recordID !== 0 && this.sortYesNo !== undefined && this.sortOrder !== undefined) {
        this.inclusionDataList = [];
        this.exclusionDataList = [];
        this.inclusionDataListOld = [];
        this.exclusionDataListOld = [];
        this.datasList = '';
        this.prepareDatasList();
      }
    });
  }
  prepareDatasList() {
    this.currentRecordId = this.data.recordID;
    if (this.currentRecordId.split(',').length > 1) {
      const recordId = this.currentRecordId;
      const request: DataStoreRequest = {
        windowId: this.adWindowID,
        record_id: null,
        parent_constraint: recordId,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.adTabID,
          startRow: 0,
          endRow: 0,
          sortModel: [
            {
              colId: this.sortOrder,
              sort: CompiereDataGridSortModelType.ASC,
            },
          ],
        },
      };
      this.subscriptions.push(
        this.store.getDataGrid(request, true).subscribe((datas) => {
          // init des listes
          if (datas !== undefined) {
            this.inclusionDataList = datas.data
              .filter((row: any) => row[this.sortYesNo] === 'Y')
              .sort((a: any, b: any) => a[this.sortOrder] - b[this.sortOrder]);
            this.exclusionDataList = datas.data
              .filter((row: any) => row[this.sortYesNo] === 'N')
              .sort((a: any, b: any) => a.Name.localeCompare(b.Name));

            this.inclusionDataListOld = cloneDeep(this.inclusionDataList);
            this.exclusionDataListOld = cloneDeep(this.exclusionDataList);
            this.datasList = datas;
          }
        })
      );
    }
  }

  save() {
    const observables: Observable<any>[] = [];
    const dataToSaveList = [];
    // Attribution des bons numéros de séquence

    for (let i = 0; i < (this.inclusionDataList.length ?? 0); i++) {
      const value = this.inclusionDataList[i];
      value[this.sortOrder] = (i + 1) * 10;
      if (
        !this.inclusionDataListOld[i] ||
        this.inclusionDataList[i].Data_UUID !== this.inclusionDataListOld[i].Data_UUID ||
        this.inclusionDataList[i][this.sortOrder] !== this.inclusionDataListOld[i][this.sortOrder]
      ) {
        const request: DataStoreRequest = {
          windowId: this.adWindowID,
          record_id: this.inclusionDataList[i].Data_UUID,
          parent_constraint: this.currentRecordId,
          compiereRequest: {
            windowType: CompiereDataGridType.WINDOW,
            entityId: this.adTabID,
            startRow: 0,
            endRow: 1,
          },
        };
        observables.push(
          this.store.getWindowSingleData(request).pipe(
            tap((dataStore: DataStore) => {
              const newData = {};
              newData[this.sortOrder] = this.inclusionDataList[i][this.sortOrder];
              newData[this.sortYesNo] = 'Y';
              this.store.syncDataChanges(dataStore, newData, true, false, [], false, true);
              dataToSaveList.push(this.inclusionDataList[i]);
            })
          )
        );
      }
    }

    for (let i = 0; i < this.exclusionDataList.length; i++) {
      if (
        !this.exclusionDataListOld[i] ||
        this.exclusionDataListOld.find((item: any) => item.Data_UUID === this.exclusionDataList[i].Data_UUID) ===
          undefined
      ) {
        const request: DataStoreRequest = {
          windowId: this.adWindowID,
          record_id: this.exclusionDataList[i].Data_UUID,
          parent_constraint: this.currentRecordId,
          compiereRequest: {
            windowType: CompiereDataGridType.WINDOW,
            entityId: this.adTabID,
            startRow: 0,
            endRow: 1,
          },
        };
        observables.push(
          this.store.getWindowSingleData(request).pipe(
            tap((dataStore) => {
              const newData = {};
              newData[this.sortOrder] = 0;
              newData[this.sortYesNo] = 'N';
              this.store.syncDataChanges(dataStore, newData, true, false, [], false, true);
              dataToSaveList.push(this.exclusionDataList[i]);
            })
          )
        );
      }
    }

    if (observables.length) {
      zip(...observables).subscribe(() => {
        const dataStorekeys = dataToSaveList.map((dataToSave) => {
          return this.store.generateDataStoreKey(
            this.adWindowID,
            this.adTabID,
            dataToSave.Data_UUID,
            this.currentRecordId
          );
        });

        if (dataStorekeys.length > 0) {
          this.subscriptions.push(
            this.store.saveWindowData(dataStorekeys).subscribe((dataSavedList) => {
              this.init();
              this.parentTab.editViewParent.updateAll(false, this.adTabID);
              if (environment.constant.mergeLevel !== 0) {
                for (const {
                  key: { recordId, windowId },
                } of dataSavedList) {
                  this.socketService.broadcast(`${windowId}-${recordId}`, {
                    from: `${this.socketService.getSocketId()}all`,
                    windowId,
                    recordId,
                  });
                }
              }
            })
          );
        }
      });
    }
  }

  onChildUpdate(event): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}
