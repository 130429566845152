@if (isOpen()) {
  <div class="panel" #panel>
    @if (hasTitle()) {
      <div class="title-bar">
        <ng-content select="[icon]" />
        <h1 class="title">{{ title() }}</h1>
        <ng-content select="[title-btn]" />
      </div>
    }

    <div class="content">
      <ng-content select="[content]" />
    </div>

    @if (displayDefaultButtons()) {
      <div class="buttons">
        <iu-storekeeper-button class="button" [label]="cancelButtonLabel() | SKWTranslate" (click)="onCancel($event)" />
        <iu-storekeeper-button
          class="button"
          [label]="confirmButtonLabel() | SKWTranslate"
          [type]="'outlined'"
          (click)="onConfirm($event)" />
      </div>
    }
    <ng-content select="[buttons]" />
  </div>

  <div class="panel-backdrop" (click)="onClickOutside($event)"></div>
}
