<label class="label" [for]="label() + '_' + uuid">{{ label() }}</label>
@if (type() === 'textarea') {
  <textarea
    #input
    class="input"
    [name]="label() + '_' + uuid"
    [id]="label() + '_' + uuid"
    [readOnly]="readOnly()"
    [placeholder]="placeholder()"
    [(value)]="value"
    (change)="updateValue($event)"></textarea>
} @else {
  <div
    class="input-wrapper"
    [attr.data-validation]="validation() !== undefined"
    [attr.data-validated]="validation()"
    [attr.data-readOnly]="readOnly()">
    <input
      #input
      class="input"
      [type]="type()"
      [name]="label() + '_' + uuid"
      [id]="label() + '_' + uuid"
      [readOnly]="readOnly()"
      [placeholder]="placeholder()"
      [(value)]="value"
      (change)="updateValue($event)"
      (keypress)="onKeypress($event)" />
    @if (validation() !== undefined) {
      <i
        [ngClass]="{
          'input-icon': true,
          'icon-check': validation(),
          'icon-close': !validation(),
        }"
        aria-hidden="true"></i>
    }
  </div>
}
