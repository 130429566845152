<div #riListElt class="recent-items">
  @if (recentItems$ | async; as recentItems) {
    @for (item of recentItems; track item) {
      <div class="recent-item" (click)="openTab($event, item)">
        {{ item.name }}
      </div>
    }
  } @else {
    <div class="recent-item">Loading...</div>
  }
</div>
