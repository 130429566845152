<div
  [ngClass]="{
    'avatar-wrapper': true,
    'with-picture': data()?.pictureURL?.length > 0 && !displayDefaultAvatar(),
  }">
  @if (data()?.pictureURL?.length > 0 && !displayDefaultAvatar()) {
    <img imgFallback [src]="data().pictureURL" (errorHandler)="onError($event)" alt="avatar" />
  } @else {
    <span [title]="data().fullname">
      {{ data().abbreviation }}
    </span>
  }
</div>
