<li
  [style.width.px]="tabWidth"
  #multiTab
  [ngClass]="[
    isActive ? 'active' : '',
    components.length === 2 ? 'iu-tab-group-double' : '',
    components.length === 3 ? 'iu-tab-group-triple' : '',
    components.length === 4 ? 'iu-tab-group-quadruple' : '',
    'tabContainer',
  ]"
  draggable="true"
  id="{{ id }}"
  (drop)="handleDrop($event)"
  (dragover)="handleDragOver($event)"
  (dragstart)="handleDragStart($event)"
  (dragend)="handleDragEnd($event)"
  (click)="setActive($event)">
  <ng-template #multiTabContent> </ng-template>
</li>
