<div
  [hidden]="!isModalDisplay"
  [ngClass]="{ hidden: !isModalDisplay }"
  #container
  class="iu-modal-background"
  (mousedown)="initClose($event)"
  (touchstart)="initClose($event)"
  [ngStyle]="
    angularStyle !== undefined && angularStyle['iu-modal-background'] ? angularStyle['iu-modal-background'] : ''
  "
  (contextmenu)="$event.preventDefault()"
  [attr.data-cy-contentType]="contentType">
  <div
    cdkDrag
    [cdkDragBoundary]="container"
    class="{{ 'iu-modal ' + modalClass }}"
    [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal'] ? angularStyle['iu-modal'] : ''">
    <div
      cdkDragHandle
      [ngClass]="[title !== undefined && title !== null ? 'border' : '', 'iu-modal-header']"
      [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal-header'] ? angularStyle['iu-modal-header'] : ''">
      <div class="close-cross" [ngStyle]="{ float: title !== undefined && title !== null ? 'unset' : 'right' }">
        <iu-button-ui
          class="btn-close"
          btnType="default"
          [isStandalone]="true"
          (click)="close($event, true)"
          icon="icon-close"
          data-cy="modal-close-btn" />
      </div>
      @if (title !== undefined && title !== null) {
        <div class="title">{{ title | translate }}</div>
      }
      @if (isLoading) {
        <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
      }
    </div>

    <div
      class="iu-modal-body"
      [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal-body'] ? angularStyle['iu-modal-body'] : ''">
      <ng-content></ng-content>
    </div>
    @if (hasCloseBtn || hasFooter) {
      <div
        class="iu-modal-footer"
        [ngStyle]="
          angularStyle !== undefined && angularStyle['iu-modal-footer'] ? angularStyle['iu-modal-footer'] : ''
        ">
        <ng-content select="[footer]"></ng-content>
        @if (hasCloseBtn) {
          <button
            pButton
            class="p-button p-button-secondary"
            (click)="close($event, true)"
            label="{{ closeBtnLabel | translate }}"
            icon="fa fa-ban"></button>
        }
      </div>
    }
  </div>
</div>
