import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ParentChildRelation, TreeCompiereJSON } from '@compiere-ws/models/compiere-tree-json';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsData } from '@iupics-manager/models/iupics-data';
import { cloneDeep } from 'lodash';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { Tree, TreeModule } from 'primeng/tree';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'iu-tree-ui',
  templateUrl: './tree-ui.component.html',
  styleUrls: ['./tree-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TreeModule],
  providers: [TreeDragDropService],
})
export default class TreeUiComponent extends AbstractDataContainer implements OnInit, OnDestroy {
  @Input()
  data: IupicsData;
  @Output()
  selectItem = new EventEmitter();
  loading = true;
  @ViewChild('tree', { static: true })
  tree: Tree;
  treeData: TreeCompiereJSON;

  maxTreeHeight: string;
  private listener: Function;

  private isGetDatagridInProgress = false;

  constructor() {
    super();
    this.listener = this.renderer.listen('window', 'resize', this.editHeight.bind(this));
  }

  editHeight() {
    this.maxTreeHeight = `${document.documentElement.getBoundingClientRect().height - 140}px`;
  }

  ngOnInit() {
    this.maxTreeHeight = `${document.documentElement.getBoundingClientRect().height - 140}px`;
    this.getData();
    this.tree.onNodeClick = function (event, node: TreeNode) {
      if (!node.children) {
        this.onNodeSelect.emit({ originalEvent: event, node: node });
      }
    };
  }

  getData() {
    if (this.isGetDatagridInProgress) {
      return;
    }
    this.loading = true;
    this.isGetDatagridInProgress = true;
    this.subscriptions.push(
      this.store
        .getDataTree(this.data.AD_Table_ID)
        .pipe(tap((_) => (this.isGetDatagridInProgress = false)))
        .subscribe((tree) => {
          if (tree) {
            this.treeData = tree;
            this.loading = false;
          }
        })
    );
  }

  onNodeDrop({ originalEvent }: { originalEvent: Event; dragNode: TreeNode; dropNode: TreeNode }) {
    originalEvent.stopPropagation();
    const dataToSave: ParentChildRelation[] = [];
    this.prepareDataToSave(this.treeData.treeNodes, dataToSave, 0);
    const tree = cloneDeep(this.treeData);
    tree.treeNodes = [];
    tree.parentChildRelations = dataToSave.filter(
      (i) =>
        i.node_ID >= 0 &&
        tree.parentChildRelations.findIndex(
          (pcr) => pcr.node_ID === i.node_ID && pcr.parent_ID === i.parent_ID && pcr.seqNo === i.seqNo
        ) < 0
    );

    this.subscriptions.push(this.store.saveDataTree(tree).subscribe());
  }

  prepareDataToSave(nodes: TreeNode[], dataToSave: ParentChildRelation[], parent_ID: number) {
    let seqNo = 0;
    for (const node of nodes) {
      seqNo += 10;
      dataToSave.push({ node_ID: node.data.id, parent_ID: parent_ID, seqNo: seqNo });
      if (node.children && node.children.length > 0) {
        this.prepareDataToSave(node.children, dataToSave, node.data.id);
      }
    }
  }

  nodeSelect({ originalEvent, node }: { originalEvent: Event; node: TreeNode }) {
    originalEvent.stopPropagation();
    this.selectItem.emit(this.treeData.columnKey + ',' + node.data.id);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.listener();
  }
}
