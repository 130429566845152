@if (parsedXml) {
  <div class="xmlContent">
    @for (node of nodes; track node) {
      <div
        [class]="'xmlNode node-level-' + indentLevel"
        [style.color]="childColor"
        [style.marginLeft.px]="indentLevel * 10">
        @if (node.nodeType === 1 && node.nodeName != 'html') {
          <div
            (click)="toggleTag(node)"
            class="xmlNodeTag openingTag"
            [ngClass]="node.classList.contains('closed') ? 'pi-chevron-right' : 'pi-chevron-down'">
            &lt;<span class="xmlNodeName">{{ node.tagName }}</span>
            @if (node.attributes > 0) {
              @for (attribute of node.attributes; track attribute) {
                <div class="xmlNodeAttr">
                  @if (attribute.name != 'class') {
                    <span class="xmlNodeAttrName">{{ ' ' + attribute.name + ' = ' }}</span>
                    <span class="xmlNodeAttrValue">{{ '"' + attribute.value + '"' }}</span>
                  }
                </div>
              }
            }
            &gt;
          </div>
          <div [ngClass]="{ closed: node.classList.contains('closed') }">
            <iu-xml-viewer [node]="node" [indentLevel]="indentLevel"></iu-xml-viewer>
          </div>
          @if (!node.classList.contains('closed')) {
            <div (click)="toggleTag(node)" class="xmlNodeTag" [class.parent]="node.children.length > 0">
              &lt;/<span class="xmlNodeName">{{ node.tagName }}</span
              >&gt;
            </div>
          }
        }
        @if (node?.nodeType == 3) {
          <div class="xmlNodeText">{{ node.textContent }}</div>
        }
      </div>
    }
  </div>
}
@if (!parsedXml) {
  <div>
    <!-- Display error message if any -->
    <p>{{ 'generic.loadingOoo' | translate }}</p>
  </div>
}
