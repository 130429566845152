import { Injectable, inject, signal } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  connectorService = inject(SecurityManagerService);

  availableThemes: { items: Theme[] };

  #activeTheme = signal<Theme>(null);
  active = this.#activeTheme.asReadonly();

  constructor() {
    const themes = <ThemeConfig>environment.themes;
    this.availableThemes = { items: [] };
    let themeId = 1;

    for (const themeKey of Object.keys(themes)) {
      const displayValue = themes[themeKey]['__name'] ?? themeKey;

      this.availableThemes.items.push({
        id: themeId++,
        key: themeKey,
        displayValue,
        data: themes[themeKey],
      });
    }
  }

  getAvailableThemes() {
    return this.availableThemes;
  }

  getActiveTheme() {
    return this.#activeTheme();
  }

  getThemeProperty(propertyName: string) {
    return this.#activeTheme().data[propertyName];
  }

  setActiveTheme(themeKey?: string): void {
    if (!themeKey) {
      themeKey = this.connectorService.getIupicsUserContext()['#UITheme'];
    }

    const next = this.availableThemes.items.find((theme) => theme['key'] === themeKey);

    // Retrieves theme style
    let variablesStyle = document.querySelector<HTMLStyleElement>('[data-theme]') ?? null;
    if (!variablesStyle) {
      variablesStyle = document.createElement('style');
      document.head.appendChild(variablesStyle);
    }
    // override theme's variables
    variablesStyle.setAttribute('data-theme', themeKey);
    const fontStyles = Array.from(document.querySelectorAll('[data-theme-font]'));
    for (const fontStyle of fontStyles) fontStyle.remove();
    variablesStyle.textContent = `:root {\n`;
    const keys = Object.keys(next.data);

    let gridVariableStyle = `[data-theme] {`;
    let apizGridVariableStyle = `[data-theme='apiz-grid-theme-base'] {`;
    for (const property of keys) {
      if (property === '__name') {
        continue;
      }

      // TODO: remove this when old ag grid mapping is removed
      if (property.startsWith('ag-')) {
        gridVariableStyle += `--${property}: ${next.data[property]};\n`;
        continue;
      }

      if (property.startsWith('az-')) {
        apizGridVariableStyle += `--${property}: ${next.data[property]};\n`;
        continue;
      }

      if (property.startsWith('font-family')) {
        // Retrieves font from google api
        const fontFamily = next.data[property].split('"')[1] || '';
        if (
          fontFamily !== '' &&
          // Checks if font isn't already loaded
          ![...(<any>document.querySelectorAll('[data-theme-font]'))].find(
            (el) => el.getAttribute('data-theme-font') === fontFamily
          )
        ) {
          // Creates style element for new font
          const fontStyle = document.createElement('style');
          fontStyle.setAttribute(`data-theme`, themeKey);
          fontStyle.setAttribute(`data-theme-font`, fontFamily);
          fontStyle.textContent = `@import url('https://fonts.googleapis.com/css2?family=${next.data[property]
            .split('"')[1]
            .replace(
              / /g,
              '+'
            )}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');`;
          // Handles error: deletes style element & remove font family variable
          fontStyle.onerror = () => {
            variablesStyle.textContent.replace(`--${property}: ${next.data[property]};\n`, '');
            fontStyle.remove();
          };
          document.head.appendChild(fontStyle);
        }

        variablesStyle.textContent += `--${property}: ${next.data[property]};\n`;
      }

      variablesStyle.textContent += `--${property}: ${next.data[property]};\n`;
    }
    variablesStyle.textContent += `}\n${gridVariableStyle}}\n${apizGridVariableStyle}\n`;

    this.#activeTheme.set(next);
  }
}

type ThemeData = {
  __name: string;
  [key: string]: any; // This should be string | string[]
};

type ThemeConfig = {
  [key: string]: ThemeData;
};

export type Theme = {
  id: number;
  key: string;
  displayValue: string;
  data: ThemeData;
};

export enum ThemeVariableKeys {
  AvatarFtColor = '--avatar-ui-ft-color',
}
