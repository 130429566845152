<div class="gantt-container">
  <div class="gantt-header" #ganttHeader>
    @if (showCollapseButton) {
      <button class="gantt-header-btn" (click)="updateDisplayStatus(false)">
        <i class="fas fa-minus-square" aria-hidden="true"></i>
      </button>
    }
    @if (showExpandButton) {
      <button class="gantt-header-btn" (click)="updateDisplayStatus(true)">
        <i class="fas fa-plus-square" aria-hidden="true"></i>
      </button>
    }
    @if (showUndoButton) {
      <button class="gantt-header-btn" (click)="gantt.undo()">
        <i class="fas fa-undo" aria-hidden="true"></i>
      </button>
    }
    @if (showRedoButton) {
      <button class="gantt-header-btn" (click)="gantt.redo()">
        <i class="fas fa-redo" aria-hidden="true"></i>
      </button>
    }
    @if (showZoomInButton) {
      <button class="gantt-header-btn" (click)="gantt.ext.zoom.zoomIn()">
        <i class="fas fa-search-plus" aria-hidden="true"></i>
      </button>
    }
    @if (showZoomOutButton) {
      <button class="gantt-header-btn" (click)="gantt.ext.zoom.zoomOut()">
        <i class="fas fa-search-minus" aria-hidden="true"></i>
      </button>
    }
    <button
      [disabled]="!paginationEnable || paginationInProgress"
      class="gantt-header-btn"
      (click)="handlePageBtn($event, 'prev')">
      <i class="fas fa-step-backward" aria-hidden="true"></i>
    </button>
    <div class="gantt-header-item">
      {{
        maximumPage === -1
          ? ('gantt.more-page' | translate: { actualPage: actualPage })
          : ('gantt.fixed-page' | translate: { actualPage: actualPage, maximumPage: maximumPage })
      }}
    </div>
    <button
      [disabled]="!paginationEnable || paginationInProgress"
      class="gantt-header-btn"
      (click)="handlePageBtn($event, 'next')">
      <i class="fas fa-step-forward" aria-hidden="true"></i>
    </button>
    <!-- <button class="gantt-header-btn" (click)="printToConsole()">
    <i class="fas fa-laptop-code" aria-hidden="true"></i>
  </button> -->
    @if (hasUniversalFilter) {
      <div class="gantt-universal-filter">
        <iu-universal-filter-standalone-ui
          [tabId]="uf_formId"
          [formId]="uf_formId"
          [windowType]="IupicsMenuType.FORM"
          [setFilterEmitter]="setFilterEmitter" />
      </div>
    }
  </div>
  <div #ganttDiv [ngStyle]="{ height: ganttHeight - ganttHeader.clientHeight + 'px' }" class="gantt-chart"></div>
</div>
