import { Injectable, Injector, OnDestroy, Optional, SkipSelf, inject } from '@angular/core';
import {
  CompiereDataFieldType,
  CompiereDataGridFilterType,
  DataStore,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import {
  ColumnFilterAutocomplete,
  OperatorFilterAutocomplete,
} from '@iupics-components/models/autocomplete-interfaces';
import { FilterOperator, OperatorFilterType, filterOperators } from '@iupics-components/models/universal-filter';
import { AZ_DataToDisplay, UniversalFilterUtils } from '@iupics-components/standard/menu/utils/universal-filter-utils';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { AppliedItem, FilterType, createInjectionToken } from '@iupics/apiz-grid';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import GridTabInfinityScrollUiComponent from '../../grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import GridViewUiComponent from '../../grid-view-ui/grid-view-ui.component';

@Injectable()
export class FilterCtxService implements OnDestroy {
  uuid = self.crypto.randomUUID();
  #gridTab: GridTabInfinityScrollUiComponent | undefined;
  #gridView: GridViewUiComponent | undefined;
  #initialDatastore: DataStore | undefined;
  /** Only used for universal-filter-ui.component and filter.component */
  #localDatastore: DataStore | undefined;
  #ctxArea: any = {};

  #selected: AZ_DataToDisplay;

  #selectionColumns: ColumnFilterAutocomplete[] = [];
  #columnNames: { items: ColumnFilterAutocomplete[] };
  #defaultColumn: ColumnFilterAutocomplete;

  #operators: { items: OperatorFilterAutocomplete[] };
  #defaultOperator: OperatorFilterAutocomplete;

  #store = inject(DataStoreService);
  #uiCreator = inject(UICreatorService);
  #messageManager = inject(MessageManagerService);
  #translator = inject(TranslateService);
  #ufUtils = inject(UniversalFilterUtils);

  #columns: any[];

  ngOnDestroy(): void {
    this.unregisterGrid(this.#gridView ?? this.#gridTab);
  }

  registerGrid(grid: GridViewUiComponent | GridTabInfinityScrollUiComponent) {
    if (grid instanceof GridViewUiComponent) this.#setupGridView(grid);
    if (grid instanceof GridTabInfinityScrollUiComponent) this.#setupGridTab(grid);
    this.#init();
  }

  #setupGridView(grid: GridViewUiComponent) {
    this.#gridView = grid;
    const isFromWindow = this.#gridView.windowType === IupicsMenuType.WINDOW;
    if (isFromWindow) {
      const container = this.#gridView.container;
      let windowId = this.#gridView.data.AD_Window_ID;
      if (container && windowId == null) {
        windowId = container.infoComponent ? container.infoComponent.windowId : null;
      }
      this.#uiCreator
        .getActualTab(windowId, this.#gridView.tabId)
        .subscribe((tabUI) => (this.#ctxArea = cloneDeep(tabUI.firstTab.editView.data.ctxArea || {})));
    }
  }

  #setupGridTab(grid: GridTabInfinityScrollUiComponent) {
    this.#gridTab = grid;
    if (grid.isSearch) {
      this.#columns = grid.data.searchColumns;
    }
  }

  setColumns(columns: any[]): void {
    this.#columns = columns;
  }

  #init(): void {
    this.#initLocalDatastore();
    this.#initColumns();
    this.#initOperatorFilters();
    this.#initData();
  }

  #initLocalDatastore(): void {
    const container = this.provideContainer();
    const tabId = this.#gridView?.tabId ?? this.#gridTab?.tabId ?? -1;
    const windowType = container?.windowType;
    if (windowType) {
      switch (windowType) {
        case IupicsMenuType.FORM:
          const formId = container.formId;
          this.#localDatastore = this.#store.newSpecificWindowData(formId);
          break;
        case IupicsMenuType.WINDOW:
          // put random windowid when its a searchpanel
          const windowId = container.infoComponent ? container.infoComponent.windowId : null;
          if (windowId) {
            this.#localDatastore = this.#store.newWindowData(windowId, tabId > 0 ? tabId : null);
          } else if (this.#columns) {
            // build own datastructure to fit this searchpanel columns
            const dataTransformed: DataStore = new DataStore();
            const newStructure = {};
            for (const col of this.#columns) {
              if (col.field) newStructure[col.field.ColumnName] = null;
            }
            const generatedTabId = uuid();
            const generatedWindowId = uuid();
            this.#store.addWindowDataStructure(generatedTabId, newStructure);
            const dataStorekey = this.#store.generateDataStoreKey(generatedWindowId, generatedTabId, uuid(), null);
            Object.assign(dataTransformed.data, newStructure);
            dataTransformed.key = dataStorekey;
            dataTransformed.status = DataStoreStatus.NEWRECORD;
            this.#localDatastore = dataTransformed;
          }
          break;
        case IupicsMenuType.PROCESS:
          this.#localDatastore = this.#store.newProcessData(container.windowId);
          break;
        default:
          this.#localDatastore = new DataStore();
          break;
      }
    } else {
      this.#localDatastore = new DataStore();
    }
    // 131192 Avoid to set defaultValue for thoses columns
    this.#localDatastore.data['DocStatus'] = null;
    this.#localDatastore.data['DocAction'] = null;
    this.#initialDatastore = cloneDeep(this.#localDatastore);
  }

  reinitLocalDatastore() {
    this.#localDatastore = cloneDeep(this.#initialDatastore);
  }

  #initColumns(): void {
    const container = this.provideContainer();
    const tabId = this.#gridView?.tabId ?? this.#gridTab?.tabId ?? -1;
    const windowType = container?.windowType;
    if (tabId === -1) {
      try {
        const columns = this.#columns;
        const columnInfos = {
          items: columns.map<ColumnFilterAutocomplete>((column) => {
            return {
              id: column.field.ColumnName,
              displayValue: column.field.name,
              columnInfo: {
                fieldEntity: column,
                filterType: UICreatorUtils.getFilterTypeFromReference(column.field.AD_Reference_ID),
              },
            };
          }),
        };
        FilterCtxService.sortColumnInfosItemsByDisplayValue(columnInfos);
        this.#selectionColumns = columnInfos.items
          .filter(
            ({ columnInfo: { fieldEntity } }) =>
              [10, 14, 40, 38, 39].includes(fieldEntity.field.AD_Reference_ID) &&
              (fieldEntity?.field?.isQueryCriteria ?? fieldEntity?.field?.IsSelectionColumn)
          )
          .sort((a, b) =>
            a.columnInfo.fieldEntity.field.SelectionSeqNo > b.columnInfo.fieldEntity.field.SelectionSeqNo
              ? 1
              : a.columnInfo.fieldEntity.field.SelectionSeqNo < b.columnInfo.fieldEntity.field.SelectionSeqNo
                ? -1
                : 0
          );
        this.#defaultColumn = this.#selectionColumns[0];
        this.#columnNames = columnInfos;
      } catch (err) {
        console.error(err);
        this.#messageManager.newMessage(new IupicsMessage('Erreur', err, 'error', err.stack));
      }
    } else {
      try {
        const columns = this.#uiCreator.getColumnInfosSync(
          tabId,
          this.#gridView?.isSpecificGrid ?? windowType === 'Form'
        );
        const columnInfos = {
          items: columns.reduce((acc, ci) => {
            if (
              ((ci.fieldEntity.field.Name !== null &&
                ci.fieldEntity.field.Name !== undefined &&
                ci.fieldEntity.field.Name.trim() !== '') ||
                (ci.fieldEntity.field.name !== null &&
                  ci.fieldEntity.field.name !== undefined &&
                  ci.fieldEntity.field.name.trim() !== '')) &&
              (ci.fieldEntity.field.AD_Field_ID === -2 ||
                ci.fieldEntity.field.IsDisplayed ||
                ci.fieldEntity.field.IsKey)
            ) {
              acc.push({
                id: ci.fieldEntity.field.ColumnName,
                displayValue:
                  ci.fieldEntity.field.AD_Field_ID === -2
                    ? this.#translator.instant('mandatoryColumns.' + ci.fieldEntity.field.ColumnName)
                    : (ci.fieldEntity.field?.Name ?? ci.fieldEntity.field?.name),
                columnInfo: ci,
              });
            }

            return acc;
          }, []),
        };
        FilterCtxService.sortColumnInfosItemsByDisplayValue(columnInfos);
        this.#selectionColumns = columnInfos.items
          .filter((cfa) => cfa.columnInfo.fieldEntity.field.IsSelectionColumn === true)
          .sort((a, b) =>
            a.columnInfo.fieldEntity.AD_FormDetail_ID > 0
              ? a.columnInfo.fieldEntity.SeqNo - b.columnInfo.fieldEntity.SeqNo
              : a.columnInfo.fieldEntity.field.SelectionSeqNo - b.columnInfo.fieldEntity.field.SelectionSeqNo
          );
        this.#defaultColumn = this.#selectionColumns[0];
        this.#columnNames = columnInfos;
      } catch (err) {
        console.error(err);
        this.#messageManager.newMessage(
          new IupicsMessage(this.#translator.instant('generic.error'), err, 'error', err.stack)
        );
      }
    }
  }

  #initOperatorFilters() {
    try {
      const _filterOperators = [...filterOperators];
      const operators = _filterOperators.map((op) => ({ ...op, ...{ label: this.#translator.instant(op.label) } }));
      const ofas = {
        items: operators.map((operator: FilterOperator) => ({
          id: operator.id,
          displayValue: operator.label,
          operator: operator,
        })),
      };
      this.#defaultOperator = ofas.items.find(
        (ofa) =>
          ofa.operator.filterType === CompiereDataGridFilterType.TEXT &&
          ofa.operator.type === OperatorFilterType.CONTAINS
      );
      this.#operators = ofas;
    } catch (err) {
      this.#messageManager.newMessage(new IupicsMessage(this.#translator.instant('generic.error'), err, 'error'));
    }
  }

  public static sortColumnInfosItemsByDisplayValue(columnInfos: { items: ColumnFilterAutocomplete[] }) {
    columnInfos.items.sort((a, b) => a.displayValue.toLowerCase().localeCompare(b.displayValue.toLowerCase()));
    return columnInfos;
  }

  #initData() {
    this.#selected = {
      favorite: '',
      isDefault: false,
      filters: [],
      notUFData: {},
    };
  }

  updateLocalDatastore(filters: AppliedItem<'filter'>[]) {
    const filtersToDisplay = this.#ufUtils.appliedItemsToFilterToDisplay(
      this.#columnNames.items,
      this.#operators.items,
      filters
    );
    let dataModified = {};
    for (let i = 0; i < (filtersToDisplay.length ?? 0); i++) {
      const ftd = filtersToDisplay[i];
      const idx = this.#selected.filters.findIndex((filter) => ftd.column.id === filter.column.id);
      if (idx === -1) this.#selected.filters.push(ftd);
      else this.#selected.filters[idx] = ftd;

      dataModified = {
        ...dataModified,
        ...this.setDatacontainerValue(ftd.column.id, ftd.filter, ftd.operator.operator.filterType),
      };
      if (ftd.filterTo !== undefined) {
        dataModified = {
          ...dataModified,
          ...this.setDatacontainerValue(ftd.column.id + '_To', ftd.filterTo, ftd.operator.operator.filterType),
        };
      }
    }
    this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
  }

  setDatacontainerValue(key: string, value: any, filterType: FilterType) {
    const dataModified = {};
    dataModified[key] = value;
    if (this.#gridView?.fromForm && this.#gridView?.container?.dataStore) {
      this.#gridView.container.setDatacontainerValue(
        key,
        filterType === FilterType.SET ? dataModified[key][0] : dataModified[key]
      );
    }
    return dataModified;
  }

  unregisterGrid(_: GridViewUiComponent | GridTabInfinityScrollUiComponent) {
    this.#gridView = undefined;
    this.#gridTab = undefined;
  }

  hasGridView(): boolean {
    return this.#gridView !== undefined && this.#gridView !== null;
  }

  hasGridTab(): boolean {
    return this.#gridTab !== undefined && this.#gridTab !== null;
  }

  provideCtxArea(): { [key: string]: any } {
    return this.#ctxArea;
  }

  provideLocalDatastore(): DataStore {
    if (!this.#localDatastore) this.#initLocalDatastore();
    return this.#localDatastore;
  }

  provideInitialDatastore(): DataStore {
    if (!this.#initialDatastore) this.#initLocalDatastore();
    return this.#initialDatastore;
  }

  provideSelectionColumns() {
    return this.#selectionColumns;
  }

  provideColumnNames(): { items: ColumnFilterAutocomplete[] } {
    return this.#columnNames;
  }

  provideDefaultColumn(): ColumnFilterAutocomplete {
    return this.#defaultColumn;
  }

  provideOperators(): { items: OperatorFilterAutocomplete[] } {
    return this.#operators;
  }

  provideDefaultOperator(): OperatorFilterAutocomplete {
    return this.#defaultOperator;
  }

  provideContainer(): any {
    return this.#gridTab?.container ?? this.#gridView?.container ?? null;
  }

  provideGridView(): GridViewUiComponent {
    return this.#gridView;
  }

  provideGridTab(): GridTabInfinityScrollUiComponent {
    return this.#gridTab;
  }

  provideGetCurrentContext() {
    return (() => {
      const parentContext = this.#gridTab
        ? this.#gridTab.getCurrentContext()
        : this.#gridView
          ? this.#gridView.getCurrentContext()
          : {};
      const data = {};
      const keys = [
        ...Object.keys(parentContext),
        ...Object.keys(this.#localDatastore.data),
        ...Object.keys(this.#ctxArea),
      ];
      for (const key of keys) {
        if (this.#localDatastore.data[key]) data[key] = this.#localDatastore.data[key];
        else if (parentContext[key]) data[key] = parentContext[key];
        else if (this.#ctxArea && this.#ctxArea[key]) data[key] = this.#ctxArea[key];
      }
      return data;
    }).bind(this);
  }

  getFieldType(): CompiereDataFieldType {
    let fieldType = CompiereDataFieldType.FIELD;
    const gridTab = this.provideGridTab();
    const isSearch = gridTab ? gridTab.isSearch : false;
    if (!isSearch) {
      const container = this.provideContainer();
      const windowType = container.windowType;
      switch (windowType) {
        case IupicsMenuType.FORM:
          const gridView = this.provideGridView();
          fieldType = gridView?.infoWindowId ? CompiereDataFieldType.COLUMN_INFO : CompiereDataFieldType.FORM_ITEM;
          break;
        case IupicsMenuType.PROCESS:
          fieldType = CompiereDataFieldType.PROCESS_PARA;
          break;
        case IupicsMenuType.WINDOW:
          fieldType = CompiereDataFieldType.FIELD;
          break;
        default:
          break;
      }
    }
    return fieldType;
  }
}

export const [injectFilterCtxService, provideFilterCtxService] = createInjectionToken(
  (parentInjector: Injector, filterCtxService?: FilterCtxService) => {
    if (!filterCtxService) {
      const injector = Injector.create({
        providers: [{ provide: FilterCtxService }],
        parent: parentInjector,
      });
      filterCtxService = injector.get(FilterCtxService);
    }
    return filterCtxService;
  },
  { isRoot: false, deps: [Injector, [new Optional(), new SkipSelf(), FilterCtxService]] }
);
