<ul class="input-chips">
  @for (item of selected(); track item.id) {
    @if (!this.edition() || item.id !== this.edition().id) {
      <li class="input-chip">
        <span class="input-chip-text" (click)="onEdit($event, item)">
          {{ item.name }}: {{ item?.displayValue ?? item.value }}
        </span>
        <i
          class="input-chip-btn icon-cross"
          aria-hidden="true"
          (click)="onRemove($event, item)"
          (keypress.Enter)="removeValue(item, false)"></i>
      </li>
    } @else {
      <li class="input-chip input-chip-editor">
        <ng-container
          [ngTemplateOutlet]="editing"
          [ngTemplateOutletContext]="{
            groupName: editionGroup()?.groupName,
            items: editionActiveItems(),
            fct: updateValue.bind(this),
            blurEvent: true,
          }" />
      </li>
    }
  }

  @if (!edition()) {
    <li class="input-wrapper" [ngClass]="{ 'input-filled': activeGroupName()?.length > 0 }">
      @if (activeGroupName()) {
        <ng-container
          [ngTemplateOutlet]="editing"
          [ngTemplateOutletContext]="{
            groupName: activeGroupName(),
            items: activeItems(),
            fct: addValue.bind(this),
            blurEvent: true,
          }" />
      } @else {
        <ng-container
          [ngTemplateOutlet]="input"
          [ngTemplateOutletContext]="{
            items: groupFiltered(),
            placeholder: groupFiltered()?.length > 0 ? placeholder() : '',
            fct: addGroupNameValue.bind(this),
            blurEvent: false,
          }" />
      }
    </li>
  }
</ul>

<i class="input-multi-level-icon icon-hashtag" aria-hidden="true"></i>

<ng-template #editing let-groupName="groupName" let-items="items" let-fct="fct" let-blurEvent="blurEvent">
  <span> {{ groupName }}: </span>
  <ng-container
    [ngTemplateOutlet]="input"
    [ngTemplateOutletContext]="{
      items: items,
      placeholder: undefined,
      fct: fct,
      blurEvent: blurEvent,
    }" />
</ng-template>

<ng-template #input let-items="items" let-placeholder="placeholder" let-fct="fct" let-blurEvent="blurEvent">
  <iu-autocomplete-ui
    #autocomplete
    [cssClass]="'multi-level-autocomplete-input p-col-12 p-md-6 p-lg-4'"
    label=""
    [data]="{ items: items }"
    [fieldValue]="fieldValue"
    (fieldValueModified)="fct($event)"
    (blurEmitter)="blurEvent && !edition() ? removeItemIfEmpty() : reverseEdition()"
    (keydown)="removeItemOnBackspace($event)"
    [placeholder]="placeholder"
    [isStandalone]="true"
    [styleLess]="true" />
</ng-template>
