import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { StorekeeperInventoryComponent } from './components/storekeeper-inventory/storekeeper-inventory.component';
import { StorekeeperToolbarComponent } from './components/storekeeper-toolbar/storekeeper-toolbar.component';
import { StorekeeperTransferLineDetailsComponent } from './components/storekeeper-transfer/storekeeper-transfer-line-details/storekeeper-transfer-line-details.component';
import { StorekeeperTransferComponent } from './components/storekeeper-transfer/storekeeper-transfer.component';
import { StorekeeperWindowListComponent } from './components/storekeeper-window-list/storekeeper-window-list.component';
import { StorekeeperWindowMessagesComponent } from './components/storekeeper-window-messages/storekeeper-window-messages.component';
import { SKWScannerService } from './services/storekeeper-scanner.service';
import { SKWContextService } from './services/storekeeper-window-context.service';
import { SKWMessageService } from './services/storekeeper-window-message.service';
import { SKWNavigationService } from './services/storekeeper-window-navigation.service';
import { SKWTranslateService } from './services/storekeeper-window-translate.service';
import { SKWDataService } from './services/strokeeper-window-data.service';

@Component({
  selector: 'iu-storekeeper-window-ui',
  templateUrl: './storekeeper-window-ui.component.html',
  styleUrl: './storekeeper-window-ui.component.scss',
  standalone: true,
  imports: [
    ProgressBarModule,
    NgTemplateOutlet,
    StorekeeperWindowListComponent,
    StorekeeperToolbarComponent,
    StorekeeperInventoryComponent,
    StorekeeperTransferComponent,
    StorekeeperTransferLineDetailsComponent,
    StorekeeperWindowMessagesComponent,
  ],
  providers: [
    SKWDataService,
    SKWNavigationService,
    SKWContextService,
    SKWMessageService,
    SKWTranslateService,
    SKWScannerService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorekeeperWindowUiComponent extends SpecificWindowUiComponent {
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWContextService = inject(SKWContextService);
  #SKWTranslateService = inject(SKWTranslateService);

  state = this.#SKWContextService.state;
  page = this.#SKWNavigationService.page;

  constructor() {
    super();
    this.#SKWContextService.setNavService(this.#SKWNavigationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.#SKWContextService.container = this;
    this.#SKWTranslateService.setTranslations(this.fields, ['M_Product_ID', 'M_Locator_ID', 'XX_Pallet_ID']);
  }
}
