import { NgClass } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UserAccount } from '@iupics-manager/models/user-account';
import { DocIconPipe } from '@iupics-util/pipes/doc-icon/doc-icon.pipe';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { TranslateModule } from '@ngx-translate/core';
import InputMentionUiComponent from '../../fields/input-mention-ui/input-mention-ui.component';
import AvatarUiComponent from '../../user/avatar-ui/avatar-ui.component';
import NotesPanelUiComponent from '../notes-panel-ui/notes-panel-ui.component';
import { NoteEntry, NoteType } from '../notes.interface';
@Component({
  selector: 'iu-note-ui',
  templateUrl: './note-ui.component.html',
  styleUrls: ['./note-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, AvatarUiComponent, InputMentionUiComponent, TranslateModule, TextLimitPipe, DocIconPipe],
})
export default class NoteUiComponent implements OnInit {
  @Input() note: NoteEntry;
  @Input() type: NoteType = 'TEXT';
  @Input() parent: NotesPanelUiComponent;

  /* Users infos */
  me: UserAccount;

  constructor(
    private el: ElementRef<HTMLElement>,
    private connectorService: SecurityManagerService
  ) {}

  ngOnInit(): void {
    this.me = this.connectorService.getIupicsUserAccount();
  }
}
