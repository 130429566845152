import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { DataStore, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import PrimeCalendarComponent from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TooltipModule } from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-time-ui',
  templateUrl: './input-time-ui.component.html',
  styleUrls: ['./input-time-ui.component.scss'],
  standalone: true,
  imports: [
    TooltipModule,
    PrimeCalendarComponent,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
  ],
})
export default class InputTimeUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @ViewChild('calendar', { static: true })
  calendar: PrimeCalendarComponent;

  data: IupicsDataField;

  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;
  @Input()
  set fieldValue(value: any) {
    this.calendar.value = value;
    this.calendar.setFieldValue();
  }

  get fieldValue() {
    return this.calendar.inputFieldValue;
  }

  @Input()
  hourFormat = '24';

  constructor() {
    super();
    this.isDateField = true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    this.setFieldMandatory();
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.fieldValue) {
      this.calendar.setFieldValue();
    }
    this.checkFocus();
  }

  checkFocus() {
    if (
      this.data?.IsDefaultFocus &&
      !this.isReadOnly &&
      this.editViewParent?.editTabs?.[0]?.dataStored?.status === DataStoreStatus.NEWRECORD &&
      (this.fieldValue === null || this.fieldValue === undefined)
    ) {
      this.calendar.inputRef.nativeElement.focus();
    }
  }

  setFieldMandatory() {
    if (this.data?.mandatoryLogic) {
      this.data.isMandatory = LogicEvaluator.evaluateLogic(
        this.getCurrentContext(this.dataStored, false),
        this.data.mandatoryLogic
      );
    }

    this.mandatoryCss = this.data?.isMandatory ? ' iu-field-mandatory ' : ' iu-field ';
  }

  fieldChange(event: any) {
    this.dataChange(event);
  }

  setNewData(dataStored: DataStore, isInit = false) {
    super.setNewData(dataStored, isInit);
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }
}
