import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, OnInit } from '@angular/core';
import SmartButtonUiComponent from '@iupics-components/standard/fields/smart-button-ui/smart-button-ui.component';
import { AppConfig } from '@iupics-config/app.config.service';
import { injectSmartButtonManagerService } from '@iupics-manager/managers/smart-button-manager/smart-button-manager.service';
import { Global } from '@iupics-manager/models/global-var';

@Component({
  selector: 'iu-menu-smartbuttons-ui',
  templateUrl: './menu-smartbuttons-ui.component.html',
  styleUrls: ['./menu-smartbuttons-ui.component.scss'],
  standalone: true,
  imports: [SmartButtonUiComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class MenuSmartbuttonsUiComponent implements OnInit {
  readonly #ref: ElementRef<HTMLElement> = inject(ElementRef);
  readonly #config = inject(AppConfig);
  readonly #smManager = injectSmartButtonManagerService();

  tabId = input.required<number>();
  windowId = input.required<number>();

  widthAvailable: number;
  isMoreButton = true;

  get registration() {
    return this.#smManager.registration;
  }

  get buttons() {
    return this.registration.data();
  }

  buttonWidth = this.#config.getConstant(
    `SmartButtonUiComponent#buttonWidth_${Global.isMobileWidth() ? 'mobile' : 'not-mobile'}`
  );

  subscription: any;

  get wrapperWidth() {
    return this.#ref.nativeElement.getBoundingClientRect().width;
  }

  displayed = computed(() => {
    const btnVisible = Math.floor(this.wrapperWidth / this.buttonWidth);
    const btns = this.registration.data()?.filter((b) => !b.isOpened && b.key !== this.windowId());
    return {
      visible: btns?.slice(0, btnVisible) ?? [],
      list: btns?.slice(btnVisible) ?? [],
    };
  });

  ngOnInit() {
    this.isMoreButton = false;
  }

  toggle(id: number) {
    this.#smManager.toggleSmartButton(id);
  }
}

export type SmartButtonMetaData = {
  tabId: number;
  recordId: string;
  parent: any;
};
