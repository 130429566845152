<div [ngClass]="[locationPanelPosition, 'locationPanel p-col-12']">
  <ng-container [ngTemplateOutlet]="isNewLocationEnable ? nlLocationPanel : legacyLocationPanel" />

  <div class="actions">
    <button
      pButton
      class="p-button-secondary"
      style="cursor: pointer; width: 50%"
      (click)="openMap()"
      label="{{ 'locationpanel.map' | translate }}"
      icon="fas fa-map-marked-alt"
      iconPos="left"></button>
    <button
      pButton
      style="width: 50%"
      type="button"
      class="p-button p-button-primary"
      label="{{ 'generic.save' | translate }}"
      (click)="save($event)"
      data-cy="save-location"></button>
  </div>

  <div>
    <button
      pButton
      class="p-button-secondary"
      style="width: 50%"
      type="button"
      label="{{ 'generic.cancel' | translate }}"
      (click)="cancel($event)"></button>
    <button
      pButton
      class="p-button-secondary"
      style="width: 50%"
      type="button"
      label="{{ 'generic.clear' | translate }}"
      (click)="clearLocation($event)"></button>
  </div>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss"
    [appendTo]="'body'">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

<ng-template #legacyLocationPanel>
  <div class="locationPanel-content">
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 0">
      <ng-template #vcrAddress1 />
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 1">
      <ng-template #vcrAddress2 />
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 2">
      <ng-template #vcrAddress3 />
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 3">
      <ng-template #vcrAddress4 />
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot >= 4">
      <a
        data-cy="add-address-line"
        class="button p-button-secondary"
        style="cursor: pointer"
        (click)="data.nbrAddressSlot = data.nbrAddressSlot + 1"
        ><span class="icon-add"></span> {{ 'locationpanel.addressline' | translate }}
      </a>
    </div>
    <div class="locationPanel-field-semiwidth">
      <ng-template #vcrPostal />
    </div>
    <div [hidden]="!hasLocation" class="locationPanel-field-semiwidth">
      <ng-template #vcrC_City_ID />
    </div>
    <div [hidden]="hasLocation" class="locationPanel-field-semiwidth">
      <ng-template #vcrCity />
    </div>
  </div>
  <div
    [hidden]="!this.dataStore || (!this.dataStore.data && !this.dataStore.data['C_Country_ID'])"
    class="locationPanel-field-semiwidth">
    <ng-template #vcrC_Region_ID />
  </div>
  <div class="locationPanel-field-semiwidth">
    <ng-template #vcrC_Country_ID />
  </div>
</ng-template>

<ng-template #nlLocationPanel>
  <div class="locationPanel-content">
    <div class="locationPanel-field-fullwidth">
      <ng-template iuDynamicContainer />
    </div>
  </div>
</ng-template>
