export enum ViewType {
  GRID = 'grid',
  KANBAN = 'kanban',
  CALENDAR = 'calendar',
  CHART = 'chart',
  TREE = 'tree',
  LIST = 'list',
  CARDS = 'cards',
}

export enum ChartType {
  PIE = 'pie',
  LINE = 'line',
  AREA = 'area',
  SCATTER = 'scatter',
  BUBBLE = 'bubble',
  POLARAREA = 'polarArea',
  RADAR = 'radar',
  BAR = 'bar',
  COLUMN = 'column',
  DOUGHNUT = 'doughnut',
  GEN = 'gen',
}
