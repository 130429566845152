<span
  [style.backgroundColor]="backgroundColorCss"
  [style.display]="displayCss"
  [style.width]="overridedCSS?.width"
  class="iu-input-color-ui"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  @if (this.isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0) {
    <label
      [style.font-size]="overridedCSS?.labelFontSize"
      [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }"
      [title]="label">
      {{ label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}
    </label>
  }
  <p-colorPicker
    #input
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [attr.value]="fieldValue"
    [ngClass]="[hasConflict ? 'iu-field-conflict-text' : '', mandatoryCss, 'ui-corner-all input-field p-inputcolor']"
    [appendTo]="'body'"
    [(ngModel)]="fieldValue"
    (contextmenu)="onContextMenu($event)"
    [disabled]="isReadOnly"
    [tabindex]="isReadOnly ? '-1' : null"
    (onChange)="dataChange($event.value); fieldValueChange.emit($event.value)"></p-colorPicker>
  @if (hasConflict && dataStored) {
    <i
      #spanInput
      class="rtconflict fas fa-exclamation-triangle iconClass"
      (click)="showConflictPanel($event)"
      aria-hidden="true"></i>
  }
  @if (showValuePrefPanel) {
    <iu-prime-overlay #opValuePref [dismissable]="false" appendTo="body">
      <iu-value-preference-panel
        [overlayPanel]="opValuePref"
        [fieldValue]="fieldValue"
        [dataStored]="this.dataStored"
        [sourceComponent]="this"
        [data]="this.data"
        (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
    </iu-prime-overlay>
  }
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>
