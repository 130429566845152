<iu-storekeeper-window-input-scan [(data)]="inputData" />
<h3 class="title">{{ 'ConvertToConditioning' | SKWTranslate }}</h3>
<iu-storekeeper-input
  [type]="'text'"
  [label]="'Pallet' | SKWTranslate"
  [placeholder]="'ExistingPallet' | SKWTranslate"
  [value]="inputData()?.palette?.displayValue ?? ''"
  [readOnly]="true" />
<iu-storekeeper-button class="button" [label]="'Convert' | SKWTranslate" (click)="convert($event)" />
<iu-storekeeper-button [label]="'Cancel' | SKWTranslate" [type]="'outlined'" (click)="cancel($event)" />
