<span
  [style.backgroundColor]="backgroundColorCss"
  [style.display]="displayCss"
  class="iu-input-chips-ui"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  @if (this.isLabelDisplay) {
    <label for="float-input" [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }" [title]="label">
      {{ label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}
    </label>
  }
  <iu-prime-chips
    #input
    [(ngModel)]="fieldValue"
    (onAdd)="onValueChange($event.originalEvent)"
    (onRemove)="onValueChange($event.originalEvent)"
    [inputType]="inputType"
    [addOnEnter]="addOnEnter"
    [addOnBlur]="addOnBlur"
    [addOnTab]="addOnTab"
    [addOnSpace]="addOnSpace"
    [editOnBackspace]="editOnBackspace"
    [allowDuplicate]="false"
    [matchPattern]="matchPattern"
    [columnName]="columnName"
    [inputStyle]="inputStyle"
    [inContainer]="inContainer"></iu-prime-chips>
</span>
