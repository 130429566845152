import { Component, inject, OnInit } from '@angular/core';
import { LocationModel } from '@compiere-ws/models/compiere-location-json';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { CardContent } from '@iupics-components/specific/window/customer-360/components/cards/cards-ui/card-content/card-type';
import { AppConfig } from '@iupics-config/app.config.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'iu-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
  standalone: true,
  imports: [ButtonModule, TranslateModule],
})
export default class AddressCardComponent extends CardContent implements OnInit {
  #locationService = inject(LocationService);
  #config = inject(AppConfig);

  get isNewLocationEnable(): boolean {
    return this.#config.isModuleEnable('newLocation');
  }

  displayData: any = {};
  locationData: LocationModel;

  ngOnInit(): void {
    this.displayData['name'] = this.content.Name;
    this.displayData['isShipTo'] = this.content.IsShipTo === 'Y';
    this.displayData['isBillTo'] = this.content.IsBillTo === 'Y';
    this.displayData['isRemitTo'] = this.content.IsRemitTo === 'Y';
    this.displayData['isPayFrom'] = this.content.IsPayFrom === 'Y';
    this.subscriptions.push(
      this.#locationService.getLocation(this.content.C_Location_ID).subscribe((data) => {
        this.locationData = data;
        this.displayData['address'] = this.#locationService.parseAddress(data, data.DisplaySequence, true);
      })
    );
  }

  copy() {
    navigator.clipboard.writeText(
      this.#locationService.parseAddress(this.locationData, this.locationData.DisplaySequence, false)
    );
  }

  openMap() {
    window.open(
      'https://www.google.com/maps?q=' +
        encodeURI(this.#locationService.parseAddress(this.locationData, this.locationData.DisplaySequence, false)),
      '_blank'
    );
  }
}
