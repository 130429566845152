<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }

  @if (vcrOptional.length > 0) {
    <a class="settings" (click)="isSidebarOpen = !isSidebarOpen">
      <i class="fas fa-cog" aria-hidden="true"></i>
    </a>
  }

  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="p-col-12">
      <label [hidden]="title && title.length === 0" class="specific-title">
        <strong>{{ title }}</strong>
      </label>
      <div class="fields">
        <ng-template iuDynamicContainer />
      </div>
    </div>
    <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
      @if (gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
        <iu-button-ui
          class="p-col-12 p-md-3 p-lg-2"
          (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
          label="{{ 'contextmenu.zoom' | translate }}"
          data-cy="form-button-zoom"
          icon="fa fa-search-plus" />
      }
      <iu-button-ui
        label="{{ 'productPrice.priceHistory' | translate }}"
        class="p-col-12 p-md-3 p-lg-2 historyPriceButton"
        [btnType]="'primary'"
        (click)="$event.preventDefault(); $event.stopPropagation(); showPriceHistoryForm()" />
      <ng-template #vcrButtons />
      @if (isModal) {
        @if (gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
          <iu-button-ui
            class="p-col-12 p-md-3 p-lg-2"
            (click)="
              $event.preventDefault();
              $event.stopPropagation();
              setSelectedRowOnAutocomplete();
              closeModalEmitter.emit()
            "
            label="{{ 'infodialog.ok' | translate }}"
            data-cy="form-button-validate" />
        }

        <iu-button-ui
          class="p-col-12 p-md-3 p-lg-1"
          btnType="alert"
          (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
          label="{{ 'process.cancel' | translate }}"
          icon="fa fa-ban" />
      }
    </div>
  </p-scrollPanel>
  <p-sidebar
    [(visible)]="isSidebarOpen"
    [baseZIndex]="0"
    position="right"
    styleClass="ui-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.optionalFilters' | translate }}
    </h1>
    <div class="optionalFields">
      <ng-template #optional />
    </div>
  </p-sidebar>
</div>

<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
<div hidden="true">
  <ng-template #vcrHidden />
</div>
