import { NgClass } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import PrimeChipsComponent from '../../../overrided/prime-chips/prime-chips.component';

@Component({
  selector: 'iu-input-chips-ui',
  templateUrl: './input-chips-ui.component.html',
  styleUrls: ['./input-chips-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, PrimeChipsComponent, FormsModule, TextLimitPipe],
})
export default class InputChipsUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input()
  columnName: string;
  @Output()
  fieldValueChange = new EventEmitter<any>();

  @Input() data: IupicsDataField;
  @Input() inputType = 'text';
  @Input() addOnBlur = false;
  @Input() addOnEnter = true;
  @Input() addOnTab = true;
  @Input() addOnSpace = false;
  @Input() editOnBackspace = false;
  @Input() matchPattern: RegExp = /.*/;
  @Input() inputStyle: { [key: string]: string };
  @Input() inContainer = false;

  ngOnInit() {
    super.ngOnInit();
    this.cssGrid = this.cssClass;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  onValueChange(event: any) {
    event.stopPropagation();
    this.fieldValueChange.emit(this.fieldValue);
  }
}
