import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
  inject,
  input,
} from '@angular/core';
import { CompiereDataFieldType, DataStore, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import PrimeAutocompleteComponent from '@iupics-components/overrided/prime-autocomplete/prime-autocomplete.component';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import ProcessUiComponent from '@iupics-components/specific/window/process-ui/process-ui.component';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import {
  injectFilterCtxService,
  provideFilterCtxService,
} from '@iupics-components/standard/grid/filters/services/filter-ctx.service';
import { GridTabInfinityScrollHeadlessUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { provideViewColumnsService } from '@iupics-components/standard/grid/services/view-columns.service';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import UniversalFilterUiComponent from '@iupics-components/standard/menu/universal-filter-ui/universal-filter-ui.component';
import ValuePreferencePanelComponent from '@iupics-components/standard/value-preference-panel/value-preference-panel.component';
import { AppConfig } from '@iupics-config/app.config.service';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsZoomInfo } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { Utils } from '@iupics-util/tools/util';
import {
  ToolbarComponent,
  provideColumnApiService,
  provideGridApiService,
  providePanelService,
} from '@iupics/apiz-grid';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceService } from '@web-desktop/components/workspace/components/workspace-ui/workspace.service';
import { UrlParamsService } from '@web-desktop/controllers/url-params.service';
import { cloneDeep, isNil } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'iu-autocomplete-ui',
  templateUrl: './autocomplete-ui.component.html',
  styleUrls: ['./autocomplete-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PrimeAutocompleteComponent,
    NgClass,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    forwardRef(() => ModalUiComponent),
    UniversalFilterUiComponent,
    GridTabInfinityScrollHeadlessUiComponent,
    ToolbarComponent,
  ],
  providers: [
    provideGridApiService(), // Provide the grid API service
    provideColumnApiService(), // Provide the column API service
    providePanelService(), // Provide the panel service
    provideFilterCtxService(), // Provide the filterCtx service at this level and not root level
    provideViewColumnsService(), // Provide the tabColumns service at this level and not root level
  ],
})
export default class AutocompleteUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit, OnDestroy {
  readonly #translateService = inject(TranslateService);
  readonly #config = inject(AppConfig);
  readonly #urlParamsService = inject(UrlParamsService);
  readonly #workspaceService = inject(WorkspaceService);

  component = 'AutocompleteUiComponent';
  itemData: DynamicComponent;
  suggestionsFilter: any;
  @ViewChild('autoComplete', { static: true })
  autoComplete: PrimeAutocompleteComponent;
  @Input() suggestions: any[];
  @Input() scrollHeight = '240px';
  @Input() columnName: string;
  @Input() iconClass: string;
  @Input() limitSuggestions = 19;
  @Input() isAdvancedSearch = false;
  @Input() isAccountSearch = false;
  @Input() readonly = false;
  @Input() disable = false;
  @Input() multiple = false;
  @Input() isEditorView = false;
  @Input() isInsideOverflow = false;
  @Input() placeholder = '';

  styleLess = input(false);

  subscription: Subscription;
  isSetMandatoryValue = false;

  zoomInfo: IupicsZoomInfo;
  private searchTimeout: NodeJS.Timeout;

  @Output() autoCompleteEmitter = new EventEmitter<any>();

  @Output() checkGridEditorEmitter = new EventEmitter<any>();
  @Output() blurEmitter = new EventEmitter<any>();
  top: number;
  left: number;
  width: number;
  itemSuggestionsCache = null;

  @Input()
  set fieldValue(value: any) {
    this.autoComplete.value = value;
    this.autoComplete.updateInputField();
    if (this.DOMParentComponent?.DOMParentComponent?.isAccordion) {
      this.DOMParentComponent.DOMParentComponent.setMandatory();
    }
  }

  get fieldValue() {
    return this.autoComplete.value;
  }

  dataContainers: AbstractDataContainerCallout;
  allDataRecovered = false;
  @Input() dataStoredEditor: DataStore;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;

  // searchPanel to append
  parentContainer;
  searchPanelValidation: string;
  displaySearch = false;
  // formPanel to append
  displayFormUI = false;
  customFormModalBodyCss = undefined;
  specificWindowTitle: string;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: false })
  vcrSpecific: ViewContainerRef;
  @ViewChild('specificModal', { read: ModalUiComponent, static: false })
  specificModal: ModalUiComponent;

  @Input() appendTo: any = 'body';

  filterCtx = injectFilterCtxService();
  isProcessingFilterResponse = false;
  @ViewChild('gridTab', { static: false }) gridTab: GridTabInfinityScrollHeadlessUiComponent;

  isAutocompleteField = true;

  ngOnInit() {
    if (this.isEditorView && this.dataStoredEditor) {
      this.dataStored = this.dataStoredEditor;
    }
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    this.updateLabel();
    this.updateFieldType();
    this.updateAdvancedSearch();
    this.updateAccountSearch();
    this.updateFieldTypeForm();
    this.updateStandalone();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    // ? à commenter si on ne veut pas charger les données d'une form pour affichage instantanné au clic.
    if (!Global.isMobile() && this.autoComplete && this.autoComplete.checkSpecificView && this.autoComplete.formId) {
      this.subscriptions.push(this.uiCreatorService.getSpecificWindow(this.autoComplete.formId).subscribe());
    }
    this.checkFocus();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription?.unsubscribe();
  }

  checkFocus() {
    if (
      this.data?.IsDefaultFocus &&
      !this.isReadOnly &&
      this.editViewParent?.editTabs?.[0]?.dataStored?.status === DataStoreStatus.NEWRECORD &&
      (this.fieldValue === null || this.fieldValue === undefined)
    ) {
      this.autoComplete.inputEL.nativeElement.focus();
    }
  }

  setFieldMandatory() {
    if (this.data?.mandatoryLogic) {
      this.data.isMandatory = LogicEvaluator.evaluateLogic(
        this.getCurrentContext(this.dataStored, false),
        this.data.mandatoryLogic
      );
    }

    if (this.data?.isMandatory) {
      this.handleMandatoryField();
    } else {
      this.handleNonMandatoryField();
    }

    if (this.autoComplete) {
      this.autoComplete.setMandatoryCss(this.mandatoryCss);
    }
  }

  dataChange(value) {
    if (this.multiple && !Array.isArray(value)) {
      this.fieldValue = this.fieldValue ?? [];
      value = [...(cloneDeep(this.fieldValue) as any[]), value];
    }
    super.dataChange(value);
    this.autoCompleteEmitter.emit(this.fieldValue);
  }

  /**
   * Update le store avec ce qu'on reçoit du search panel
   * @param id id qu'on reçoit depuis l'emitter de ui-prime-autocomplete
   */
  setSearchSelectItem(event) {
    this.isProcessingFilterResponse = true;
    let id = event.data['Data_UUID'];
    if (id) {
      id = id.split(',')[1];
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const validation = LogicEvaluator.replaceVariables(
      this.data.validationCode,
      this.connectorService.getIupicsUserContext(),
      this.getCurrentContext(),
      false,
      false,
      !this.isInUniversalFilter
    );
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    this.subscription = this.store
      .getAutocompleteDataById(this.fieldType, entityId, id, validation)
      .subscribe((dataWS) => {
        this.dataChange(dataWS[0]);
        this.isProcessingFilterResponse = false;
        this.hideSearchPanel();
      });
  }
  /**
   * @description get text value linked to an id
   * @param id id received from url
   */
  getSearchItem(idValue: any): Observable<any> {
    const id = parseInt(idValue, 10) ? parseInt(idValue, 10) : idValue;
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    return this.store
      .getAutocompleteDataById(
        this.fieldType,
        entityId,
        id,
        LogicEvaluator.replaceVariables(
          this.data.validationCode,
          this.connectorService.getIupicsUserContext(),
          this.getCurrentContext(),
          false,
          false,
          !this.isInUniversalFilter
        )
      )
      .pipe(map((dataWS) => dataWS.find((data) => data.id === id)));
  }

  /**
   * Lancé au focus du champ ui-prime-autocomplete
   * @param event
   */
  search(event: { originalEvent: Event; query: string }, forceRefresh = false) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
    }
    this.searchTimeout = setTimeout(
      () => {
        this.searchTimeout = undefined;
        this.handleSearch(event, forceRefresh);
      },
      this.isStandalone || event.originalEvent instanceof FocusEvent ? 0 : 375
    );
  }

  selectByIdentifier(identifier: string, value: any) {
    if (identifier && value && this.data.items) {
      for (const element of this.data.items) {
        const propose = element;
        if (propose[identifier] === value) {
          this.fieldValue = propose;
        }
      }
    }
  }

  zoomAcross() {
    this.isZoom = true;
    const record_id = this.fieldValue?.id ?? -1;
    const isSOTrx = this.dataStored.data.IsSOTrx ? this.dataStored.data.IsSOTrx === 'Y' : undefined;
    this.subscriptions.push(
      this.uiCreatorService
        .zoomAcross(this.data.details.tableName, this.data.details.keyColumn, record_id, isSOTrx)
        .subscribe({
          next: (dataWs) => this.doZoomAcross(dataWs, record_id),
          error: (err) => {
            this.isZoom = false;
          },
        })
    );
  }

  refreshZoomInfo() {
    let record_id = -1;
    if (this.fieldValue) {
      record_id = this.fieldValue.id;
    }
    this.zoomInfo.record_id = record_id;
    this.zoomInfo.dataUUID = this.data.details.keyColumn.split('.')[1] + ',' + record_id;
    if (!this.zoomInfo.children && this.zoomInfo.record_id !== -1) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectZoomChange,
        item: {
          container: null,
          dataStoreKey: null,
          zoomInfo: this.zoomInfo,
        },
      });
    }
  }

  setNewData(dataStored: DataStore, isInit = false) {
    if (!dataStored.isCopied) {
      this.isSetMandatoryValue = false;
    }
    super.setNewData(dataStored, isInit);
    if (this.autoComplete) {
      this.autoComplete.setMandatoryCss(this.mandatoryCss);
      this.checkAccordionAutocomplete();
    }
  }

  checkAccordionAutocomplete() {
    if (this.DOMParentComponent?.DOMParentComponent?.isAccordion) {
      this.DOMParentComponent.DOMParentComponent.setMandatory();
    }
  }

  onSiblingUpdate(event: any) {
    if (event?.refreshZoom) {
      this.resetAutocompleteFromZoom(event.id);
    } else if (event && event.type === IupicsTypeEvent.expandEvent) {
      if (this.editViewParent?.editTabs?.length > 1) {
        for (const tab of this.editViewParent.editTabs) {
          tab.expandTab();
        }
        // this.editViewParent.smartButtons.resizeSmartButton(event);
      }
    } else if (event && event.type === IupicsTypeEvent.collapseEvent) {
      if (this.editViewParent?.editTabs?.length > 1) {
        for (const tab of this.editViewParent.editTabs) {
          tab.collapseTab();
        }
        // this.editViewParent.smartButtons.resizeSmartButton(event);
      }
    }
  }

  showConflictPanel(ev: any) {
    ev.stopPropagation();
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  setParentContainer() {
    if (this.container instanceof SpecificWindowUiComponent || this.container instanceof ProcessUiComponent) {
      this.parentContainer = this.container;
    } else if (this.container) {
      const editView = <EditViewUiComponent>(
        this.container.DOMChildrenComponent.find(
          (DOMChild: any) => DOMChild instanceof EditViewUiComponent && DOMChild.tabId === this.data.tabId
        )
      );
      this.parentContainer = editView;
    }
  }

  showSearchPanel() {
    this.setParentContainer();
    let url = null;
    if (this.data.urlSearch) {
      url = this.data.urlSearch;
    } else if (this.data?.details?.entityID) {
      url = '/Field/Search?id=' + this.data.details.entityID + '&type=0';
    }
    if (url) {
      this.subscriptions.push(
        this.uiCreatorService.getFieldsData(url).subscribe((searchColumns) => {
          this.data.searchColumns = searchColumns;
          this.lockFilterPanel();
          this.displaySearch = true;
        })
      );
    }
  }

  hideSearchPanel() {
    if (this.isProcessingFilterResponse) return;
    this.filterCtx.unregisterGrid(this.gridTab);
    this.unlockFilterPanel();
    this.displaySearch = false;
  }

  showFormPanel(formID) {
    if (this.data.isAccount) {
      this.container['accountEditor_ColumnSearch'] = this.data.columnName;
    }
    this.updateModalDisplay(
      { key: 'displayFormUI', value: true, sourceComponent: this },
      { key: 'formId', value: formID }
    );
  }

  onInputClick(event: MouseEvent) {
    event.stopPropagation();
    const rect = (this.elementRef.nativeElement.firstChild as HTMLElement).getBoundingClientRect();
    this.top = rect.y + 26;
    this.left = rect.x;
    this.width = rect.width;
  }

  checkGridEditor() {
    if (this.suggestions != null) {
      const event = {
        overlayVisible: true,
        label: this.label,
        isLabelDisplay: this.isFieldDisplay,
        scrollHeight: this.scrollHeight,
        suggestions: this.suggestions,
        field: this.autoComplete.field,
        itemTemplate: this.autoComplete.itemTemplate,
        noResults: this.autoComplete.noResults,
        emptyMessage: this.autoComplete.emptyMessage,
        top: this.top,
        left: this.left,
        width: this.width,
        linkAutocomplete: this,
      };
      this.checkGridEditorEmitter.emit(event);
    }
  }

  createSpecificWindow(formId: number, sourceComponent: any) {
    this.setParentContainer();
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formId).subscribe((specificWindow) => {
        let component;
        if (
          specificWindow.angularClass &&
          specificWindow.angularClass.length > 0 &&
          specificWindow.angularClass !== 'default'
        ) {
          component = CacheManagerService.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = CacheManagerService.iupics_specific_window.get('default');
        }
        this.vcrSpecific.clear();
        const componentRef = this.vcrSpecific.createComponent<SpecificWindowUiComponent>(component);
        this.specificWindowTitle = specificWindow.name;
        componentRef.instance.name = specificWindow.name;
        componentRef.instance.title = specificWindow.title;
        componentRef.instance.description = specificWindow.description;
        componentRef.instance.help = specificWindow.help;
        componentRef.instance.componentRef = componentRef;
        componentRef.instance.isModal = true;
        componentRef.instance.formId = formId;
        componentRef.instance.vcrwindow = this.vcrSpecific;
        componentRef.instance.parentComponent = this.parentContainer;
        componentRef.instance.sourceModal = this.specificModal;
        componentRef.instance.index = this.vcrSpecific.length - 1;
        componentRef.instance.sourceComponentData = sourceComponent?.itemData ?? sourceComponent;
        componentRef.instance.sourceComponent =
          sourceComponent; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
        componentRef.instance.closeModalEmitter.subscribe(() =>
          this.updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })
        );
        this.componentRefs.push(componentRef);
      })
    );
  }
  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   * sourceComponent : déterminer si autocomplete ou button
   */
  updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        setTimeout(() => {
          // timeout le temps d'afficher le vcr
          this.createSpecificWindow(id.value, display.sourceComponent);
        }, 500);
      }
    } else {
      this.customFormModalBodyCss = undefined;
    }
  }

  private updateLabel() {
    if (!this.data || !this.label) {
      return;
    }

    if (this.label.indexOf('_From') >= 0) {
      this.label = this.label.replace('_From', this.#translateService.instant('ranged-value.calendar.from'));
    } else if (this.label && this.label.indexOf('_To') >= 0) {
      this.label = this.label.replace('_To', this.#translateService.instant('ranged-value.calendar.to'));
    }
  }

  private updateFieldType() {
    if (this.data) {
      this.fieldType =
        this.isForInfoWindow && this.isAdvancedSearch ? CompiereDataFieldType.COLUMN_INFO : this.fieldType;
    }
  }

  private updateAdvancedSearch() {
    if (this.data) {
      this.isAdvancedSearch = !!this.data.urlSearch;
    }
  }

  private updateAccountSearch() {
    if (this.data) {
      this.isAccountSearch = !!this.data.isAccount;
    }
  }

  private updateFieldTypeForm() {
    if (this.data?.fieldTypeForm) {
      this.fieldType = CompiereDataFieldType.FORM_ITEM;
    }
  }

  private updateStandalone() {
    if (this.isStandalone && this.data && this.data.isMandatory && this.data.items && this.data.items.length > 0) {
      this.fieldValue = this.data.items[0];
    }
  }

  private handleMandatoryField() {
    if (this.fieldValue) {
      this.isSetMandatoryValue = true;
    } else if (this.shouldFetchAutocompleteData()) {
      this.fetchAutocompleteData();
      this.isSetMandatoryValue = true;
    }
    this.mandatoryCss = ' iu-field-mandatory ';
  }

  private handleNonMandatoryField() {
    this.mandatoryCss = ' iu-field ';
  }

  private shouldFetchAutocompleteData() {
    return (
      (!this.data.defaultValue || this.isSetDefaultValue) &&
      !this.isSetMandatoryValue &&
      this.data &&
      this.data.urlList &&
      (!this.data.items || this.data.items.length <= 0 || this.data.validationCode)
    );
  }

  private fetchAutocompleteData() {
    if (this.fieldType !== CompiereDataFieldType.FIELD) {
      let validationCode;
      if (this.data.validationCode) {
        validationCode = LogicEvaluator.replaceVariables(
          this.data.validationCode,
          this.connectorService.getIupicsUserContext(),
          this.getCurrentContext(),
          false,
          false,
          !this.isInUniversalFilter
        );
      }
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      const entityId = /*this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId :*/ this.data.columnId;
      this.subscription = this.store
        .getAutocompleteData(
          this.fieldType,
          entityId,
          this.data.isSearch,
          null,
          validationCode,
          this.isInUniversalFilter
        )
        .subscribe((dataWs) => {
          this.suggestions = [];
          this.data.items = dataWs;
          if (
            this.dataStored.status === DataStoreStatus.NEWRECORD &&
            this.data.items.length === 1 &&
            (this.data.columnName !== 'AD_Client_ID' ||
              this.data.defaultValue === undefined ||
              (this.data.fromRange && this.data.defaultValue2 === undefined))
          ) {
            this.updateStore(this.data.items[0], [], true);
          }
          if (this.autoComplete) {
            this.autoComplete.setMandatoryCss(this.mandatoryCss);
          }
        });
    }
  }

  private handleSearch(event: { originalEvent: Event; query: string }, forceRefresh: boolean) {
    const regex = '.*'.concat(
      Utils.cleanUpSpecialChars(event.query || '', true)
        .split(' ')
        .join('.*'),
      '.*'
    );
    if (!this.data['events']) {
      if (!this.isContextMenu) {
        if (
          this.data?.urlList &&
          (forceRefresh ||
            this.data.validationCode ||
            event.query?.length > 0 ||
            ((event.query === null || event.query === undefined || event.query.length === 0) &&
              this.itemSuggestionsCache === null) ||
            !this.data.items ||
            this.data.items.length <= 0)
        ) {
          this.handleSearchWithUrlList(event);
        } else {
          this.handleSearchWithoutUrlList(event, regex);
        }
      } else {
        this.autoComplete.loading = false;
        this.isContextMenu = false;
      }
    }
  }

  private handleSearchWithUrlList(event: { originalEvent: Event; query: string }) {
    let validationCode;
    if (this.data.validationCode) {
      validationCode = LogicEvaluator.replaceVariables(
        this.data.validationCode,
        this.connectorService.getIupicsUserContext(),
        this.getCurrentContext(),
        false,
        false,
        !this.isInUniversalFilter
      );
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    this.subscription = this.store
      .getAutocompleteData(
        this.fieldType,
        entityId,
        this.data.isSearch,
        event.query,
        validationCode,
        this.isInUniversalFilter
      )
      .subscribe((dataWs) => {
        this.handleSearchResults(event, dataWs);
      });
  }

  private handleSearchWithoutUrlList(event: { originalEvent: Event; query: string }, regex: string) {
    if (this.itemSuggestionsCache !== null && this.itemSuggestionsCache.length > 0) {
      this.data.items = this.itemSuggestionsCache;
    }

    this.suggestions = [];
    if ((!event.query || event.query === '') && !this.data.isMandatory) {
      if (!this.isStandalone) {
        this.suggestions.push({ id: -2, displayValue: '' });
      }
      if (this.data.items) {
        this.suggestions.push(...this.data.items);
      }
    } else {
      this.addMatchingSuggestions(regex);
    }
    if ((this.isAdvancedSearch || this.isAccountSearch) && this.suggestions.length > this.limitSuggestions) {
      this.suggestions = this.suggestions.splice(0, this.limitSuggestions);
      this.suggestions.push({
        id: -1,
        displayValue: this.#translateService.instant('generic.moreResults'),
      });
    }

    if (!this.DOMParentComponent) {
      this.checkGridEditor();
    }
  }

  private addMatchingSuggestions(regex: string) {
    for (const element of this.data.items) {
      const propose = element;
      if (this.data.isSearch || RegExp(regex).exec(Utils.cleanUpSpecialChars(propose.displayValue))) {
        this.suggestions.push(propose);
      }
    }
  }

  private handleSearchResults(event: { originalEvent: Event; query: string }, dataWs: any[]) {
    this.suggestions = [];
    this.data.items = dataWs;

    if ((!event.query || event.query === '') && !this.data.isMandatory) {
      if (!this.isStandalone) {
        this.suggestions.push({ id: -2, displayValue: '' });
      }
      if (this.data.items) {
        for (const propose of this.data.items) this.suggestions.push(propose);
      }
    } else if (this.data.items) {
      for (const propose of this.data.items) {
        this.suggestions.push(propose);
      }
      this.itemSuggestionsCache = [...this.data.items];
    }
    this.addMoreResultsSuggestionIfNeeded();

    if (!this.DOMParentComponent) {
      this.checkGridEditor();
    }

    this.handleSingleResult(dataWs, event.query);
    this.cacheItemSuggestionsIfNeeded(dataWs, event.query);
  }

  private addMoreResultsSuggestionIfNeeded() {
    if ((this.isAdvancedSearch || this.isAccountSearch) && this.suggestions.length > this.limitSuggestions) {
      this.suggestions = this.suggestions.splice(0, this.limitSuggestions);
      this.suggestions.push({
        id: -1,
        displayValue: this.#translateService.instant('generic.moreResults'),
      });
    }
  }

  private handleSingleResult(dataWs: any[], query: string) {
    if (dataWs.length === 1 && this.data.isMandatory && !this.autoComplete.overlayVisible && !this.fieldValue) {
      this.fieldValue = this.data.items[0];
      super.dataChange(this.fieldValue);
    } else if (dataWs.length > 0) {
      const suggestion = dataWs.find((i: any) => i.displayValue === query);
      if (!isNil(suggestion) && !this.fieldValue) {
        this.fieldValue = suggestion;
        super.dataChange(this.fieldValue);
      }
    }
  }

  private cacheItemSuggestionsIfNeeded(dataWs: any[], query: string) {
    if (query !== null && query !== undefined && query.length === 0) {
      if (this.data.items) {
        this.itemSuggestionsCache = [...this.data.items];
      }
    }
  }

  private doZoomAcross(dataWs, record_id: number) {
    if (dataWs && dataWs.length > 0) {
      this.zoomInfo = {
        // tableName: this.data.detailZoom.tableName,
        windowId: dataWs[0].Window_ID,
        dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
        record_id: record_id,
        children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
        isInCurrentWindow: true,
      };

      // check whether a tab with zoom info tabid already exist
      const tabExist = this.isTabExist(parseInt(dataWs[0].Tab_ID, 10));
      if (tabExist || this.data.isParam || this.container instanceof SpecificWindowUiComponent) {
        this.openExistingTab(dataWs, record_id);
      } else if (!this.#config.isModuleEnable('apiz-monowindow')) {
        this.openNewTab(dataWs);
      }
    }
  }

  private isTabExist(zoomTabId: number) {
    let tabExist = false;
    if (this.container?.DOMChildrenComponent) {
      let j = 1;
      while (!tabExist && this.container.DOMChildrenComponent.length > j) {
        if (
          this.container.DOMChildrenComponent[j].DOMComponent.instance instanceof EditViewUiComponent &&
          this.container.DOMChildrenComponent[j].tabId === zoomTabId
        ) {
          tabExist = true;
        }
        j++;
      }
    }

    return tabExist;
  }

  private openExistingTab(dataWS: any[], record_id: number) {
    this.isZoom = false;
    this.zoomInfo.isInCurrentWindow = false;
    this.#urlParamsService.getUrlParams().dataGridRequest = null;
    this.#workspaceService.openTargetSearchEmt.emit({
      zoomInfo: this.zoomInfo,
      cat: { id: parseInt(dataWS[0].Window_ID, 10) },
      source: {
        id: record_id !== -1 ? dataWS[0].Record_ID : 'newRecord',
      },
    });
  }

  private openNewTab(dataWS: any[]) {
    const windowId = parseInt(dataWS[dataWS.length - 1]['Window_ID'], 10);
    this.subscriptions.push(
      this.uiCreatorService.getWindow(windowId).subscribe((tabUI) => {
        const item: DynamicComponent = {
          container: this.container,
          DOMParentComponent: this.container,
          linkedComponents: [this],
          component: 'EditViewUiComponent',
          cssClass: 'iupics-blade-content',
          isCssOnComponent: false,
          tabId: tabUI.tabId,
          windowId: windowId,
          zoomInfo: this.zoomInfo,
        };
        this.componentEmitter.emit({
          type: IupicsTypeEvent.showEditView,
          item: item,
        });
      })
    );
  }
}
