<div class="fields">
  <div class="field" data-position="left">
    <span class="field-label">{{ 'transferNO' | SKWTranslate }}:</span>
    <span class="field-value">{{ data().transferNO }}</span>
  </div>

  @if (data()?.IsManual !== 'Y') {
    <div class="field">
      <span class="field-label">{{ 'DocumentNo' | SKWTranslate }}:</span>
      <span class="field-value">{{ data().DocumentNo }}</span>
    </div>
  }
</div>

<div class="buttons" data-position="right">
  @if (tabActive() === 'transfers') {
    <iu-storekeeper-button
      class="button button-wide"
      [label]="(!isAssigning() ? 'AssignMe' : 'AssignmentInProgress') | SKWTranslate"
      [disabled]="isAssigning()"
      (onClick)="assign($event)" />
  } @else if (listStatusTabActive() === 'CO') {
    <iu-storekeeper-button
      class="button button-wide"
      [label]="'ViewTransfer' | SKWTranslate"
      (onClick)="goToDetail($event, 'UL')" />
  } @else {
    <iu-storekeeper-button
      class="button button-left"
      [label]="'Load' | SKWTranslate"
      (onClick)="goToDetail($event, 'TL')" />
    <iu-storekeeper-button
      class="button button-right"
      [label]="'Unload' | SKWTranslate"
      [type]="'outlined'"
      (onClick)="goToDetail($event, 'UL')" />
  }
</div>

@if (tabActive() === 'tasks' && data().DocStatus !== 'CO') {
  <button class="button-close" (click)="remove($event)">
    <i class="icon-close" aria-hidden="true"></i>
  </button>
}
