<div class="chartView">
  <div class="chartViewRender">
    <div class="filters">
      <div class="switchView">
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.pieChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-pie"
          (click)="switchToPieChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.doughnutChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-dot-circle"
          (click)="switchToDoughnutChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.polarChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-bullseye"
          (click)="switchToPolarChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.lineChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-line"
          (click)="switchToLineChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.areaChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-area"
          (click)="switchToAreaChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.barChart }}"
          tooltipPosition="top"
          type="button"
          icon="far fa-chart-bar fa-rotate-90"
          (click)="switchToBarChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.columnChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-bar"
          (click)="switchToColumnChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.radarChart }}"
          tooltipPosition="top"
          type="button"
          icon="far fa-snowflake"
          (click)="switchToRadarChart()"
          class="p-menu-bar-button"></button>
      </div>
      <div class="operation">
        <iu-autocomplete-ui
          #operationField
          [label]="'chartview.aggregate' | translate"
          [(fieldValue)]="operation"
          (fieldValueModified)="changeOperationToCompute($event)"
          [isStandalone]="true"
          [data]="{
            items: operations,
          }"></iu-autocomplete-ui>
      </div>
      <div class="column">
        <iu-autocomplete-ui
          [(fieldValue)]="defaultColumn"
          [label]="'chartview.dataAggregate' | translate"
          (fieldValueModified)="changeColumnToCompute($event)"
          [isStandalone]="true"
          [data]="{
            items: columns,
          }"></iu-autocomplete-ui>
      </div>
    </div>

    <p-chart
      #chart
      [type]="chartType === 'area' ? 'line' : chartType === 'horizontalBar' ? 'bar' : chartType"
      [data]="dataChart"
      [options]="options"
      [height]="'70vh'"></p-chart>
  </div>
</div>
