<span
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($any($event))"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  [style.backgroundColor]="backgroundColorCss">
  @if (isLabelDisplay && !(displayImage && fieldValue !== undefined && fieldValue !== null)) {
    <label [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }" [title]="label">
      {{ label | textLimit: elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}
    </label>
  }
  @if (!(displayImage && fieldValue !== undefined && fieldValue !== null)) {
    <input
      #input
      [(ngModel)]="fieldValue"
      [readonly]="isReadOnly ? 'disabled' : null"
      [tabindex]="isReadOnly ? '-1' : null"
      [ngClass]="[mandatoryCss || '', 'ui-corner-all', 'input-field p-inputtext']"
      value=""
      type="text"
      (change)="dataChange(input.value)"
      autocomplete="no"
      data-cy="input-text"
      [attr.data-cy-columnName]="data ? data.columnName : columnName" />
  }
  @if (!(displayImage && fieldValue !== undefined && fieldValue !== null && fieldValue !== '')) {
    <i class="picto icon-img" aria-hidden="true" #ddBtn type="button" (click)="switchField()"> </i>
  }

  @if (displayImage && fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
    <img
      [ngClass]="[!isReadOnly ? 'iu-image-hover' : '', 'iu-image']"
      [attr.width]="width"
      [attr.height]="height"
      [src]="fieldValue | docServerUrl | async"
      (click)="switchField()"
      data-cy="input-image"
      [attr.data-cy-columnName]="data ? data.columnName : columnName"
      alt="image" />
  }
  @if (showValuePrefPanel) {
    <iu-prime-overlay #opValuePref [dismissable]="false" appendTo="body">
      <iu-value-preference-panel
        [overlayPanel]="opValuePref"
        [fieldValue]="fieldValue"
        [dataStored]="dataStored"
        [sourceComponent]="this"
        [data]="data"
        (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
    </iu-prime-overlay>
  }
</span>
