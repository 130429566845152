<span
  [style.display]="displayCss"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  tooltipZIndex="2100"
  #input
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($any($event))"
  [style.backgroundColor]="backgroundColorCss">
  <!-- [(ngModel)]="fieldValue" -->
  <!--     si on ajoute ceci, ça call un fieldvaluechange et donc rend la data not sync => showTime="true" hourFormat="24" -->
  <iu-prime-calendar
    #calendar
    [showButtonBar]="true"
    [isStandalone]="this.isStandalone"
    [monthNavigator]="true"
    [yearNavigator]="true"
    yearRange="{{ this.rangeDate }}"
    [iupicsLocale]="locale"
    [label]="label"
    [isLabelDisplay]="this.isLabelDisplay"
    [showTime]="this.data?.needTime"
    [showIcon]="true"
    (fieldChange)="fieldChange($event)"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [readonlyInput]="isReadOnly"
    [disabled]="isReadOnly"
    (checkGridEditorEmitter)="checkGridEditor()"
    [isGridEditor]="isGridEditor"
    [columnName]="data ? data.columnName : columnName"
    [hasTodayBtn]="hasTodayBtn"
    [calendarConfig]="calendarConfig"
    (calendarConfigChange)="onCalendarConfigChange($event)" />
  @if (hasConflict && dataStored) {
    <i
      #spanInput
      class="rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
      (click)="showConflictPanel($event)"
      aria-hidden="true">
    </i>
  }
  @if (showValuePrefPanel) {
    <iu-prime-overlay #opValuePref [dismissable]="false" appendTo="body">
      <iu-value-preference-panel
        [overlayPanel]="opValuePref"
        [fieldValue]="fieldValue"
        [dataStored]="this.dataStored"
        [sourceComponent]="this"
        [data]="this.data"
        (closeEmitter)="toggleValuePreferencePanel($event)" />
    </iu-prime-overlay>
  }
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>
