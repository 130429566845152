<div class="kanban-card-container p-grid">
  @if (datasKanban.image !== null) {
    <div class="img-container p-col-4 p-md-4 p-lg-4">
      @if (datasKanban.image | docServerUrl | async; as src) {
        <img class="iu-image" [src]="src" (error)="datasKanban.image = ''" alt="kanban image" />
      } @else {
        <img
          class="iu-image"
          [src]="tableName === 'C_BPartner' ? 'assets/noImage-c_bpartner.png' : 'assets/imageError.webp'"
          (error)="datasKanban.image = ''"
          alt="kanban partner image" />
      }
    </div>
  }
  <div [ngClass]="[datasKanban.image === null ? 'p-col-12' : 'p-col-8 p-md-8 p-lg-8', 'other-container']">
    @for (dataKanban of datasKanban.rows; track dataKanban) {
      @if (dataKanban.dataAlignLeft.length > 0 || dataKanban.dataAlignright.length > 0) {
        <div class="board-card-line p-grid">
          @if (dataKanban.dataAlignLeft.length > 0) {
            <h4 [ngClass]="[dataKanban.dataAlignright.length > 0 ? 'p-col-6' : '', 'board-card-line-left']">
              @for (dataAlignLeft of dataKanban.dataAlignLeft; track dataAlignLeft; let first = $first) {
                @if (dataAlignLeft.type === 'text') {
                  <div #element [ngClass]="[first ? 'first' : '', 'board-card-line-data']">
                    @if (isMobile) {
                      <span
                        tooltipPosition="top"
                        showDelay="250"
                        tooltipZIndex="8000"
                        [life]="3000"
                        [hideDelay]="250"
                        pTooltip="{{ dataAlignLeft.value }}"
                        >{{ dataAlignLeft.value | textLimit: 80 }}</span
                      >
                    }
                    @if (!isMobile) {
                      <span>{{ dataAlignLeft.value }}</span>
                    }
                  </div>
                }
                @if (dataAlignLeft.value | docServerUrl | async; as srcLeft) {
                  @if (dataAlignLeft.type === 'image') {
                    <img class="iu-image" [src]="srcLeft" (error)="dataAlignLeft.value = ''" alt="kanban image" />
                  }
                } @else {
                  <img
                    class="iu-image"
                    [src]="'assets/imageError.webp'"
                    (error)="dataAlignLeft.value = ''"
                    alt="kanban image" />
                }
              }
            </h4>
          }
          @if (dataKanban.dataAlignright.length > 0) {
            <div [ngClass]="[dataKanban.dataAlignLeft.length > 0 ? 'p-col-6' : '', 'board-card-line-right']">
              @for (dataAlignright of dataKanban.dataAlignright; track dataAlignright; let first = $first) {
                @if (dataAlignright.type === 'text') {
                  <div #element [ngClass]="[first ? 'first' : '', 'board-card-line-data']">
                    @if (isMobile) {
                      <span
                        tooltipZIndex="8000"
                        tooltipPosition="top"
                        showDelay="250"
                        [life]="3000"
                        [hideDelay]="250"
                        pTooltip="{{ dataAlignright.value }}"
                        >{{ dataAlignright.value | textLimit: 80 }}</span
                      >
                    }
                    @if (!isMobile) {
                      <span>{{ dataAlignright.value }}</span>
                    }
                  </div>
                }
                @if (dataAlignright.value | docServerUrl | async; as srcRight) {
                  @if (dataAlignright.type === 'image') {
                    <img class="iu-image" [src]="srcRight" (error)="dataAlignright.value = ''" alt="kanban image" />
                  }
                } @else {
                  @if (dataAlignright.type === 'image') {
                    <img
                      class="iu-image"
                      [src]="'assets/imageError.webp'"
                      (error)="dataAlignright.value = ''"
                      alt="kanban image" />
                  }
                }
              }
            </div>
          }
        </div>
      }
    }
  </div>
</div>
