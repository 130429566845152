<div class="buttonActions">
  @if (chart) {
    <button
      tooltipPosition="top"
      showDelay="250"
      [life]="3000"
      pTooltip="{{ 'chartWidgetUi.showLegend' | translate }}"
      pButton
      type="button"
      icon="{{ (lastLegendDisplayState ? 'far fa-eye' : 'far fa-eye-slash') || '' }}"
      (click)="changeLegendView()"
      class="p-menu-bar-button"></button>
  }
  @if (this.widget.windowId) {
    <button
      tooltipPosition="top"
      showDelay="250"
      [life]="3000"
      pTooltip="{{ 'widgetUi.openWindow' | translate }}"
      pButton
      type="button"
      icon="icon-search-bolder"
      (click)="openTab()"
      class="p-menu-bar-button"></button>
  }
</div>
@if (dataToDisplay) {
  <p-chart
    #chart
    [type]="chartType"
    [data]="dataToDisplay"
    [options]="options"
    [plugins]="plugins"
    [height]="'100%'"></p-chart>
}
@if (!dataToDisplay) {
  <div class="no-data">
    <p class="no-data-label">{{ 'widget.noData' | translate }}</p>
  </div>
}
