import { Injectable, inject, signal } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import {
  SKWLineStatus,
  SKWListTabType,
  SKWPage,
  SKWTaskStatus,
  SKWTransferHeaderData,
  SKWTransferLineData,
  SKWTransferLineFormData,
} from '../models/storekeeper-window.model';

@Injectable()
export class SKWNavigationService {
  userInfos = inject(SecurityManagerService).getIupicsUserAccount();

  #page = signal(SKWPage.LIST);
  #previousPages: SKWPage[] = [];
  page = this.#page.asReadonly();

  #listTabActive = signal<SKWListTabType>(SKWListTabType.TASKS);
  listTabActive = this.#listTabActive.asReadonly();

  #listStatusTabActive = signal<SKWTaskStatus>(SKWTaskStatus.AS);
  listStatusTabActive = this.#listStatusTabActive.asReadonly();

  transferActive = signal<SKWTransferHeaderData>(undefined);

  #detailTabActive = signal<SKWLineStatus>(SKWLineStatus.TL);
  detailTabActive = this.#detailTabActive.asReadonly();

  detailSelectedLine = signal<SKWTransferLineData>(undefined);
  searchData = signal<Partial<SKWTransferLineFormData>>({});

  isFilterPanelOpen = signal(false);

  //#region Page
  changePage(page: SKWPage) {
    this.#previousPages.push(this.#page());
    this.#page.set(page);
  }

  previousPage(fallback: SKWPage = SKWPage.LIST) {
    if (this.detailSelectedLine()) {
      this.detailSelectedLine.set(undefined);

      if (this.transferActive().Record_ID !== 0) {
        return;
      }
    }

    let next = this.#previousPages.pop();
    if (!next || next === this.#page()) {
      next = fallback;
    }

    this.#page.set(next);
  }
  //#endregion

  //#region Tab
  changeListTabActive(tab: SKWListTabType) {
    this.#listTabActive.set(tab);
  }

  changeListStatusTabActive(status: SKWTaskStatus) {
    this.#listStatusTabActive.set(status);
  }

  changeDetailTabActive(tab: SKWLineStatus, changePage = true) {
    this.#detailTabActive.set(tab);
    if (changePage) {
      this.changePage(SKWPage.TRANSFER);
    }
  }

  goToDetail(transfer: SKWTransferHeaderData, tabType: SKWLineStatus) {
    this.#detailTabActive.set(tabType);
    this.transferActive.set(transfer);
    this.changePage(SKWPage.TRANSFER);
    // this.#SKWContextService.getTransferDetails(transfer);
  }
  //#endregion

  //#region Filter Panel
  toggleFilterPanel() {
    this.isFilterPanelOpen.update((v) => !v);
  }
  //#endregion
}
