import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import { DataStore, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import PrimeCalendarComponent from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-calendar-ui',
  templateUrl: './calendar-ui.component.html',
  styleUrls: ['./calendar-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgClass,
    PrimeOverlayComponent,
    PrimeCalendarComponent,
    forwardRef(() => ValuePreferencePanelComponent),
  ],
})
export default class CalendarUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  #translateService = inject(TranslateService);

  @ViewChild('calendar', { static: true })
  calendar: PrimeCalendarComponent;

  @ViewChild('input', { static: true })
  inputRef: ElementRef;

  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;

  dataContainers: AbstractDataContainerCallout;

  @Input()
  set fieldValue(value: any) {
    this.calendar.value = value;
    this.calendar.setFieldValue();
  }
  get fieldValue() {
    return this.calendar.value;
  }

  @Input()
  columnName: string;

  @Input()
  isGridEditor: boolean;

  @Input() hasTodayBtn = false;

  @Output()
  checkGridEditorEmitter = new EventEmitter<any>();

  calendarValue: Date;
  rangeDate: string;

  top;
  left;
  width;

  constructor() {
    super();
    this.isDateField = true;
  }

  ngOnInit() {
    this.rangeDate = new Date().getFullYear() - 100 + ':' + (new Date().getFullYear() + 100);
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    this.setFieldMandatory();
    this.calendar.setMandatoryCss(this.mandatoryCss);
    if (this.label && this.label.indexOf('_From') >= 0) {
      this.label = this.label.replace('_From', this.#translateService.instant('ranged-value.calendar.from'));
    } else if (this.label && this.label.indexOf('_To') >= 0) {
      this.label = this.label.replace('_To', this.#translateService.instant('ranged-value.calendar.to'));
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.fieldValue) {
      this.calendar.setFieldValue();
    }
    this.checkFocus();
  }

  checkFocus() {
    if (
      this.data?.IsDefaultFocus &&
      !this.isReadOnly &&
      this.editViewParent?.editTabs?.[0]?.dataStored?.status === DataStoreStatus.NEWRECORD &&
      (this.fieldValue === null || this.fieldValue === undefined)
    ) {
      this.calendar.inputRef.nativeElement.focus();
    }
  }

  setFieldMandatory() {
    if (this.data?.mandatoryLogic) {
      this.data.isMandatory = LogicEvaluator.evaluateLogic(
        this.getCurrentContext(this.dataStored, false),
        this.data.mandatoryLogic
      );
    }

    if (this.data?.isMandatory) {
      this.mandatoryCss = ' iu-field-mandatory ';
    } else {
      this.mandatoryCss = ' iu-field ';
    }

    this.calendar.setMandatoryCss(this.mandatoryCss);
  }

  fieldChange(event) {
    this.dataChange(event);
  }

  setNewData(dataStored: DataStore, isInit = false) {
    super.setNewData(dataStored, isInit);
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  checkGridEditor() {
    const rect = this.elementRef.nativeElement.firstChild.getBoundingClientRect();
    this.top = rect.y + 32;
    this.left = rect.x;
    this.width = rect.width;
    const event = {
      panelStyleClass: this.calendar.panelStyleClass,
      panelStyle: this.calendar.panelStyle,
      inline: this.calendar.inline,
      overlayCalendarVisible: true,
      months: this.calendar.months,
      numberOfMonths: this.calendar.numberOfMonths,
      monthNavigator: this.calendar.monthNavigator,
      view: this.calendar.view,
      locale: this.calendar.locale,
      yearNavigator: this.calendar.yearNavigator,
      yearOptions: this.calendar.yearOptions,
      currentYear: this.calendar.currentYear,
      weekDays: this.calendar.weekDays,
      showOtherMonths: this.calendar.showOtherMonths,
      dateTemplate: this.calendar.dateTemplate,
      monthPickerValues: this.calendar.monthPickerValues,
      linkCalendarEditor: this,
      top: this.top,
      left: this.left,
      width: this.width,
      currentMonth: this.calendar.currentMonth,
      showTime: this.calendar.showTime,
    };
    this.checkGridEditorEmitter.emit(event);
  }

  onCalendarConfigChange(calendarConfig: { todayMode: boolean; value?: string }) {
    this.calendarConfig = calendarConfig;
    this.calendarConfigChange.emit(calendarConfig);
  }
}
