import { Injectable } from '@angular/core';
import { Email, EmailModel } from '@compiere-ws/models/compiere-mail-json';
import { AppConfig } from '@iupics-config/app.config.service';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private url: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.url = this.config.getBackendResource('email');
  }

  public getEmailModels(): Observable<EmailModel[]> {
    return this.http.get<EmailModel[]>(this.url);
  }

  public sendEmail(email: Email) {
    return this.http.post(this.url, email);
  }
}
