<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container operation-management-window no-overflow-x"
  [ngClass]="{ 'specific-window-fullscreen': isFullScreen }"
  [attr.data-cy-specific-window-name]="name">
  <!-- ? Panel top -->
  <div class="operation-management-container apiz-p-grid">
    <!-- ? Panel top-left -->
    <div class="operation-management-panel">
      <div class="p-col-12 operation-management-inner-fieldset p-grid">
        <ng-template #top />
      </div>
      <div class="apiz-p-grid">
        <iu-prime-fieldset
          [toggleable]="false"
          [collapsed]="false"
          legend="{{ 'operation-management.note' | translate }}"
          class="p-col-12"
          [overridedCSS]="bigLegend">
          <!-- calc(13 * (1.275em)); -->
          <div class="operation-management-inner-fieldset">
            <iu-input-textarea-ui
              [isReadOnly]="!selectedOperation"
              [isStandalone]="true"
              [fieldValue]="selectedOperation?.note"
              (fieldValueModified)="selectedOperation.note = $event"
              [overridedCSS]="bigInput"
              [resize]="'none'"
              [shouldSyncHeightWithLine]="true" />
          </div>
        </iu-prime-fieldset>
      </div>
    </div>

    <!-- ? Panel top-right -->
    <div class="operation-management-panel">
      <div class="apiz-p-grid">
        @if (selectedOperation?.description) {
          <iu-prime-fieldset
            [toggleable]="false"
            [collapsed]="false"
            legend="{{ 'operation-management.description' | translate }}"
            class="p-col-12"
            [overridedCSS]="bigLegend">
            <div class="operation-management-inner-fieldset" style="font-size: 14pt">
              {{ selectedOperation?.description }}
            </div>
          </iu-prime-fieldset>
        }
      </div>
      <div class="apiz-p-grid">
        <iu-input-file-ui
          class="p-col-12"
          [canUpload]="false"
          [isStandalone]="true"
          [mustGet]="false"
          [uploadedFiles]="selectedOperation?.attachedFile || []"
          [canDelete]="false"
          [canPreview]="true"
          [type]="'OPERATION_MANAGEMENT_DOC'"
          [searchInformations]="[{ 'META|TYPE': 'OPERATION_MANAGEMENT_DOC' }]"
          [multiple]="true"
          [uploadedFileList_title]="'operation-management.input-file.title' | translate"
          [uploadedFileList_noFiles]="'operation-management.input-file.no-files' | translate"
          [overridedCSS]="[bigInput, bigLabel] | spreadOperator" />
      </div>

      @if (isLastOperation) {
        <div>
          <!-- ? produits fabriqués -->
          <iu-prime-fieldset
            [toggleable]="false"
            [collapsed]="false"
            legend="{{ 'operation-management.products' | translate }}"
            [overridedCSS]="bigLegend">
            <div class="operation-management-inner-fieldset">
              <table class="products-table" aria-label="products table">
                @if (selectedProducts) {
                  <ng-container
                    [ngTemplateOutlet]="productList"
                    [ngTemplateOutletContext]="{ products: selectedProducts, $implicit: false }" />
                } @else {
                  <ng-container
                    [ngTemplateOutlet]="productList"
                    [ngTemplateOutletContext]="{
                      products: [
                        {
                          name: 'PRODUCT',
                          QtyProduced: 0,
                          QtyToProduce: 0,
                          unit: 'operation-management.unit' | translate,
                          usedQty: 0,
                        },
                      ],
                      $implicit: true,
                    }" />
                }
              </table>
              <div class="operation-management-main-btn">
                <button class="p-button p-button-success big-btn" (click)="validateProducts()">
                  {{ 'operation-management.end' | translate }}
                </button>
                <button class="p-button p-button-info big-btn" (click)="saveProducts()">
                  {{ 'operation-management.save' | translate }}
                </button>
              </div>
            </div>
          </iu-prime-fieldset>
        </div>
      }
    </div>
  </div>
  <hr />
  <!-- ? Panel central -->
  <div class="operation-management-container apiz-p-grid">
    <!-- ? Panel central-left -->
    <div class="operation-management-panel">
      <iu-prime-fieldset
        [toggleable]="false"
        [collapsed]="false"
        legend="{{ 'operation-management.components' | translate }}"
        [overridedCSS]="bigLegend">
        <div class="operation-management-inner-fieldset">
          <table class="components-table" aria-label="components table">
            @if (selectedOperation) {
              <ng-container
                [ngTemplateOutlet]="componentList"
                [ngTemplateOutletContext]="{ components: selectedOperation.components, $implicit: false }" />
            } @else {
              <ng-container
                [ngTemplateOutlet]="componentList"
                [ngTemplateOutletContext]="{
                  components: [
                    {
                      name: 'COMPONENT',
                      QtyConsumed: 0,
                      QtyToConsume: 0,
                      unit: 'operation-management.unit' | translate,
                      usedQty: 0,
                    },
                  ],
                  $implicit: true,
                }" />
            }
          </table>
          <button
            [disabled]="!selectedOperation || true"
            (click)="toggleComponentInput()"
            class="p-button p-button-info big-btn">
            {{ 'operation-management.add-component' | translate }}
          </button>
        </div>
      </iu-prime-fieldset>
    </div>
    <!-- ? Panel central-right -->
    <div class="operation-management-panel">
      <iu-prime-fieldset
        [toggleable]="false"
        [collapsed]="false"
        legend="{{ 'operation-management.ressources' | translate }}"
        [overridedCSS]="bigLegend">
        <div class="operation-management-inner-fieldset">
          <table class="ressources-table" aria-label="ressources table">
            @if (selectedOperation) {
              <ng-container
                [ngTemplateOutlet]="ressourceList"
                [ngTemplateOutletContext]="{ ressources: selectedOperation.ressources, $implicit: false }" />
            } @else {
              <ng-container
                [ngTemplateOutlet]="ressourceList"
                [ngTemplateOutletContext]="{
                  ressources: [{ name: 'RESSOURCE', baseTime: 0, usedTime: 0 }],
                  $implicit: true,
                }" />
            }
          </table>
        </div>
      </iu-prime-fieldset>

      @if (hasDeltaTime) {
        <iu-prime-fieldset
          [toggleable]="false"
          [collapsed]="false"
          legend="{{ 'operation-management.delta-time-note' | translate }}"
          [overridedCSS]="bigLegend">
          <div class="operation-management-inner-fieldset">
            <iu-input-textarea-ui
              [isStandalone]="true"
              [fieldValue]="selectedOperation?.deltaTime"
              (fieldValueModified)="updateDeltaTime()" />
          </div>
        </iu-prime-fieldset>
      }
    </div>
    <div class="p-col-6 p-offset-6">
      <hr />
      <div class="operation-management-main-btn">
        <button
          [disabled]="!selectedOperation || selectedOperation.status.id === ''"
          class="p-button p-button-success big-btn"
          (click)="validateOperation()"
          data-cy="btn-end-operation">
          {{ 'operation-management.end' | translate }}
        </button>
        <button
          [disabled]="!selectedOperation || selectedOperation.status.id === ''"
          class="p-button p-button-info big-btn"
          (click)="saveOperation()"
          data-cy="btn-save-operation">
          {{ 'operation-management.save' | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- ? Panel bottom-right -->
</div>

<!-- ? Zone des templates -->
<ng-template #componentList let-components="components" let-readOnly>
  @for (component of components; track i; let i = $index) {
    <tr [ngClass]="[readOnly ? 'read-only' : '', 'operation-management-row']">
      <td class="big-label">{{ component.name | uppercase }}</td>
      <td class="big-label">{{ component.QtyConsumed }}/{{ component.QtyToConsume }} {{ component.unit }}</td>
      <td style="width: 20%">
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="component.usedQty"
          (fieldValueModified)="updateUsedQtyOperation($event, i)"
          [isReadOnly]="readOnly"
          [overridedCSS]="bigInput" />
      </td>
      <td style="width: 25%" class="btn-cell">
        <button class="p-button p-button-primary big-btn" [disabled]="readOnly" (click)="changeUsedQtyOperation(1, i)">
          +
        </button>
        <button
          class="p-button p-button-secondary big-btn"
          [disabled]="readOnly"
          (click)="changeUsedQtyOperation(-1, i)">
          -
        </button>
      </td>
    </tr>
  }

  @if (isAddingOperationComponent) {
    <ng-container [ngTemplateOutlet]="componentInput" />
  }
</ng-template>

<ng-template #componentInput>
  <tr [ngClass]="['operation-management-row']">
    <td>
      <iu-input-text-ui
        [placeHolder]="'operation-management.component-item.name' | translate"
        [isStandalone]="true"
        [overridedCSS]="[bigInput, bigLabel] | spreadOperator"
        #inputTextName />
    </td>
    <td>
      <iu-input-number-ui
        [placeHolder]="'operation-management.component-item.nb' | translate"
        [isStandalone]="true"
        [overridedCSS]="[bigInput, bigLabel] | spreadOperator"
        #inputNumberBaseQty />
    </td>
    <td>
      <iu-input-text-ui
        [placeHolder]="'operation-management.component-item.unit' | translate"
        [isStandalone]="true"
        [overridedCSS]="[bigInput, bigLabel] | spreadOperator"
        #inputTextUnit />
    </td>

    <td style="display: flex">
      <button
        class="p-button p-button-primary big-btn"
        (click)="
          addOperationComponent(inputTextName?.fieldValue, inputNumberBaseQty?.fieldValue, inputTextUnit?.fieldValue)
        ">
        {{ 'generic.confirm' | translate }}
      </button>
      <button class="p-button p-button-secondary big-btn" (click)="isAddingOperationComponent = false">
        {{ 'generic.cancel' | translate }}
      </button>
    </td>
  </tr>
</ng-template>

<ng-template #ressourceList let-ressources="ressources" let-readOnly>
  @for (ressource of ressources; track i; let i = $index) {
    <tr [ngClass]="[readOnly ? 'read-only' : '', 'operation-management-row']">
      <td class="big-label">{{ ressource.name }}</td>
      <td class="big-label">{{ ressource.baseTime - ressource.usedTime }}/{{ ressource.baseTime }}h</td>
      <td style="width: 20%">
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="ressource.usedTime"
          (fieldValueModified)="updateUsedTime($event, i)"
          [overridedCSS]="bigInput" />
      </td>
      <td style="width: 25%" class="btn-cell">
        <button class="p-button p-button-primary big-btn" [disabled]="readOnly" (click)="changeUsedTime(1, i)">
          +
        </button>
        <button class="p-button p-button-secondary big-btn" [disabled]="readOnly" (click)="changeUsedTime(-1, i)">
          -
        </button>
      </td>
    </tr>
  }
</ng-template>

<ng-template #productList let-products="products" let-readOnly>
  @for (product of products; track i; let i = $index) {
    <tr [ngClass]="[readOnly ? 'read-only' : '', 'operation-management-row']">
      <td class="big-label">{{ product.name }} {{ 'operation-management.unit' | translate }}</td>
      <td class="big-label">{{ product.QtyProduced }}/{{ product.QtyToProduce }} {{ product.unit }}</td>
      <td style="width: 20%">
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="product.usedQty"
          (fieldValueModified)="updateUsedQtyProduct($event, i)"
          [overridedCSS]="bigInput" />
      </td>
      <td style="width: 25%" class="btn-cell">
        <button class="p-button p-button-primary big-btn" [disabled]="readOnly" (click)="changeUsedQtyProduct(1, i)">
          +
        </button>
        <button class="p-button p-button-secondary big-btn" [disabled]="readOnly" (click)="changeUsedQtyProduct(-1, i)">
          -
        </button>
      </td>
    </tr>
  }
</ng-template>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
