import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  model,
  output,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CompiereDataFieldType, CompiereDataJSON } from '@compiere-ws/models/compiere-data-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { map, tap } from 'rxjs';
import {
  SearchData,
  SKWLineStatus,
  SKWStateActionType,
  SKWTransferLineData,
  SKWTransferLineFormData,
} from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWScannerService } from '../../services/storekeeper-scanner.service';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from '../../services/storekeeper-window-message.service';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { Sound } from '../../utils/storekeeper-window-sound.utils';
import { StorekeeperInputComponent } from '../storekeeper-input/storekeeper-input.component';

@Component({
  selector: 'iu-storekeeper-window-input-scan',
  standalone: true,
  imports: [StorekeeperInputComponent, NgClass, SKWTranslatePipe],
  templateUrl: './storekeeper-window-input-scan.component.html',
  styleUrl: './storekeeper-window-input-scan.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorekeeperWindowInputScanComponent {
  readonly #SKWCtxService = inject(SKWContextService);
  readonly #SKWNavigationService = inject(SKWNavigationService);
  readonly #SKWMessageService = inject(SKWMessageService);
  readonly #SKWScannerService = inject(SKWScannerService);
  readonly #compiereDataService = inject(CompiereDataService);

  parsedField = {};

  data = model<SKWTransferLineFormData>();

  scanData = model<string>();

  isSearch = input(false);
  transfer = this.#SKWNavigationService.transferActive;
  lines = input<SKWTransferLineData[]>();

  inventory = input(false);
  inventoryChange = output<{ type: string; data: IAutocomplete }>();

  callback = input<(value: IAutocomplete) => void>(undefined);

  input = viewChild<StorekeeperInputComponent>('input');

  validation = output<string>();
  search = output<SearchData>();

  isPalette = computed(() => this.transfer()?.isPalette === 'Y');
  isULAndConditioning = computed(
    () => this.#SKWNavigationService.detailTabActive() === SKWLineStatus.UL && this.transfer()?.isCond === 'Y'
  );

  constructor() {
    for (const field of this.#SKWCtxService.container.fields) {
      this.parsedField[field.data.columnName] = field;
    }

    this.#SKWScannerService.scanner
      .pipe(
        takeUntilDestroyed(inject(DestroyRef)),
        tap((value) => this.scanDataChange(value))
      )
      .subscribe();
  }

  onReadings(readings: string[]) {
    for (const reading of readings) {
      this.scanDataChange(reading);
    }
  }

  scanDataChange(value: string) {
    if (!value) return;

    if (this.isSearch()) {
      return this.#search(value);
    }

    if (value.startsWith('A')) {
      this.#verify('M_Product_ID', this.inventory() ? 'M_Product_ID' : 'Product', value);
    } else if (value.startsWith('P')) {
      this.#verify('M_Locator_ID', this.inventory() ? 'M_Locator_ID' : 'palette', value);
    } else if (value.startsWith('E')) {
      if (!this.inventory() && !this.data()?.palette && !this.data()?.Product) {
        return this.#noMatches('UnableScanLocatorWithoutProductOrPallet');
      }

      this.#verify('M_Locator_ID', this.inventory() ? 'M_Locator_ID' : 'locator', value);
    } else if (value.startsWith('V')) {
      this.validation.emit(value);
    } else {
      this.#noMatches();
    }
  }

  #search(value: string) {
    let lineData: any;

    if (this.isULAndConditioning() && `P${this.lines()?.[0]?.palette_destination ?? 0}` === value) {
      lineData = {
        pallet: {
          id: this.lines()?.[0]?.palette_destination,
          displayValue: this.lines()?.[0]?.palette_destination_barcode,
        },
      };
    } else {
      lineData = this.lines()?.find((l) => {
        return (value.startsWith('P') ? `P${l?.palette_source}` : `A${l?.M_Product_ID?.id}`) === value;
      });
    }

    if (!lineData) {
      this.#noMatches();
      return;
    }

    const data: Partial<SKWTransferLineFormData> = {};
    if (this.isULAndConditioning()) {
      data['palette'] = lineData.pallet;
      lineData = this.lines()?.[0];
    } else if (this.isPalette()) {
      data['palette'] = { id: lineData.palette_source, displayValue: lineData?.palette_source_barcode };
      if (this.#SKWNavigationService.detailTabActive() === SKWLineStatus.TL) {
        data['locator'] = lineData.locator_source;
      }
    } else {
      data['Product'] = lineData.M_Product_ID;
    }

    this.search.emit({ line: lineData, data });
    Sound.playScan();
  }

  #verify(columnName: string, property: string, value: string) {
    const parsedValue = this.#removeIdentifier(value);

    if (this.data()?.[property] === parsedValue) return;

    this.#SKWCtxService.newAction({
      type: SKWStateActionType.GET,
      isLoading: true,
      source: this.#getData(this.parsedField[columnName], parsedValue).pipe(
        tap((result) => {
          if (!result?.[0]) {
            this.#noMatches();
          } else {
            this.#handleResult(result, property);
          }
        }),
        map(() => undefined)
      ),
    });
  }

  #getData(field: any, query: string = null) {
    return this.#compiereDataService.getDataForAutocomplete(
      CompiereDataFieldType.FORM_ITEM,
      field.formDetailItemId,
      true,
      null,
      `${field.data.details.keyColumn}=${query}`
    );
  }

  #handleResult(result: CompiereDataJSON, property: string) {
    if (!this.inventory()) {
      this.#update(property, result[0]);
      this.callback()?.(<any>this.data()[property]);
    } else {
      this.inventoryChange.emit({ type: property, data: result[0] });
    }
    Sound.playScan();

    const input = this.input();
    if (input) {
      input.value.set('');
    }
  }

  #update(key: string, value: { id: string; displayValue: string } | string) {
    this.data.update((v) => ({ ...v, [key]: value }));
  }

  #removeIdentifier(value: string) {
    return value.slice(1);
  }

  #noMatches(message?: string) {
    Sound.playError();
    this.#SKWMessageService.addMessage({
      type: SKWMessageType.ERROR,
      key: 'ScanError',
      title: 'ScanError',
      content: message ?? 'ScanErrorMsg',
    });
  }
}
