<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }

  @if (vcrOptional.length > 0) {
    <a class="settings" (click)="isSidebarOpen = !isSidebarOpen">
      <i class="fas fa-cog" aria-hidden="true"></i>
    </a>
  }

  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="p-col-12">
      <h1>{{ title }}</h1>
    </div>
    <div class="p-col-12">
      <iu-autocomplete-ui
        #printerNameAutocomplete
        [cssClass]="'p-col-12 p-md-6'"
        tooltip="{{ 'specificWindow.DirectPrintingDocumentsComponent.toolTipSelectPrinter' | translate }}"
        label="{{ 'specificWindow.DirectPrintingDocumentsComponent.SelectPrinter' | translate }}"
        [data]="printerNames"
        [isStandalone]="true" />
    </div>
    <div class="p-col-12">
      <ng-template #vcrParameters />
    </div>
    <div class="p-col-12">
      <ng-template #vcrProcess />
    </div>
  </p-scrollPanel>
  <p-sidebar
    [(visible)]="isSidebarOpen"
    [baseZIndex]="0"
    position="right"
    styleClass="p-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.optionalFilters' | translate }}
    </h1>
    <div class="optionalFields">
      <ng-template #optional />
    </div>
  </p-sidebar>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
