import { NgClass } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { AppConfig } from '@iupics-config/app.config.service';
import { Global } from '@iupics-manager/models/global-var';
import { Tab } from '@web-desktop/models/Tab';
import TabUiComponent from '../tab-ui/tab-ui.component';

@Component({
  selector: 'iu-tab-group-ui',
  templateUrl: './tab-group-ui.component.html',
  styleUrls: ['./tab-group-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass],
})
export default class TabGroupUiComponent implements OnInit, Tab, OnDestroy {
  static cpt = 0;

  @ViewChild('multiTabContent', { read: ViewContainerRef, static: true })
  entry: ViewContainerRef;
  @ViewChild('multiTab', { read: ElementRef, static: true })
  ref: ElementRef;

  angularClass?: string;
  components: ComponentRef<any>[] = [];
  id: string;
  isActive = false;
  tabWidth: number;

  destroyTabEmitter: EventEmitter<any> = new EventEmitter();
  setActiveTabEmitter: EventEmitter<any> = new EventEmitter();
  closeWindowEmitter: EventEmitter<any> = new EventEmitter();
  moveTabEmitter: EventEmitter<any> = new EventEmitter();
  createTabEmitter: EventEmitter<any> = new EventEmitter();
  updateScrollTabsEmitter: EventEmitter<any> = new EventEmitter();
  destroyTabContainerEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private config: AppConfig) {}

  ngOnInit() {}

  createTab(tab: TabUiComponent) {
    if (tab) {
      const tabRef = this.entry.createComponent(TabUiComponent);
      tabRef.instance.id = tab.id;
      tabRef.instance.domWinId = tab.domWinId;
      tabRef.instance.parentId = this.id;
      tabRef.instance.windowId = tab.windowId;
      tabRef.instance.name = tab.name;
      tabRef.instance.iconClass = tab.iconClass;
      tabRef.instance.appName = tab.appName;
      tabRef.instance.menuType = tab.menuType;
      tabRef.instance.actionID = tab.actionID;
      tabRef.instance.splitViewRef = tab.splitViewRef;
      tabRef.instance.destroyTabEmitter.subscribe((data) => {
        this.destroy(data);
      });
      tabRef.instance.setActiveTabEmitter.subscribe((data) => {
        this.setActive();
      });
      tabRef.instance.closeWindowEmitter.subscribe((data) => {
        data.tabGroup = this;
        this.closeWindowEmitter.emit(data);
      });
      this.components.push(tabRef);
    }
  }

  destroy(draggedComponent: TabUiComponent) {
    const componentFound = this.components.find((component) => component.instance.id === draggedComponent.id);
    const componentIndex = this.components.indexOf(componentFound);
    if (componentIndex !== -1) {
      // Remove component from both view and array
      this.entry.remove(this.entry.indexOf(componentFound.hostView));
      this.components.splice(componentIndex, 1);
      this.updateScrollTabsEmitter.emit();
    }
    if (this.components.length < 2) {
      this.destroyTabContainerEmitter.emit(this);
    }
  }

  handleDragStart(ev: DragEvent): void {
    ev.dataTransfer.setData('fireEvent', 'draggend');
    Global.draggedComponent = this;
    this.ref.nativeElement.parentElement.classList.add('dragged');
    ev.stopPropagation();
  }

  handleDragEnd(ev: DragEvent): void {
    this.ref.nativeElement.parentElement.classList.remove('dragged');
    Global.draggedComponent = null;
  }

  handleDrop(ev: DragEvent): boolean {
    ev.preventDefault();
    if (Global.draggedComponent === null || Global.draggedComponent instanceof TabGroupUiComponent) {
      return false;
    } else {
      const tabFound = this.components.find((tab) => tab.instance.id === Global.draggedComponent.id);
      // check if container has reached its limit or already contains dragged tab
      if (this.components.length < 4 && !tabFound) {
        this.createTab(Global.draggedComponent);
        this.setActive();
        this.destroyTabEmitter.emit(Global.draggedComponent);
        ev.stopPropagation();
      }
    }
  }

  handleDragOver(ev: any): boolean {
    // drop zone is the dragged component itself
    ev?.preventDefault();
    if (Global.draggedComponent === null) {
      return false;
    }
    if (
      Global.draggedComponent.id !== this.id &&
      !this.components.find((tab) => tab.instance.id === Global.draggedComponent.id)
    ) {
      // checking on which side dropzone should be displayed
      const bounding = Global.draggedComponent.ref.nativeElement.getBoundingClientRect();
      const boundingOver = this.ref.nativeElement.getBoundingClientRect();
      const x = (ev.layerX / this.ref.nativeElement.clientWidth) * 100;
      if (bounding.x > boundingOver.x && x < 40) {
        this.moveTabEmitter.emit({ tab: this, move: 0 });
      }
      if (bounding.x < boundingOver.x && x > 60) {
        this.moveTabEmitter.emit({ tab: this, move: 0 });
      }
      ev?.stopPropagation();
    }
  }

  setActive(ev?: MouseEvent): void {
    if (ev?.stopPropagation) ev.stopPropagation();
    this.setActiveTabEmitter.emit(this);
  }

  ngOnDestroy(): void {
    for (const cf of this.components) cf.destroy();
  }
}
