import { Injectable } from '@angular/core';
import { CompiereDataGridRequestJSON, DataGridExportFormat } from '@compiere-ws/models/compiere-data-json';
import { IAutocomplete, PrintFormatAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { ProcessParams } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { AppConfig } from '@iupics-config/app.config.service';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereExportDataService {
  private url: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.url = this.config.getBackendResource('exportData');
  }
  /**
   *
   * @param tabId Récupération des types de fichiers et les formats d'impression pour une fenetre
   * @param uuid Choix de la liste des types de fichier
   */
  public getExportTableDataFormats(tableId: number, uuid: number): Observable<ExportDataOptions> {
    return this.http.get<ExportDataOptions>(`${this.url}?table_id=${tableId}&uid=${uuid}`);
  }
  /**
   *
   * @param tabId Récupération des types de fichiers et les formats d'impression pour un processus d'édition
   * @param uuid Choix de la liste des types de fichier
   */
  public getExportProcessDataFormats(processId: number, uuid: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}?process_id=${processId}&uid=${uuid}`);
  }

  /**
   * permet de créer un fichier sous un format et un type pour les ids spécifiés
   * @param tabId id de la fenetre
   * @param printFormatId id du format d'impression
   * @param fileTypeId id du type de fichier
   * @param ids recordids des records sélectionnés
   */
  public exportData(tabId: number, printFormatId: number, fileTypeId: number, ids: number[], whereClause?: string) {
    let whereClauseString = '';
    if (whereClause) {
      whereClauseString = '&whereclause=' + whereClause;
    }
    let idStr = '';
    if (ids.length > 0) {
      idStr = '&query=';
    }
    for (let i = 0; i < ids.length; i++) {
      idStr += ids[i] + '';
      idStr += i < ids.length - 1 ? ',' : '';
    }
    return this.http.post<any>(
      `${this.url}/?tab_id=${tabId}&printFormat_id=${printFormatId}&fileFormat=${fileTypeId}${idStr}${whereClauseString}`,
      {}
    );
  }

  /**
   * permet de créer un fichier excel avec les données récupérées avec le datagridRequest
   * @param datagridRequest request utilisée pour la recup des données de la grille courante
   */
  public exportDataGrid(
    datagridRequest: CompiereDataGridRequestJSON,
    format: DataGridExportFormat,
    includeGroupDetails = false
  ) {
    const url = this.config.getBackendResource('exportDataGrid');
    const datagridWithoutDataUUID: CompiereDataGridRequestJSON = { ...datagridRequest };
    datagridWithoutDataUUID.headerCols = datagridRequest.headerCols.filter((header) => header.field !== 'Data_UUID');

    return this.http.post<string>(
      `${url}/${format}`,
      { request: datagridWithoutDataUUID, includeGroupDetails },
      { responseType: 'text' as 'json' }
    );
  }
  public exportDataGridUsingPrintFormat(processRequestVO: ProcessParams) {
    const url = this.config.getBackendResource('exportDataGrid');
    return this.http.post<string>(`${url}`, processRequestVO, { responseType: 'text' as 'json' });
  }
}

export interface ExportDataOptions {
  printFormats: PrintFormatAutocomplete[];
  fileFormats: IAutocomplete[];
}
