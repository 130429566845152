<fieldset
  [attr.id]="id"
  [ngClass]="[
    'p-fieldset ui-widget ui-widget-content ui-corner-all',
    toggleable ? 'p-fieldset-toggleable' : '',
    styleClass ? styleClass : '',
  ]"
  [ngStyle]="style"
  [attr.data-cy-tablename]="tableName ? tableName : legend"
  [attr.aria-expanded]="!collapsed">
  @if (isLegendVisible) {
    <legend [ngClass]="['p-fieldset-legend ui-corner-all ui-state-default ui-unselectable-text']">
      <a
        tabindex="0"
        (click)="handleLegendEvent($event)"
        (keydown.enter)="handleLegendEvent($event)"
        [attr.aria-controls]="id + '-content'"
        [attr.aria-expanded]="!collapsed"
        data-cy="fieldset">
        <ng-container [ngTemplateOutlet]="legendContent" />
      </a>
      <ng-template #legendContent>
        <span class="p-fieldset-legend-text" [style.font-size]="overridedCSS?.legendFontSize">{{ legend }}</span>
        @if (toggleable && (!isSingleRow || isReadOnly)) {
          <span [ngClass]="['p-fieldset-toggler pi', collapsed ? 'pi-plus' : 'pi-minus']"></span>
        }

        @if (isSingleRow && !isReadOnly) {
          <span
            class="icon-next-info"
            tooltipPosition="top"
            showDelay="250"
            data-cy="openNewBlade"
            [life]="3000"
            [hideDelay]="250"
            pTooltip="{{ 'menuBarDetails.openNewBlade' | translate }}"></span>
        }

        <ng-content select="p-header" />
      </ng-template>
    </legend>
  }
  <div
    [attr.id]="id + '-content'"
    [@fieldsetContent]="
      collapsed
        ? { value: 'hidden', params: { transitionParams: transitionOptions } }
        : { value: 'visible', params: { transitionParams: transitionOptions } }
    "
    [ngClass]="[collapsed || animating ? 'p-fieldset-content-wrapper-overflown' : '', 'p-fieldset-content-wrapper']"
    [attr.aria-hidden]="collapsed"
    (@fieldsetContent.done)="onToggleDone()"
    role="region">
    <div class="p-fieldset-content">
      <ng-content />
    </div>
  </div>
</fieldset>
