<div
  #mainDiv
  id="chips"
  [ngClass]="['p-chips ui-widget', styleClass || '']"
  [ngStyle]="style"
  (click)="onWrapperClick()">
  <ul
    #wrapper
    data-cy="chips-list"
    [ngClass]="[
      'p-inputtext ui-state-default ui-corner-all',
      focused ? 'ui-state-focus' : '',
      disabled ? 'p-disabled' : '',
    ]"
    [ngStyle]="
      this.shouldBeSmall && this.focused && inputtext.value.trim() !== ''
        ? { display: 'none' }
        : {
            'max-width.px': maxWidthChipsContainer,
            display: 'flex',
            overflow: hasScroll ? 'hidden' : 'inherit',
            'flex-wrap': hasScroll ? 'nowrap' : 'wrap',
            gap: '5px',
          }
    "
    (wheel)="handleWheel($event)">
    @for (item of value; track $index) {
      <li #token class="p-chips-token p-highlight ui-corner-all" (click)="onItemClick($event, item)" data-cy="chip">
        @if (itemTemplate) {
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }" />
        } @else {
          <span class="p-chips-token-label">{{ field ? resolveFieldData(item, field) : item }}</span>
        }

        @if (!disabled) {
          <span class="p-chips-token-icon pi pi-times" (click)="removeItem($event, $index)"></span>
        }
      </li>
    }

    <li class="p-chips-input-token" style="max-width: 50%">
      <input
        #inputtext
        type="text"
        [ngStyle]="this.focused && (!this.shouldBeSmall || inputtext.value.trim() !== '') ? { width: '100%' } : {}"
        [ngClass]="['prime-chips-input', value?.length ? 'prime-chips-input-small' : '', inputStyleClass || '']"
        [readonly]="disabled || preventInput"
        [tabindex]="disabled ? '-1' : (tabindex ?? null)"
        [attr.id]="inputId"
        [attr.placeholder]="value && value.length ? null : placeholder"
        [attr.aria-labelledby]="ariaLabelledBy"
        [attr.data-cy-columnName]="columnName"
        data-cy="input-chips"
        autocomplete="no"
        (keydown)="onKeydown($event)"
        (input)="onInput()"
        (paste)="onPaste($event)"
        (focus)="!disabled ? onInputFocus($event) : null"
        (blur)="onInputBlur($event)" />
    </li>
  </ul>
</div>
