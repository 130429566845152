<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast invoice']"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && (isInputInfoLoading || isLoading)) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }

  <div class="fast-invoice-grid">
    <!-- title -->
    <div class="title">
      <h1>{{ 'specificWindow.quickInvoiceVendor.title' | translate }} ( {{ docStatus }} )</h1>
      <p-toolbar>
        <button
          pButton
          tooltipPosition="left"
          showDelay="250"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'specificWindow.quickInvoiceVendor.joinFiles' | translate }}"
          type="button"
          icon="icon-attach3x"
          data-cy="button-attachFile"
          style="overflow: visible"
          (mouseup)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: true })">
          @if (nbUploadedFiles !== undefined && nbUploadedFiles > 0) {
            <span class="iupics-badge iupics-badge-count" style="top: 0; right: -2px">
              {{ nbUploadedFiles > 9 ? '9+' : nbUploadedFiles }}
            </span>
          }
        </button>
        <button
          pButton
          tooltipPosition="right"
          showDelay="250"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'specificWindow.quickInvoiceVendor.settings' | translate }}"
          type="button"
          icon="fas fa-cog"
          (click)="isSidebarOpen = !isSidebarOpen"></button>
      </p-toolbar>
    </div>
    <!-- qr -->
    <div class="qr">
      <textarea
        #inputInfoArea
        class="quickInvoiceQRInfo"
        (focus)="clearInputArea(); setPlaceHolder(true)"
        (blur)="setInputInfo(); setPlaceHolder()"
        autocomplete="no"
        autoResize="autoResize"
        placeholder="{{ placeHolder }}"
        [(ngModel)]="inputInfoString"
        [ngClass]="{
          quickInvoiceQRInfoEmpty: !inputInfoString || inputInfoString.length === 0,
          hasError: (inputInfoStringLast || inputInfoString) && inputInfo && inputInfo.logicalErrors > 0,
        }"
        [disabled]="!this.messages || this.messages.length === 0"></textarea>
      <label class="quickInvoiceLogs-header">
        {{ 'specificWindow.quickInvoiceVendor.invoiceLogTitle' | translate }}
        @if (inputInfo && inputInfo.logicalWarnings > 0) {
          <span class="quickInvoiceLogs-header-warning-cpt"> ({{ inputInfo.logicalWarnings }}) </span>
        }
      </label>
      <div
        #logsContainer
        class="quickInvoiceLogs"
        [ngClass]="{
          hasError: (inputInfoStringLast || inputInfoString) && inputInfo && inputInfo.logicalErrors > 0,
          isSuccess:
            dataStore &&
            dataStore.data &&
            dataStore.data['C_BPartner_ID'] &&
            !isInputInfoLoading &&
            inputInfoStringLast &&
            inputInfo &&
            inputInfo.logicalErrors === 0 &&
            inputInfo.logicalWarnings === 0,
        }">
        @for (log of logs; track $index) {
          <div
            class="quickInvoiceLogs-item"
            [ngClass]="{ 'quickInvoiceLogs-error': !log.isSuccess, 'quickInvoiceLogs-title': log.type === 'title' }">
            {{ log.value }}
          </div>
        }
      </div>
    </div>
    <!-- left -->
    <div class="left subgrid">
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.vendor' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBPartner />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.vendorLocation' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBPartnerLocation />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.amountToPay' | translate }}</label>
        <div class="flex-grow">
          <iu-input-number-ui
            #amountToPayInput
            [isStandalone]="true"
            [(fieldValue)]="this.amountToPay"
            label=""
            [data]="grandTotalData"
            [currency]="currency"></iu-input-number-ui>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.vendorIBAN' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBPBankAccount />
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="right subgrid col-2">
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.order' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrOrder />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.dateInvoiced' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrDateInvoiced />
        </div>
      </div>
      <div class="group group-row col-2">
        <label>{{ 'specificWindow.quickInvoiceVendor.paymentCommunication' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrZ_PaymentCommunication />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.BVRNumber' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBVR />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.validator' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrValidator />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.priceListHeader' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrPriceListHeader />
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickInvoiceVendor.paymentTerm' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrPaymentTerm />
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="table">
      <div class="row-btn pt-0 separator-border">
        <ng-template #vcrCreateFrom />
      </div>
      <p-scrollPanel [style]="{ width: '100%' }">
        <div id="page-wrap">
          <!-- invoice line -->
          <div #linesContainer class="items-container">
            <table id="items">
              <colgroup>
                <col class="productCol" />
                <col class="chargeCol" />
                <col class="quantityCol" />
                <col class="priceEnteredCol" />
                <col class="priceListCol" />
                <col class="taxAmtCol" />
                <col class="taxCol" />
                <col class="totalLineCol" />
                <col class="deleteCol" />
              </colgroup>
              <thead>
                <tr>
                  <th>{{ translations.product }}</th>
                  <th>{{ translations.charge }}</th>
                  <th>{{ translations.quantity }}</th>
                  <th>{{ translations.priceEntered }}</th>
                  <th>{{ translations.priceList }}</th>
                  <th>{{ translations.taxAmt }}</th>
                  <th>{{ translations.tax }}</th>
                  <th>{{ translations.totalLine }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (item of nbLines | fakeArray; track i; let i = $index) {
                  <tr
                    class="item-row"
                    [ngClass]="{ notPersisted: !dataLines[i] || !dataLines[i].data['C_InvoiceLine_ID'] }">
                    <td [attr.data-label]="translations.product">
                      <ng-template #vcrProduct />
                    </td>
                    <td [attr.data-label]="translations.charge">
                      <ng-template #vcrCharge />
                    </td>
                    <td [attr.data-label]="translations.quantity">
                      <ng-template #vcrQty />
                    </td>
                    <td [attr.data-label]="translations.priceEntered">
                      <ng-template #vcrPriceEntered />
                    </td>
                    <td [attr.data-label]="translations.priceList">
                      <ng-template #vcrPriceList />
                    </td>
                    <td [attr.data-label]="translations.taxAmt">
                      <ng-template #vcrTaxAmt />
                    </td>
                    <td [attr.data-label]="translations.tax">
                      <ng-template #vcrTax />
                    </td>
                    <td [attr.data-label]="translations.totalLine">
                      <ng-template #vcrLineNetAmt />
                    </td>
                    <div style="display: none"><ng-template #vcrOthers /></div>
                    <td class="deleteButton" data-label="">
                      {{ item }}
                      @if (i < dataLines.length) {
                        <button
                          [disabled]="(dataStore?.data?.['DocStatus']?.id ?? dataStore?.data?.['DocStatus']) === 'CO'"
                          tooltipPosition="top"
                          showDelay="250"
                          [life]="3000"
                          [hideDelay]="250"
                          pTooltip="{{ 'generic.delete' | translate }}"
                          pButton
                          icon="icon-delete"
                          (click)="removeLine(dataLines[i], i)"></button>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div class="row-btn separator-border" id="hiderow">
            <button
              pButton
              id="addrow"
              class="button p-button-secondary"
              [disabled]="
                !dataStore.data['C_Invoice_ID'] ||
                (dataStore?.data?.['DocStatus']?.id ?? dataStore?.data?.['DocStatus']) === 'CO'
              "
              (click)="newLine()"
              label="{{ 'specificWindow.quickInvoiceVendor.addrow' | translate }}"
              icon="icon-add"></button>
          </div>
          <!-- total -->
          <div class="total-header subgrid col-3 auto-row">
            <div class="group group-row">
              <label class="total">
                {{ 'specificWindow.quickInvoiceVendor.grandNetTotal' | translate }}
              </label>
              <div class="flex-grow">
                <iu-input-number-ui
                  [isStandalone]="true"
                  [isReadOnly]="true"
                  [fieldValue]="this.grandNetTotal"
                  label=""
                  [data]="grandTotalData"
                  [currency]="currency" />
              </div>
            </div>
            <div class="group group-row">
              <label class="total">
                {{ 'specificWindow.quickInvoiceVendor.grandVatTotal' | translate }}
              </label>
              <div class="flex-grow">
                <iu-input-number-ui
                  [isStandalone]="true"
                  [isReadOnly]="true"
                  [fieldValue]="this.grandVatTotal"
                  label=""
                  [data]="grandTotalData"
                  [currency]="currency" />
              </div>
            </div>
            <div class="group group-row">
              <label class="total">
                {{ 'specificWindow.quickInvoiceVendor.grandTotal' | translate }}
              </label>
              <div class="flex-grow">
                <iu-input-number-ui
                  [isStandalone]="true"
                  [isReadOnly]="true"
                  [fieldValue]="this.grandTotal"
                  label=""
                  [data]="grandTotalData"
                  [currency]="currency" />
              </div>
            </div>
            <div class="group group-row justify-center col-3 row-btn">
              @if (!isProcessed) {
                <button
                  [ngClass]="['button p-button-alert cancelButton']"
                  (click)="$event.preventDefault(); $event.stopPropagation(); cancelDoc()"
                  pButton
                  label=" {{ 'specificWindow.quickInvoiceVendor.cancel' | translate }}"
                  icon="fas fa-ban"></button>
              }
              <button
                class="button p-button-success"
                [disabled]="
                  isProcessed ||
                  !dataStore.data['C_Invoice_ID'] ||
                  (dataStore?.data?.['DocStatus']?.id ?? dataStore?.data?.['DocStatus']) === 'CO'
                "
                (click)="$event.preventDefault(); $event.stopPropagation(); saveInvoice()"
                pButton
                label="{{ 'specificWindow.quickInvoiceVendor.save' | translate }}"
                icon="{{
                  dataStore?.data?.['DocStatus'] === 'CO' || dataStore?.data?.['DocStatus']?.id === 'CO'
                    ? 'fa fa-ban'
                    : 'fa fa-check'
                }}"></button>

              @if (dataStore?.data?.['C_Invoice_ID']) {
                <button
                  pButton
                  class="button p-button-warning"
                  (click)="$event.preventDefault(); $event.stopPropagation(); resetInvoice()"
                  label="{{ 'specificWindow.quickInvoiceVendor.newInvoice' | translate }}"
                  icon="fa fa-plus"></button>
              }
            </div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>

  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="0" position="right" styleClass="p-sidebar-md">
    <div class="sidebar-header">
      <h1>
        {{ 'specificWindow.quickInvoiceVendor.settings' | translate }}
      </h1>
      <button
        pButton
        tooltipPosition="right"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'generic.close' | translate }}"
        type="button"
        icon="icon-next"
        (click)="isSidebarOpen = !isSidebarOpen"></button>
    </div>
    <ng-template #vcrSettings />
  </p-sidebar>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

@if (displayProcessUI) {
  <div>
    <iu-modal-ui
      (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
      [hasCloseBtn]="true"
      [angularStyle]="{ 'iu-modal-body': { padding: 0 } }"
      [contentType]="'process'">
      <iu-process-ui
        (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
        [windowId]="processId"
        [parentComponent]="this"
        [isModal]="true" />
    </iu-modal-ui>
  </div>
}

@if (displayJoinFilesPanel) {
  <iu-modal-ui
    [title]="'joinFiles.title'"
    [hasCloseBtn]="true"
    [contentType]="'joinFiles'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: false })">
    <iu-join-file-ui
      [dsKey]="dsKey"
      [adTable_ID]="tableID"
      [adTab_ID]="invoiceTabID"
      [(nbUploadedFiles)]="nbUploadedFiles"
      [data]="data" />
  </iu-modal-ui>
}
