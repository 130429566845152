@if (page() !== 'list') {
  <i
    class="toolbar-icon icon-arrow-left-long"
    aria-hidden="true"
    [attr.disabled]="!state.loaded()"
    (click)="back($event)"></i>
}

<div class="toolbar-title">
  <h1 [ngClass]="{ title: true, 'with-sub-title': subTitle() !== undefined }">
    {{ title() | SKWTranslate }}
  </h1>
  @if (subTitle() !== undefined) {
    <h2 class="sub-title">
      {{ subTitle() }}
    </h2>
  }
</div>

@if (page() === 'list') {
  @if (!isFilterOpen()) {
    <i
      class="toolbar-icon icon-refresh"
      aria-hidden="true"
      [attr.disabled]="!state.loaded()"
      [attr.data-active]="state.pending() === 'refresh'"
      (click)="refresh($event)"></i>
  }
  <i
    class="toolbar-icon icon-filter-2"
    aria-hidden="true"
    [attr.disabled]="!state.loaded()"
    [attr.data-active]="isFilterOpen()"
    [attr.data-pin]="hasFilter()"
    (click)="toggleFilterPanel($event)"></i>
  <iu-storekeeper-filter-panel [(isOpen)]="isFilterOpen" />
}

@if (subTitle() !== undefined) {
  <i class="toolbar-icon icon-print-2" aria-hidden="true"></i>
}

@if (showToggleProfileBtn) {
  <i class="toolbar-icon icon-user" (click)="toggleProfile($event)" aria-hidden="true" [attr.disabled]="false"></i>
}
