<div
  class="badge-container"
  [attr.data-rule]="data.rule"
  iuLoading
  [dataToWatch]="data"
  role="button"
  (click)="onClick($event)">
  @if (data?.label) {
    <span class="badge-label">{{ data.label }}</span>
  }

  @if (data?.hasIcon) {
    <i
      [ngClass]="{
        'badge-value': true,
        'icon-checkmark': data.rule === 1,
        'icon-cross': data.rule === -1,
      }"
      aria-hidden="true"></i>
  } @else {
    <span class="badge-value" iuNumberAnimation [data]="data.value" [playAnimation]="true">
      {{ data.value }}
    </span>
  }
</div>
