import { NgClass } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import ModalUiComponent from '../../../standard/layouts/modal-ui/modal-ui.component';

@Component({
  selector: 'iu-price-history',
  templateUrl: './price-history.component.html',
  styleUrls: ['./price-history.component.scss'],
  standalone: true,
  imports: [ProgressBarModule, DynamicContainerDirective, TabViewModule, NgClass, ModalUiComponent, TranslateModule],
})
export default class PriceHistoryComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('vcrHistoriqueDesPrix', { read: ViewContainerRef, static: true })
  vcrHistoriqueDesPrix: ViewContainerRef;

  @ViewChild('vcrQtyReserved', { read: ViewContainerRef, static: true })
  vcrQtyReserved: ViewContainerRef;

  @ViewChild('vcrOrdered', { read: ViewContainerRef, static: true })
  vcrOrdered: ViewContainerRef;

  @ViewChild('vcrUnconfirmed', { read: ViewContainerRef, static: true })
  vcrUnconfirmed: ViewContainerRef;

  @ViewChild('vcrATP', { read: ViewContainerRef, static: true })
  vcrATP: ViewContainerRef;

  @ViewChild('vcrBlanketSO', { read: ViewContainerRef, static: true })
  vcrBlanketSO: ViewContainerRef;

  @ViewChild('vcrBlanketPO', { read: ViewContainerRef, static: true })
  vcrBlanketPO: ViewContainerRef;

  isProductView = false;

  constructor() {
    super();

    this.customDesignArray.push(
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12 p-md-4 p-lg-3',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Product_ID',
        cssClass: 'p-col-12 p-md-4 p-lg-3',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Warehouse_ID',
        cssClass: 'p-col-12 p-md-4 p-lg-3',
      },
      {
        vcr: 'vcrHistoriqueDesPrix',
        type: CustomDesignItemType.GRID,
        tableName: 'Price History 1 tab',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrQtyReserved',
        type: CustomDesignItemType.GRID,
        tableName: 'Reserved 2 tab',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrOrdered',
        type: CustomDesignItemType.GRID,
        tableName: 'Ordered 3 tab',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrUnconfirmed',
        type: CustomDesignItemType.GRID,
        tableName: 'Unconfirmed 5 tab',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrATP',
        type: CustomDesignItemType.GRID,
        tableName: 'ATP 6 tab',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrBlanketSO',
        type: CustomDesignItemType.GRID,
        tableName: 'BlanketSO 7 tab',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrBlanketPO',
        type: CustomDesignItemType.GRID,
        tableName: 'BlanketPO 8 tab',
        cssClass: 'p-col-12',
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    setTimeout(() => {
      for (const grid of this.gridViews) {
        if (grid.GridTabInfinityScrollUiComponent) {
          grid.GridTabInfinityScrollUiComponent.columnResize();
        }
      }
    }, 1);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.sourceComponentData) {
      this.dataStore.data['M_Warehouse_ID'] = this.sourceComponentData.api.getSelectedRows()[0]['M_Warehouse_ID'];
      if (this.sourceComponentData.api.getSelectedRows()[0]['M_Product_ID']) {
        this.dataStore.data['M_Product_ID'] = this.sourceComponentData.api.getSelectedRows()[0]['M_Product_ID'];
        this.isProductView = true;
      } else if (this.sourceComponentData.api.getSelectedRows()[0]['C_BPartner_ID']) {
        this.dataStore.data['C_BPartner_ID'] = this.sourceComponentData.api.getSelectedRows()[0]['C_BPartner_ID'];
      }
      if (this.sourceComponentData.api.getSelectedRows()[0]['M_Product_ID']) {
        this.setExcludedFiltersToGrid('Price History 1 tab', ['C_BPartner_ID', 'M_Warehouse_ID']);
        this.setExcludedFiltersToGrid('Reserved 2 tab', ['C_BPartner_ID']);
        this.setExcludedFiltersToGrid('Ordered 3 tab', ['C_BPartner_ID']);
        this.setExcludedFiltersToGrid('ATP 6 tab', ['C_BPartner_ID', 'M_Warehouse_ID']);
        this.setExcludedFiltersToGrid('BlanketSO 7 tab', ['C_BPartner_ID']);
        this.setExcludedFiltersToGrid('BlanketPO 8 tab', ['C_BPartner_ID']);
      } else if (this.sourceComponentData.api.getSelectedRows()[0]['C_BPartner_ID']) {
        this.setExcludedFiltersToGrid('Price History 1 tab', ['M_Product_ID', 'M_Warehouse_ID']);
        this.setExcludedFiltersToGrid('Reserved 2 tab', ['M_Product_ID']);
        this.setExcludedFiltersToGrid('Ordered 3 tab', ['M_Product_ID']);
        this.setExcludedFiltersToGrid('BlanketSO 7 tab', ['M_Product_ID']);
        this.setExcludedFiltersToGrid('BlanketPO 8 tab', ['M_Product_ID']);
      }
    }
    this.setDataContainersValueWithChangedStore();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }
}
