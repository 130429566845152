<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div
      [ngClass]="[
        this.dataStore?.data?.['Posted']?.id === 'E' || this.dataStore?.data?.['Posted']?.id === 'N'
          ? 'containerHidden'
          : '',
        'fields',
      ]">
      <div class="top-container-AccountingViewer">
        <div class="p-col-12 apiz-p-grid"><ng-template #left /></div>
      </div>
      <div class="bot-container-AccountingViewer">
        <div class="p-col-12"><ng-template #bot /></div>
        <ng-template iuDynamicContainer />
      </div>
    </div>
    @if (this.dataStore?.data?.['Posted']?.id === 'E' || this.dataStore?.data?.['Posted']?.id === 'N') {
      <div class="postingErrorMessage">
        {{ 'specificWindow.accountingViewer.postingErrorMessage' | translate }}
      </div>
    }
  </p-scrollPanel>
  <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
    <ng-template #vcrButtons />
    @if (isModal) {
      <iu-button-ui
        class="p-col-12 p-md-3 p-lg-1"
        btnType="alert"
        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban" />
    }
  </div>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
