import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStore,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsData } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import { injectGridApiService } from '@iupics/apiz-grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, merge, switchMap, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import AddressCardComponent from './card-content/address-card/address-card.component';
import ContactCardComponent from './card-content/contact-card/contact-card.component';

@Component({
  selector: 'iu-cards-ui',
  templateUrl: './cards-ui.component.html',
  styleUrls: ['./cards-ui.component.scss'],
  standalone: true,
  imports: [AddressCardComponent, ContactCardComponent, TranslateModule],
})
export default class CardsUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit, OnDestroy {
  private datastore = inject(DataStoreService);
  private connectorService = inject(SecurityManagerService);
  private messageManager = inject(MessageManagerService);
  private translator = inject(TranslateService);
  #gridApi = injectGridApiService();

  @Input() parentComponent: any;
  @Input() dataStored: DataStore;

  #filter$ = new Subject();
  _filter: CompiereDataGridRequestJSON;
  @Input() set filter(value: CompiereDataGridRequestJSON) {
    this.applyFilter(value);
  }
  get filter() {
    return this._filter;
  }

  @Output()
  clickEmitter = new EventEmitter<any>();

  private datagrid$: Subscription;
  private isGetDatagridInProgress = false;

  #refreshDataState$ = new Subject();
  #actionSource$ = merge(
    this.#refreshDataState$.asObservable(),
    this.#filter$.asObservable().pipe(tap((filter) => (this._filter = filter))),
    this.#gridApi.appliedItemsUpdated.asObservable()
  );

  cards: any[] = [];
  cardsDisplayed: any[] = [];

  @Input() type: string;
  @Input() limit: number = 4;
  @Input() plusCardsLimit: number = this.limit;

  uuid = uuid();

  debounceRefreshCardsFctTimer: NodeJS.Timeout;

  /* Mobile navigation */
  cardsContentMutationObs: MutationObserver;
  cardsIntersectionObs: IntersectionObserver;
  cardsElement: Element[] = [];
  cardsIntoView: Element[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      merge(this.#actionSource$, (<DataStore>this.DOMParentComponent?.parentTab?.dataStored).dataChange.asObservable())
        .pipe(
          tap(() => this.changeDataGridLoadingState()),
          switchMap(() => this.getData()),
          tap(({ data }) => this.#setData(data)),
          tap(() => this.changeDataGridLoadingState())
        )
        .subscribe()
    );

    this.cardsContentMutationObs = new MutationObserver(this.cardsContentMutationCallback.bind(this));
    this.cardsIntersectionObs = new IntersectionObserver(this.intersectionObsCallback.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (window.innerWidth <= 480) {
      this.limit = 1;
    }

    this.#refreshDataState$.next(true);
  }

  ngAfterViewInit(): void {
    this.activateMobileNavigation();
  }

  ngOnDestroy(): void {
    SubscriptionsUtils.unsubscribe(this.datagrid$, ...this.subscriptions);
    this.cardsIntersectionObs.disconnect();
    this.cardsContentMutationObs.disconnect();
  }

  onChildUpdate(event: IupicsEvent): void {}
  onRemoveComponent(event: IupicsEvent): void {}
  onSiblingUpdate(event: IupicsEvent): void {}

  getData() {
    const parent = this.DOMParentComponent ?? this.parentComponent;
    const dataStoreRequest: DataStoreRequest = {
      windowId: undefined,
      parent_constraint: LogicEvaluator.parseLogic(
        this.getCurrentContext(),
        parent.gridTabFilter[0],
        this.connectorService.getIupicsUserContext()
      ),
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        entityId: (<IupicsData>parent.data).AD_Tab_ID,
        tableName: parent.data.tableName,
        windowCtx: this.getCurrentContext(),
      },
    };

    if (this.filter) {
      dataStoreRequest.compiereRequest.filterModel = this.filter.filterModel;
      dataStoreRequest.compiereRequest.rowGroupCols = this.filter.rowGroupCols;
      dataStoreRequest.compiereRequest.sortModel = this.filter.sortModel;
    }

    return this.datastore.getDataGrid(dataStoreRequest, true);
  }

  #setData(result: any[]) {
    this.cards = result;
    this.resetDisplay();
    this.addMoreCards();
  }

  applyFilter(filter: CompiereDataGridRequestJSON) {
    this.#filter$.next(filter);
  }

  changeDataGridLoadingState() {
    this.isGetDatagridInProgress = !this.isGetDatagridInProgress;
  }

  getCurrentContext() {
    let editViewParent;
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getCurrentContext();
    } else {
      if (this.parentComponent?.editTabs?.[0]) {
        editViewParent = this.parentComponent;
      }
      return EditViewUtils.getCurrentContext(
        editViewParent,
        this.dataStored ? this.dataStored : undefined,
        this.connectorService.getIupicsUserContext()
      );
    }
  }

  addMoreCards() {
    if (this.cards?.length === 0) {
      this.cardsDisplayed = [];
      return;
    }
    const currentIndex = Math.max(this.cardsDisplayed.length, 1);
    const newIndex = currentIndex + (currentIndex === 0 ? this.limit : this.plusCardsLimit);
    this.cardsDisplayed = [...this.cards.slice(0, newIndex)];
  }

  refreshCards() {
    this.resetDisplay();
    this.#refreshDataState$.next(true);
  }

  resetDisplay() {
    this.cardsDisplayed = [];
    this.cardsElement = [];
    this.cardsIntoView = [];
  }

  /* Mobile navigation */
  @HostListener('window:resize', ['$event']) // Needed for refreshing cardsIntoView
  activateMobileNavigation() {
    if (window.innerWidth >= 1024) {
      return;
    }

    // reset states
    this.cardsElement = [];
    this.cardsIntersectionObs.disconnect();

    const cardsWrapper: HTMLElement = document.querySelector(
      `[data-cards-container="${this.uuid}"] [data-cards-wrapper]`
    );
    cardsWrapper.ontouchstart = (e: TouchEvent) => {
      e.stopPropagation();
    };
    this.cardsContentMutationObs.observe(cardsWrapper, { childList: true });
    const elements: HTMLElement[] = Array.from(cardsWrapper.querySelectorAll(`[data-card]`));
    for (const el of elements) {
      this.cardsElement.push(el);
      this.cardsIntersectionObs.observe(el);
    }
  }

  cardsContentMutationCallback(mutations: MutationRecord[]) {
    for (const mutation of mutations) {
      const addedNodes = Array.from(mutation.addedNodes);
      for (const el of addedNodes) {
        this.cardsIntersectionObs.observe(<Element>el);
        this.cardsElement.push(<Element>el);
      }
    }
  }

  intersectionObsCallback(entries: IntersectionObserverEntry[], _: IntersectionObserver) {
    if (window.innerWidth >= 1024) {
      return;
    }

    for (const entry of entries) {
      if (entry.isIntersecting) {
        this.cardsIntoView.push(entry.target);
        if (entry.target === entry.target.parentElement.lastElementChild) {
          this.addMoreCards();
        }
      } else {
        this.cardsIntoView = this.cardsIntoView.filter((cardEl) => cardEl !== entry.target);
      }
    }

    this.cardsIntoView.sort(
      (a, b) => parseInt(a.getAttribute('data-card'), 10) - parseInt(b.getAttribute('data-card'), 10)
    );
  }

  next() {
    this.scrollCardIntoView(
      this.cardsIntoView.length === 1
        ? this.cardsIntoView[0].nextElementSibling
        : this.cardsIntoView[this.cardsIntoView.length - 1]
    );
  }

  prev() {
    this.scrollCardIntoView(this.cardsIntoView[0].previousElementSibling);
  }

  scrollCardIntoView(card: Element | null) {
    card?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }

  clickCard(card: any) {
    const dataUUIDSplit = card?.['Data_UUID'].split(',') ?? null;
    if (!dataUUIDSplit) {
      this.messageManager.newMessage({
        name: this.translator.instant('cardsUi.recordLinkImpossibleTitle'),
        message: this.translator.instant('cardsUi.recordLinkImpossible'),
        type: 'error',
      });
      return;
    }

    // TODO: voir pour modif ça
    this.clickEmitter.emit(`${dataUUIDSplit[0]},${dataUUIDSplit[1]}`);
  }

  getTabWhereclause() {
    let validation;
    if (this.DOMParentComponent) {
      validation = (<GridViewUiComponent>this.DOMParentComponent).getTabWhereclause();
    } else if (this.parentComponent?.editTabs?.[0]) {
      validation = this.parentComponent.getTabWhereclause();
    }
    return validation;
  }
}
