<div class="body mergeToolBody">
  <div class="board">
    <div class="board-inner">
      <header class="board-header">
        <h3 class="board-title js-board-handle">
          <span class="board-title-text has-tooltip block-truncated"> {{ 'mergetool.local' | translate }} </span>
        </h3>
      </header>
      <div class="board-list-component">
        <ul class="board-list js-board-list">
          @for (col of dataToMerge.fields; track $index) {
            <li
              (click)="onClick($event, col, localData.data[col], 'local')"
              [ngClass]="[
                isSelected[col] && isSelected[col].who === 'local' ? 'selected-board-card' : '',
                'board-card',
              ]">
              {{ colNameToLabel.get(col) }} : {{ getDisplayValue(localData.data[col]) }}
            </li>
          }
        </ul>
      </div>
    </div>
    <div class="board-footer">
      <button
        pButton
        class="p-button p-button-secondary"
        (click)="keepAll($event, 'local')"
        [label]="'mergetool.keepLocal' | translate"></button>
    </div>
  </div>

  <div class="board">
    <div class="board-inner">
      <header class="board-header">
        <h3 class="board-title js-board-handle">
          <span class="board-title-text has-tooltip block-truncated"> {{ 'mergetool.merged' | translate }} </span>
        </h3>
      </header>
      <div class="board-list-component">
        <ul class="board-list js-board-list">
          @for (col of dataToMerge.fields; track $index) {
            <li class="board-card">{{ colNameToLabel.get(col) }} : {{ getDisplayValue(mergedData.data[col]) }}</li>
          }
        </ul>
      </div>
    </div>
    <div class="board-footer">
      <button
        pButton
        class="p-button p-button-primary"
        (click)="save($event)"
        [label]="'generic.save' | translate"></button>
    </div>
  </div>

  <div class="board">
    <div class="board-inner">
      <header class="board-header">
        <h3 class="board-title js-board-handle">
          <span class="board-title-text has-tooltip block-truncated"> {{ 'mergetool.remote' | translate }} </span>
        </h3>
      </header>
      <div class="board-list-component">
        <ul class="board-list js-board-list">
          @for (col of dataToMerge.fields; track $index) {
            <li
              (click)="onClick($event, col, conflictsResult.dataConflict[col], 'remote')"
              [ngClass]="[
                isSelected[col] && isSelected[col].who === 'remote' ? 'selected-board-card' : '',
                'board-card',
              ]">
              {{ colNameToLabel.get(col) }} : {{ getDisplayValue(conflictsResult.dataConflict[col]) }}
            </li>
          }
        </ul>
      </div>
    </div>
    <div class="board-footer">
      <button
        pButton
        class="p-button p-button-secondary"
        (click)="keepAll($event, 'remote')"
        [label]="'mergetool.keepRemote' | translate"></button>
    </div>
  </div>
</div>
