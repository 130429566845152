import { Component, computed, inject } from '@angular/core';
import { SKWTransferHeaderData } from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../../services/storekeeper-window-context.service';
import { SKWTransferService } from '../../../services/storekeeper-window-transfer.service';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';

@Component({
  selector: 'iu-storekeeper-transfer-details',
  standalone: true,
  imports: [StorekeeperInputComponent, StorekeeperButtonComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-transfer-details.component.html',
  styleUrl: './storekeeper-transfer-details.component.scss',
})
export class StorekeeperTransferDetailsComponent {
  readonly #SKWContextService = inject(SKWContextService);
  readonly SKWTransferService = inject(SKWTransferService);

  data = this.SKWTransferService.data;
  isLineOpen = computed(() => this.SKWTransferService.selectedLine() !== undefined);

  lines = this.SKWTransferService.linesDisplayed;

  isConditioning = this.SKWTransferService.isConditioning;
  isPallet = this.SKWTransferService.isPalette;
  isNewTransfer = this.SKWTransferService.isNewTransfer;
  isConvertingToConditioning = this.SKWTransferService.isConvertingToConditioning;

  isPending = computed(() => this.#SKWContextService.state.pending() !== undefined);

  canConvertToConditioning = computed(() => {
    return (
      !this.isPending() &&
      !this.isNewTransfer() &&
      !this.isPallet() &&
      !this.isConvertingToConditioning() &&
      !this.isLineOpen() &&
      (this.data()?.nblineco ?? 0) === 0
    );
  });

  changeValue(key: keyof SKWTransferHeaderData, newValue: unknown) {
    if (this.isNewTransfer()) {
      this.data.update((d) => ({ ...d, [key]: newValue }));
    }
  }

  togglePalletPanel(event?: MouseEvent) {
    this.isConvertingToConditioning.update((v) => !v);
  }
}
