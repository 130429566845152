import { Injectable } from '@angular/core';
import { Chat, ChatEntry, ChatFollow, ChatTag } from '@iupics-components/standard/notes/notes.interface';
import { AppConfig } from '@iupics-config/app.config.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class DetailsNoteService {
  private urlChatEntry: string;
  private urlChatFollow: string;
  private urlChatTags: string;
  constructor(
    private http: ApiService,
    private config: AppConfig,
    private messageManager: MessageManagerService,
    private translator: TranslateService
  ) {}

  public getChatEntry(AD_Table_ID: number, Record_ID: number): Observable<Chat> {
    this.urlChatEntry = this.config.getBackendResource('chatEntry');
    return this.http.get<Chat>(`${this.urlChatEntry}?AD_Table_ID=${AD_Table_ID}&Record_ID=${Record_ID}`).pipe(
      map((chat) => {
        if (chat) {
          return chat;
        } else {
          return null;
        }
      }),
      catchError(() => {
        this.messageManager.newMessage(
          new IupicsMessage(this.translator.instant('generic.error'), this.translator.instant('detailsNote.get_error'))
        );
        return of(null);
      })
    );
  }

  public sendChatEntry(chatEntry: ChatEntry): Observable<ChatEntry> {
    this.urlChatEntry = this.config.getBackendResource('chatEntry');

    return this.http.post<ChatEntry>(`${this.urlChatEntry}`, chatEntry).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('generic.error'),
            this.translator.instant('detailsNote.save_error'),
            'error'
          )
        );

        return of(null);
      })
    );
  }

  public followChat(
    C_Chat_ID: number,
    AD_User_ID: number,
    AD_Table_ID: number,
    Record_ID: number
  ): Observable<ChatFollow> {
    this.urlChatFollow = this.config.getBackendResource('chatFollow');
    const followChatRequest = {
      chatID: C_Chat_ID,
      tableID: AD_Table_ID,
      recordID: Record_ID,
      userID: AD_User_ID,
    };
    return this.http.post<ChatFollow>(`${this.urlChatFollow}`, followChatRequest).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('generic.error'),
            this.translator.instant('detailsNote.save_error'),
            'error'
          )
        );
        return of(null);
      })
    );
  }

  public deleteFollowChat(C_Chat_ID: number, AD_User_ID: number): Observable<boolean> {
    this.urlChatFollow = this.config.getBackendResource('chatFollow');
    return this.http.delete<boolean>(this.urlChatFollow + '?chatID=' + C_Chat_ID + '&userID=' + AD_User_ID).pipe(
      map((removed) => {
        if (!removed) {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('generic.error'),
              this.translator.instant('detailsNote.delete_error'),
              'error'
            )
          );
        }
        return removed;
      }),
      catchError(() => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('generic.error'),
            this.translator.instant('detailsNote.delete_error'),
            'error'
          )
        );
        return of(false);
      })
    );
  }

  public getChatTags(C_Chat_ID: number, AD_Window_ID): Observable<ChatTag[]> {
    this.urlChatTags = this.config.getBackendResource('chatTags');
    return this.http.get<ChatTag[]>(`${this.urlChatTags}?chatID=${C_Chat_ID}&windowID=${AD_Window_ID}`).pipe(
      map((tags) => {
        return tags ?? [];
      }),
      catchError(() => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('generic.error'),
            this.translator.instant('detailsNote.get_tags_error')
          )
        );

        return of([]);
      })
    );
  }

  // public deleteValuePreference(valuePreference: ValuePreference): Observable<boolean> {
  //   this.urlValuePreference = this.config.getBackendResource('valuePreference');
  //   const valuePreferenceRequest: ValuePreferenceRequest = {
  //     valuePreference: valuePreference,
  //     actionType: ValuePreferenceActionType.DELETE
  //   };
  //   return this.http.post<ValuePreferenceResponse>(`${this.urlValuePreference}`, valuePreferenceRequest).pipe(
  //     map((response) => {
  //       if (response && response.success) {
  //         this.messageManager.newMessage(
  //           new IupicsMessage(this.translator.instant('generic.success'), response.message, 'success')
  //         );
  //       } else {
  //         this.messageManager.newMessage(new IupicsMessage(this.translator.instant('generic.error'), response.message, 'error'));
  //       }
  //       return response ? response.success : false;
  //     }),
  //     catchError((err) => {
  //       this.messageManager.newMessage(
  //         new IupicsMessage(
  //           this.translator.instant('generic.error'),
  //           this.translator.instant('valuePreference.delete_error'),
  //           'error'
  //         )
  //       );
  //       return throwError(() => err);
  //     })
  //   );
  // }
}
