import { NgClass, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { IupicsJsonField } from '@iupics-manager/models/iupics_json_field';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { Nullable } from 'primeng/ts-helpers';
import InputJsonLineUiComponent from '../input-json-line-ui/input-json-line-ui.component';
import EditTabUiComponent from '../layouts/edit-tab-ui/edit-tab-ui.component';
import ValuePreferencePanelComponent from '../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-json-ui',
  templateUrl: './input-json-ui.component.html',
  styleUrls: ['./input-json-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    TextLimitPipe,
    ButtonModule,
    DynamicContainerDirective,
    NgStyle,
    TranslateModule,
  ],
})
export default class InputJsonUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  #translateService = inject(TranslateService);
  #messageManager = inject(MessageManagerService);

  hasMultiValues = false;
  fields: IupicsJsonField[] = [];
  focused: Nullable<boolean>;
  isNotAuthorized: Nullable<boolean>;
  @Input() addOnBlur = false;
  @Input() addOnEnter = true;
  @Input() addOnTab = true;
  @Input() addOnSpace = false;
  @Input() editOnBackspace = true;
  @Input() matchPattern: RegExp = /.*/;
  @Input() inputStyle: { [key: string]: string };
  @Input() placeholder: string;
  private globalClickListener: Function;
  onModelChange: Function;
  _chips = [];
  @Output() chipsChange = new EventEmitter<any>();
  @Output() onChipAdded = new EventEmitter<any>();
  @Output() onChipRemoved = new EventEmitter<any>();
  @ViewChild('opConflict', { static: true }) opConflict: OverlayPanel;
  @ViewChild('opCreation', { static: true }) opCreation: OverlayPanel;
  @ViewChild('vcrCreation', { read: ViewContainerRef, static: true })
  vcrCreation: ViewContainerRef;
  @ViewChild('jsonFieldsContainer', { read: ElementRef, static: true }) jsonFieldsContainer: ElementRef<HTMLDivElement>;
  @ViewChild('inputtext', { read: ElementRef, static: true })
  inputViewChild: ElementRef<HTMLInputElement>;
  @Input() data: IupicsDataField;
  @Input()
  columnName: string;

  @Input() placeHolder: string;
  fieldKeys: IupicsJsonField[] = [];
  fieldDisplayeds: IupicsJsonField[] = [];
  uniqueProp: boolean = false;
  currentChip;
  isFree = true;
  isFromInputJson = false;
  isSplitButtonOpen = false;
  @Input()
  set chips(value: any) {
    if (value instanceof Array) {
      this.generateDisplayedKeys(value);
      for (const c of value) {
        c._ID = this.generateId(c);
      }
      this.generateDisplayedKeys(value);
    }
    this._chips = value;
  }

  get chips() {
    return this._chips;
  }
  @Input()
  set fieldValue(value: any) {
    this._fieldValue = value;
    if (value != null && value != undefined) {
      if (!(value instanceof Object)) {
        let fieldValueJson = this.parseJson(value);
        if (fieldValueJson != null && fieldValueJson != undefined && !Array.isArray(fieldValueJson)) {
          fieldValueJson = [fieldValueJson];
        }
        this.chips = fieldValueJson;
      } else {
        this.chips = [value];
      }
    } else {
      this.chips = [];
    }
  }

  get fieldValue() {
    return this._fieldValue;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
    this.initJsonStructureData();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  dataChange(value) {
    super.dataChange(value);
  }

  parseJson(value: string) {
    return JSON.parse(value);
  }

  buildPanel() {
    this.isSplitButtonOpen = false;
    this.vcrCreation.clear();
    this.componentRefs = [];
    if (!this.isFree) {
      for (const f of this.fields) {
        this.buildLine(f);
      }
    } else {
      const keys = Object.keys(this.currentChip).filter((k) => k != '_ID');
      let i = 0;
      if (keys.length == 0) {
        this.buildLine();
      }
      for (const k of keys) {
        this.buildLine({
          componentName: this.currentChip[k] instanceof Object ? 'InputJsonUiComponent' : 'InputTextUiComponent',
          description: '',
          name: k,
          nameToShow: k,
          numberType: null,
          seqNo: i++,
          jsonFieldId: i++,
          isKey: false,
          isDisplayed: false,
        });
      }
    }
  }

  initJsonStructureData() {
    if (this?.data?.jsonDefId > 0) {
      const jsonDef = this.store.getJsonDef(this?.data?.jsonDefId);
      this.hasMultiValues = jsonDef.hasMultiValues;
      this.fields = jsonDef.fields;
      this.isFree = this.fields.length == 0;
      this.uniqueProp = this.fields.length == 1;
      this.fieldKeys = this.fields.filter((f) => f.isKey);
      if (!this.isFree || this.fields.length > 0) this.fieldDisplayeds = this.fields.filter((f) => f.isDisplayed);
    }
  }

  buildObjectLine() {
    this.buildLine({
      componentName: 'InputJsonUiComponent',
      description: '',
      name: '',
      nameToShow: '',
      numberType: null,
      seqNo: -1,
      jsonFieldId: -1,
      isKey: false,
      isDisplayed: false,
    });
  }
  buildLine(jsonField?: IupicsJsonField) {
    if (!jsonField) {
      jsonField = {
        componentName: 'InputTextUiComponent',
        description: '',
        name: '',
        nameToShow: '',
        numberType: null,
        seqNo: -1,
        jsonFieldId: -1,
        isKey: false,
        isDisplayed: false,
      };
    }
    const componentRef = this.vcrCreation.createComponent(InputJsonLineUiComponent);
    componentRef.instance.dataChangeEmitter.subscribe((value) => this.lineChange(value));
    componentRef.instance.jsonField = jsonField;
    componentRef.instance.value = this.currentChip[jsonField.name];
    componentRef.instance.key = jsonField.name;
    componentRef.instance.nameToShow = jsonField.nameToShow;
    componentRef.instance.isFree = this.isFree;
    this.componentRefs.push(componentRef);
  }

  lineChange(value: any) {
    let oldId = this.currentChip['_ID'];
    const keys = Object.keys(value);
    for (const k of keys) {
      if (k === '' || value[k] === undefined || value[k] === null) {
        delete this.currentChip[k];
      } else {
        this.currentChip[k] = value[k];
      }
    }
    let newId = this.generateId(this.currentChip);
    if (!this.addChip(this.currentChip, oldId, newId)) {
      this.currentChip['_ID'] = oldId;
    }
  }

  addChip(chip: any, oldId: string, newId: string): boolean {
    this.isNotAuthorized = false;
    if (chip) {
      let foundIndex = this.chips.findIndex((t) => t._ID === oldId);
      if (newId != oldId && this.chips.find((t) => t._ID === newId)) {
        this.#messageManager.newMessage(
          new IupicsMessage(
            this.#translateService.instant('generic.error'),
            this.#translateService.instant('inputJson.duplicateKey'),
            'error'
          )
        );
        return false;
      }

      if (newId?.length > 0) {
        chip._ID = newId;
        if (foundIndex == -1) {
          this.chips = [...this.chips, chip];
        } else {
          this.chips = [
            ...this.chips.slice(0, foundIndex), // Elements before foundIndex
            chip, // New chip
            ...this.chips.slice(foundIndex + 1), // Elements after foundIndex
          ];
        }
      } else if (foundIndex > -1) {
        this.chips = [...this.chips.slice(0, foundIndex), ...this.chips.slice(foundIndex + 1)];
      } else return false;
    }
    this.updateModel();
    this.chipsChange.emit(this.chips);
    this.onChipAdded.emit();
    return true;
  }

  removeJsonChip(event, index: number): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.isReadOnly) {
      this.chips.splice(index, 1);
      this.updateModel();
      this.chipsChange.emit(this.chips);
      this.onChipRemoved.emit();
    }
  }

  removeChipById(id: string): void {
    const idx = this.chips.findIndex((c) => c._ID === id);
    if (idx > -1) {
      this.removeJsonChip(null, idx);
    }
  }

  showCreationPanel(event, chip = {}) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.uniqueProp && !this.isReadOnly) {
      this.currentChip = chip;
      this.buildPanel();
      this.opCreation.show(event);
      setTimeout(() => {
        if (this.componentRefs[0]?.instance?.valueComponentRef?.instance?.inputRef) {
          (<InputJsonLineUiComponent>(
            this.componentRefs[0].instance
          )).valueComponentRef.instance.inputRef.nativeElement.focus();
        }
      }, 200);
    }
  }

  updateModel() {
    let value;
    if (!this.hasMultiValues) {
      value = this.chips[0];
    } else {
      value = this.chips;
    }
    if (value === undefined) {
      value = null;
    }
    if (value != null) {
      if (!Array.isArray(value)) {
        value = { ...value, _ID: undefined };
      }
      if (!this.isFromInputJson) value = JSON.stringify(value);
    }
    this.dataChange(value);
  }

  addLineToPanel(event: Event, isObject = false) {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }
    if (this.isFree) {
      if (isObject) {
        this.buildObjectLine();
      } else {
        this.buildLine();
      }
      if (this.jsonFieldsContainer) {
        setTimeout(() => {
          this.gotoBottom();
        }, 50);
      }
    }
  }

  private gotoBottom() {
    this.jsonFieldsContainer.nativeElement.scrollTop =
      this.jsonFieldsContainer.nativeElement.scrollHeight - this.jsonFieldsContainer.nativeElement.clientHeight;
  }

  onInputFocus(event: FocusEvent) {
    if (this.globalClickListener === undefined) {
      this.globalClickListener = this.renderer.listen(document, 'click', (e: any) => {
        const path = e.path ? e.path : e.composedPath();
        if (path.indexOf(this.elementRef.nativeElement) < 0) {
          this.globalClickListener();
          this.globalClickListener = undefined;
        }
      });
    }
  }

  onInputBlur(event) {
    this.focused = false;
    if (this.addOnBlur && this.uniqueProp && this.inputViewChild.nativeElement.value) {
      const values: any = {};
      values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
      this.addChip(values, null, this.inputViewChild.nativeElement.value);
      this.inputViewChild.nativeElement.value = '';
    }
  }

  onKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      // Backspace
      case 'Backspace':
        if (this.inputViewChild.nativeElement.value.length === 0 && this.chips && this.chips.length > 0) {
          this.chips = [...this.chips];
          const removedItem = this.chips.pop();
          this.updateModel();
          this.chipsChange.emit(this.chips);
          if (this.editOnBackspace) {
            this.inputViewChild.nativeElement.value = removedItem[this.fields[0].name];
            event.preventDefault();
          }
        }
        break;

      // Enter
      case 'Enter':
        if (this.addOnEnter && this.inputViewChild.nativeElement.value !== '') {
          const values: any = {};
          values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
          if (!this.addChip(values, null, this.inputViewChild.nativeElement.value)) {
            this.isNotAuthorized = true;
            break;
          }
          this.inputViewChild.nativeElement.value = '';
          event.preventDefault();
          this.inputViewChild.nativeElement.blur();
          this.onInputBlur(event);
        }
        break;

      // Tab
      case 'Tab':
        if (this.addOnTab && this.inputViewChild.nativeElement.value !== '') {
          const values: any = {};
          values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
          if (!this.addChip(values, null, this.inputViewChild.nativeElement.value)) {
            this.isNotAuthorized = true;
            break;
          }
          this.inputViewChild.nativeElement.value = '';

          event.preventDefault();
        }
        break;

      // Space
      case ' ':
        if (this.addOnSpace && this.inputViewChild.nativeElement.value !== '') {
          const values: any = {};
          values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
          if (!this.addChip(values, null, this.inputViewChild.nativeElement.value)) {
            this.isNotAuthorized = true;
            break;
          }
          this.inputViewChild.nativeElement.value = '';
          event.preventDefault();
        }
        break;

      default:
        break;
    }
  }
  aggregateValuesWithDelimiter(obj: any, delimiter: string = '|', keys?: string[]): string {
    const result: string[] = [];
    this.traverseObject(obj, result, keys);
    return result ? result.join(delimiter) : '';
  }

  traverseObject(obj: any, result: string[], keysOverride?: string[]): void {
    if (result == null) {
      return;
    }
    if (obj !== null && typeof obj === 'object') {
      if (Array.isArray(obj)) {
        for (const item of obj) {
          this.traverseObject(item, result);
        }
      } else {
        const keys = keysOverride
          ? keysOverride
          : Object.keys(obj)
              .sort()
              .filter((k) => k != '_ID');
        for (const key of keys) {
          this.traverseObject(obj[key], result);
        }
      }
    } else if (obj === null || obj === undefined || obj.length == 0) {
      result = null;
    } else {
      result.push(String(obj));
    }
  }
  generateId(chip: any): string {
    let _ID = '';
    const keys = this.fieldKeys.filter((fk) => fk.name != '_ID');
    if (this.isFree && keys.length === 0) {
      _ID = this.aggregateValuesWithDelimiter(chip, '|');
    } else {
      _ID = this.aggregateValuesWithDelimiter(
        chip,
        '|',
        keys.map((f) => f.name)
      );
    }
    return _ID;
  }
  generateDisplayedKeys(chips: any) {
    if (this.isFree) {
      this.fieldDisplayeds = [];
      let i = 0;
      for (const c of chips) {
        const keys = Object.keys(c);
        for (const k of keys) {
          if (k != '_ID' && !(c[k] instanceof Object) && this.fieldDisplayeds.findIndex((fd) => fd.name === k) === -1) {
            this.fieldDisplayeds.push({
              componentName: 'InputTextUiComponent',
              description: k,
              name: k,
              nameToShow: k,
              numberType: null,
              seqNo: i++,
              jsonFieldId: i++,
              isKey: false,
              isDisplayed: true,
            });
          }
        }
      }
    }
  }
}
